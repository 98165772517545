import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import SideMenuItem from './SideMenuItem';
import { useTheme } from 'emotion-theming';
import settingsIcon from '../../assets/img/setting-icon.png';
import settingsIconWhite from '../../assets/img/setting-icon-white.png';
import audioIcon from '../../assets/img/sound.png';
import audioOffIcon from '../../assets/img/sound_off.png';
// import noAudioIcon from '../../assets/img/no-sound.png';

/** @jsx jsx */
const SideMenu = ({ children, ...others }) => {
  const theme = useTheme();
  return <div
      css={css`
        font-family: ${theme.texts.font.family.default};
        .overlay {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,.6);
          position: absolute;
          z-index: 99;
          -webkit-transition: all .6s ease;
          -o-transition: all .6s ease;
          transition: all .6s ease;
        }
        .side-menu {
          top: 0;
          left: 0;
          width: 350px;
          height: 100%;
          background-color: #fff;
          position: absolute;
          z-index: 999;
          margin-left: -350px;
          transition: all .5s;
          visibility: hidden;
          &.active {
             margin-left: 0px;
             visibility: visible;
          }
        }
        .round-btn {
          position: absolute;        
          /* @media only screen and (max-width: 600px){
            .round-btn{
              top: 100px;
            }
          } */

          width: 60px;
          height: 60px;
          border-radius: 50%;
          -webkit-box-shadow: 0 2px 2px rgba(0,0,0,.2);
          box-shadow: 0 2px 2px rgba(0,0,0,.2);
          cursor: pointer;
          -webkit-transition: all .6s ease;
          -o-transition: all .6s ease;
          transition: all .6s ease;
        }
        
        .settings-btn {
          top: 45px;
          left: 40px;
          background: url(${settingsIcon}) no-repeat #fff;
          background-position: 50%;
          background-size: 30px;
          &.active {
            background: url(${settingsIconWhite}) no-repeat ${theme.colors.primary};
            background-position: 50%;
            background-size: 30px;
            z-index: 100;
          }
          @media only screen and (max-width: 600px){
            top: 10px;
            left: 10px;
          }
        }
        .sound-btn {
          top: 45px;
          left: 120px;
          background: url(${audioIcon}) no-repeat #fff;
          background-position: 50%;
          background-size: 30px;
          &.active {
            background: url(${audioOffIcon}) no-repeat ${theme.colors.primary};
            background-position: 50%;
            background-size: 30px;
            /* z-index: 99; */
          }
          @media only screen and (max-width: 600px){
            left: auto;
            top: 10px;
            right: 10px;
          }

        }
        .options {
          margin-top: 90px;
          padding: 20px 45px;
          border-bottom: 1px solid #ddd;
        }
        .options-title {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          color: ${theme.colors.primary};
        }
        .checkbox {
          position: relative;
          margin-left: 10px;
        }
        /* Hide the browser's default radio button */
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
        .radio {
          position: absolute;
          transform: translate(-50%, -50%);
          height: 15px;
          width: 15px;
          background-color: #fff;
          border: 2px solid #999;
          border-radius: 50%;
          margin-top: 9px;
        }
        /* When the radio button is checked, add a blue background */
        .checkbox input:checked ~ .radio {
          background-color: #fff;
          border: 2px solid ${theme.colors.primary};
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .radio:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .checkbox input:checked ~ .radio:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .checkbox .radio:after {
          top: 47%;
          left: 47%;
          transform: translate(-47%, -47%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: ${theme.colors.primary};
        }
        .language {
          margin-left: 20px;
        }
        .buttons {
          display: flex;
          flex-direction: column;
          padding: 20px 45px;
        }
        .buttons Button {
          margin-top: 30px;
        }
      `}
      {...others}>
      {children}
      </div>;
};

SideMenu.Item = SideMenuItem;

SideMenu.propTypes = {
  children: PropTypes.node,
};

SideMenu.defaultProps = {
};

export default SideMenu;
