import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

/** @jsx jsx */
// ADD IN CALLBACK PROP
export const ImageGroup = ({ imagesArray, size, onClick, textStyle }) => {
    const theme = useTheme();
    let scale;
    switch(size){
        case 'small':
            scale = 100;
            break;
        case 'large':
            scale = 200;
            break;
        default:
            scale = 150;
    }

    return(
        <div css={css`
        width: 100%;
        .container{
            width: 100%;
            font-family: ${theme.texts.font.family.default};      
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
            * {
                font-family: ${theme.texts.font.family.default};
            }
            img {
                width: 100%;
                z-index: 0;
            }
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
            }
            .box{
                width: ${(100 / imagesArray.length)}%;
                max-width: 400px;
                cursor: pointer;
            }
            .box .text {
                position: absolute;
                z-index: 0;
                margin: 0 auto;
                left: 0;
                right: 0;        
                text-align: center;
                // top: 30%; /* Adjust this value to move the positioned div up and down */
                font-family: ${theme.texts.font.family.default};
                font-size: ${scale / 10}px;
                width: 100%;
                color: #fff;
            }
            .banner-box{
                width: ${(100 / imagesArray.length) - 1}%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin: 0px 5px;
            }
            .banner-text {
                text-align: center;
                font-family: ${theme.texts.font.family.default};
                font-size: ${scale / 11}px;
                width: 100%;
                height: 65px;
                padding: 0px 5px;
                color: #fff;
                box-sizing: border-box;
                background-color: #5fb0bb;
            }
            @media only screen and (max-width: 600px){
                .container{
                    flex-direction: column;
                }
                .banner-box{
                    margin-bottom: 15px;
                    width: 100%;
                }
                .box{
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
        `}>
            <div className="container">
                {textStyle == 'banner'?
                    imagesArray.map((image, index) => {
                        return (
                            <div key={`image-${index}`} className="banner-box" onClick={() => { onClick(index); }}>
                                <img src={image.url} alt={image.alt}></img>
                                <div className="banner-text">{image.overlayText}</div>
                            </div>
                        );
                    })
                
                :        
                    imagesArray.map((image, index) => {
                        return(
                            <div key={`image-${index}`} className="box" onClick={() => {onClick(index);}}>
                                <img src={image.url} alt={image.alt}></img>
                                <div className="text">{image.overlayText}</div>
                            </div>  
                        ); 
                    })
                }
            </div>
        </div>
    );
};


ImageGroup.propTypes = {
    /** An array of the images to be rendered */
    imagesArray: PropTypes.arrayOf( PropTypes.shape({
        /** The URL of the image being loaded */
        url: PropTypes.string.isRequired,

        /** Alt text to display if the image is not available. */
        alt: PropTypes.string.isRequired,
        /** Text to be rendered over the image. */
        overlayText: PropTypes.string
    })
    ).isRequired,
    /** The size of the Image Group */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /** The size of the text associated with the images */
    textStyle: PropTypes.oneOf(['banner', 'overlay']),
    /** callback */
    onClick: PropTypes.func
};

ImageGroup.defaultProps = {
    size: 'medium',
    textStyle: 'overlay'
};
