import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** @jsx jsx */
export const NavBarItem = ({ children, ...others }) => {
  return (
    <div css={css`
          padding: 10px 30px;
          color: black;
          position: relative;
          svg {
            font-size: 20px;
          }
          .notification-counter {
            background-color: #D52023;
            color: #ffffff;
            width: 18px;
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            text-align: center;
            padding: 2px;
            border-radius: 25px;
            font-weight: bold;
            border: #ffffff 2px solid;
            font-size: 13px;
          }
        `} 
        className="navbar-item" {...others}>
        { children }
    </div>
  );
};

NavBarItem.propTypes = {
  children: PropTypes.any,
};

const NavBarItemPanel = ({ children }) => {
  return (
    <div css={css`
      position: absolute;
      width: 400px;
      right: 0px;
      background: #FFFFFF;
      border: #A7A7A7 1px solid;
      border-radius: 5px;
      top: 65px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(59,59,59,0.3);
      -moz-box-shadow: 0px 0px 5px 0px rgba(59,59,59,0.3);
      box-shadow: 0px 0px 5px 0px rgba(59,59,59,0.3);
      padding: 25px;
      h1 {
        margin: 0 0 20px 0;
        font-weight: 600;
        font-size: 24px;
      }
    `}>
      { children }
    </div>
  );
};

NavBarItemPanel.propTypes = {
  children: PropTypes.any,
};

NavBarItem.Panel = NavBarItemPanel;