import axios from '../config/Axios';

export const fetchAuthOptions = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/oauth/options')
            .then(resolve)
            .catch(reject);
    });
};
export const loginRequest = (options) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.API_URL}/users/login`,options)
            .then(resolve)
            .catch(reject);
    });
};
export const forgotPassword = (options) => {
    return new Promise((resolve, reject) => {
        axios.post('/api/users/forgot-password',options)
            .then(resolve)
            .catch(reject);
    });
};
export const fetchUserData = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/users/me')
            .then(resolve)
            .catch(reject);
    });
};
export const logoutUser = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.API_URL}/users/logout`)
            .then(resolve)
            .catch(reject);
    });
};

export const checkToken = (data) => {
    return new Promise((resolve, reject) => {
        // fake response timeout
        axios.post('/api/users/checkToken/', data)
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch(reject);
    });
};