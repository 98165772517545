import React, { useEffect, useState } from 'react';
import { fetchPatients } from '../../services/PatientsService';
import { getLocationsWithPatients } from '../../services/LocationsService';
import { getProvidersWithPatients } from '../../services/ProvidersService';
import AddPatient from './AddPatient.js';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogContent from '@material-ui/core/DialogContent';
import SortableTable from './../../components/SortableTable';
import { Permission } from '../../../../shared/Security/index';

const IndexPage = () => {
    const classes = makeStyles({
        h2: {
            display: 'inline-block'
        },
        search: {
            marginLeft: 20,
            marginTop: 15,
        },
        container: {
            maxWidth: 1600,
            margin: '0 auto'
        }
    })();
    const [tableHeaders] = useState([
        
        {
            name: 'MRN',
            key: 'mrn',
            type: 'number',
            allowed:['Patients.Index'],

            sortable: true,
            callback: false,

            link: false,
            remove: false,
        },
        {
            name: 'Name',
            key: 'pt_name',
            type: 'string',
            sortable: false,
            allowed:['Patients.Index'],
            callback: editPatient,
            callbackPermission:['Patients.Index'],

            link: false,
            remove: false
        },
        // {
        //     name: 'Clinic',
        //     key: 'clinic',
        //     allowed:['Patients.Index'],

        //     type: 'string',
        //     sortable: false,
        //     filterable: true,
        //     getFilterOptions: () => {
        //         return getLocationsWithPatients().then((response) => {
        //             let resClinicIds = response.data.map(clinic => clinic.id);
        //             let resClinicIdsToNames = {};
        //             response.data.map(clinic => {
        //                 resClinicIdsToNames[clinic.id] = clinic.name;
        //             });
        //             return {"ids": resClinicIds, "labels": resClinicIdsToNames};
        //         });        
        //     },
        //     onFilterChange: (e, newVal) => setClinicFilterArray(newVal),
        //     callback: false,
        //     link: false,
        //     remove: false
        // },
        {
            name: 'Provider',
            key: 'provider',
            allowed:['Patients.Index'],
            type: 'string',
            sortable: false,
            filterable: true,
            getFilterOptions: () => {
                return getProvidersWithPatients().then((response) => {
                    let resProviderIds = response.data.map(provider => provider.id);
                    let resProviderIdsToNames = {};
                    console.log(response);
                    response.data.map(provider => {
                        resProviderIdsToNames[provider.id] = provider.name;
                    });
                    return {"ids": resProviderIds, "labels": resProviderIdsToNames};
                });        
            },
            onFilterChange: (e, newVal) => setProviderFilterArray(newVal),
            callback: false,
            link: false,
            remove: false
        },
         {
            name: 'Due Date',
            key: 'assessmentDueDate',
            type: 'date',
            sortable: false,
            callback: false,
            isHtml:true,
            link: false,
            remove: false
        },
        {
            name: 'Recruitment',
            key: 'preferredRecruitment',
            type: 'date',
            sortable: false,
            callback: false,
            isHtml:true,
            link: false,
            remove: false
        },
        {
            name: 'Completion Date',
            key: 'completion_date',
            type: 'date',
            sortable: false,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'PHQ-9 Score',
            key: 'phq9_score',
            type: 'number',
            sortable: false,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'PHQ-9 score category',
            key: 'phq9_score_group',
            type: 'number',
            sortable: false,
            filterable: true,
            getFilterOptions: () => {
                return new Promise(resolve => {
                    resolve({
                        'ids': [
                            'minimal',
                            'mild',
                            'moderate',
                            'moderate-severe',
                            'severe',
                            // 'n/a'
                        ],
                        labels: {
                            'minimal': 'Minimal',
                            'mild': 'Mild',
                            'moderate': 'Moderate',
                            'moderate-severe': 'Moderate-Severe',
                            'severe': 'Severe',
                            // 'n/a': 'N/A'
                        }
                    });
                });
            },
            onFilterChange: (e, newVal) => setPhqCategoryFilterArray(newVal),
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Suicidal',
            key: 'suicidality',
            type: 'string',
            sortable: false,
            // @TODO: SET FILTERABLE TO TRUE WHEN READY
            filterable: true,
            getFilterOptions: () => {
                return new Promise(resolve => {
                    resolve({
                        ids: [
                            'yes',
                            'no',
                        ],
                        labels: {
                            'yes': 'Yes',
                            'no': 'No',
                        }
                    });
                });
            },
            onFilterChange: (e, newVal) => setSuicidalFilterArray(newVal),
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Assessment',
            key: 'tool',
            type: 'int',
            sortable: false,
            // @TODO: SET FILTERABLE TO TRUE WHEN READY
            filterable: true,
            getFilterOptions: () => {
                return new Promise(resolve => {
                    resolve({
                        ids: [
                            '1',
                            '2',
                        ],
                        labels: {
                            '1': 'iHeart',
                            '2': 'DepCare',
                        }
                    });
                });
            },
            onFilterChange: (e, newVal) => setAssessmentFilter(newVal),
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Treatment Interest',
            key: 'treatment_interest',
            type: 'string',
            sortable: false,
            align: 'center',
            filterable: true,
            getFilterOptions: () => {
                return new Promise(resolve => {
                    resolve({
                        ids: [
                            'care-manager',
                            'social-worker',
                            'none',
                        ],
                        labels: {
                            'care-manager': 'Care Manager',
                            'social-worker': 'Social Worker',
                            'none': 'None',
                        }
                    });
                });
            },
            onFilterChange: (e, newVal) => setTreatmentInterestFilterArray(newVal),
            link: false,
            callback: false,
            remove: false
        },
        {
            name: 'DepCare Report',
            key: 'see_report',
            type: 'string',
            allowed:['PatientAssessments.Results'],
            sortable: false,
            callback: false,
            link: true,
            align: 'center',
            conditionalLink: true,
            isNewPage: true,
            altLinkText: 'Assessment Not Completed',
            linkText: 'Report',
            isButton: true,
            remove: false
        },
        {
            name: 'Details',
            key: 'view_patient',
            type: 'string',
            sortable: false,
            callback: false,
            align: 'center',
            link: true,
            allowed:['Patients.View'],
            linkText: 'Patient',
            isButton: true,
            isNewPage: true,
            remove: false
        },
      
    ]);
    const [patients, setPatients] = useState([]);
    const [page, setPage] = useState(1);
    const [assessment_id, setAssessmentId] = useState([]);

    const [clinic_ids, setClinicianIds] = useState([]);
    const [patientToEdit, setPatientToEdit] = useState(-1);
    const [lastPageLoaded, setLastPageLoaded] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('assessmentDueDate');
    const [clinicFilterArray, setClinicFilterArray] = useState([]); 
    const [providerFilterArray, setProviderFilterArray] = useState([]); 
    const [phqCategoryFilterArray, setPhqCategoryFilterArray] = useState([]); 
    const [suicidalFilterArray, setSuicidalFilterArray] = useState([]); 
    const [assessmentFilter, setAssessmentFilter] = useState([]); 

    const [treatmentInterestFilterArray, setTreatmentInterestFilterArray] = useState([]); 

    const [engagment, setEngagement] = useState({assessment_completion_count:0,assessment_count:0}); 

    function loadPatients() {
        console.log("from loadpatients");
        console.log(clinicFilterArray);
        fetchPatients(page, search, order, orderBy, clinicFilterArray, providerFilterArray, phqCategoryFilterArray, suicidalFilterArray,treatmentInterestFilterArray,assessmentFilter).then((response) => {
            setLastPageLoaded(response.__pagination.Patients.page);
            const processedData = processData(response.data);
            setEngagement(response.engagement);
            setPatients(processedData);
            setTotalPageCount(response.__pagination.Patients.pageCount);
        });
    }
    useEffect(() => {
        console.log('search loop');
        setPage(1);
        loadPatients();
    },[search, order, orderBy, clinicFilterArray, providerFilterArray, phqCategoryFilterArray, suicidalFilterArray, treatmentInterestFilterArray,assessmentFilter]);

    function processData(data){
        const processedData = [];
        data.map(row => {
            processedData.push({
                ...row, 
                pt_name: `${row.first_name} ${row.last_name}`,
                provider: row.provider.name,
                completion_date: (row.last_assessment_results && row.last_assessment_results.completion_date)?row.last_assessment_results.completion_date.substring(0, 10):'N/A', 
                phq9_score: (row.phq_9_score)?row.phq_9_score:'N/A',
                phq9_score_group: (row.phq_score_group && row.phq_score_group)? row.phq_score_group: 'N/A',
                suicidality: (row.suicidality != '')?(row.suicidality)?'Y':'N':'N/A',
                
                treatment_interest: (row.cm_interest && row.cm_interest)?`${row.cm_interest? 'Care Manager' : (row.cm_interest && row.cm_interest? 'Social Worker': 'None')}`:'N/A',
                view_patient: `/admin/patients/view/${row.id}` ,
                assessmentDueDate:row.assessmentDueDate,
                // see_report: `/admin/patients/results/238` 
                see_report: (!row.last_assessment_results || row.last_assessment_results.completion_date === "N/A")? '' : `/admin/patients/results/${row.last_assessment_results.id}`
            });
        });
        console.log(processedData,'processedData');
        return processedData;
    }
    const StyledPagination = withStyles(() => ({
        root: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
        }
    }))(Pagination);
    useEffect(() => {
        if (page != lastPageLoaded) {
            console.log('first condition');
            loadPatients();
        }
        // else if (patients.length === 0 && search === '' ) {
        //     console.log('second condition');
        //     loadPatients();
        // }
    });
    const handleChange = (event) => {
        setClinicianIds(event.target.value);
    };
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setPatientToEdit(-1);
    };
    const handleAddSuccess = () => {
        handleDialogClose();
        loadPatients();
    };
    function editPatient(id){
        setPatientToEdit(id); 
        handleDialogOpen();
    }
    function handleSearch(e){
        console.log(e.target.value);
        console.log("changing");
        setSearch(e.target.value);
    }
    function handleSortChange(key, orderParam){
        setOrder(orderParam);
        setOrderBy(key);
        console.log(order, orderBy);
    }

    return <div className={classes.container}>
             <Permission allowed="Patients.Index">

        <Grid container spacing={3} justify="space-between">

            <Grid item xs={9}>
                <h2 className={classes.h2}>Patients</h2>
               
                <TextField value={search} onChange={handleSearch} size={'small'} className={classes.search} id="standard-basic" label="Search MRN" variant="outlined"/>            
            </Grid>
            <Grid item xs={0} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            
                <FormControl  style={{ width: 200, marginTop: "10px" ,display:'none'}}>
                    <InputLabel id="demo-mutiple-name-label">Clinic</InputLabel>
                    <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        displayEmpty
                        value={clinic_ids}
                        onChange={handleChange}
                    >
                        {[{ name: 'Clinic 1', id: 1 }].map((clinic) => (
                            <MenuItem key={clinic.id} value={clinic.id} >
                                {clinic.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Permission allowed="Patients.Add">

            <Grid item xs={2} style={{ textAlign: 'right', marginTop: 20 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDialogOpen}
                    >
                        Add
                    </Button>
                    <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <AddPatient
                                onSuccess={handleAddSuccess}
                                patientToEdit={patientToEdit}
                            />
                        </DialogContent>
                    </Dialog>
            </Grid>
            </Permission>
        </Grid>
        <Grid>
        <div style={{marginBottom:10}}><b>Engagement: </b>{engagment.assessment_completion_count}/{engagment.assessment_count}<b>({Math.round((100 / engagment.assessment_count ) *engagment.assessment_completion_count) }%)</b> assessments completed.</div>

        </Grid>
        <SortableTable 
            data={patients}
            headers={tableHeaders}
            defaultOrder="asc"
            defaultOrderBy="assessmentDueDate"
            sortCallback={handleSortChange}
        />
        <StyledPagination
            count={totalPageCount}
            size={"medium"}
            defaultPage={1}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
        />    
        </Permission>
    </div>;
};

export default IndexPage;