import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
// import { useTheme } from 'emotion-theming';

/** @jsx jsx */
export const Title = ({ children, ...others }) => {
  // const theme = useTheme();
  return (
    <h1 
      css={css`
      `}
      {...others}>
        {children}
    </h1>
  );
};

export default Title;

Title.propTypes = {
  children: PropTypes.string.isRequired, 
};

Title.defaultProps = {
};