import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkToken, resetRequest} from '../../services/AuthService';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import columbiaHeader from './../../../../../assets/cu-header.svg';

// Authentication
// import { setUserData } from '../../state/actions/AuthActions';

// Components
import ThemeProvider from 'ComponentLibrary/ThemeProvider';
const ResetPassword = ({ 
    theme, 
    // setUserData, 
    history,
    match 
}) => {
    const token = match.params.token;
    const [status, setStatus] = useState({});
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const onResetSubmit = () => {
        // history.push('/admin/reset/0');
        if(password != passwordConfirm){
            setStatus({error: "Passwords must match"});
        } else {
            console.log("Passwords match, attempting to submit.");
            resetRequest({ token: token, password: password}).then(response => {
                if (response.success) {
                    console.log("success");
                    setStatus({success: "Success! Now login with your new password."});
                    // setUserData(response.data.data.user);
                    // history.push('/admin/patients/');
                }
                else {
                    setStatus({ error: "Not valid" });
                }
            });
        };


        // resetRequest({ token: token, password: password}).then(response => {
        //     if (response.data.success) {
        //         console.log("success");
        //         console.log(response.data);
        //         setUserData(response.data.data.user);
        //           history.push('/admin/patients/');
        //     }
        //     else {
        //         setStatus({ error: "Not valid" });
        //     }
        // });
    };

    useEffect(() => {
        checkToken({token: token}).then(res => {
            console.log(res);
            if (res.success) {
                console.log("rendering page");
            } else {
                // if checkToken fails, redirect to the admin login page
                history.push('/admin');
            };
        });
    }, []);

    return <ThemeProvider theme={theme}>
        <div className="login-page">
            <div className="login-window">
                <div style={logo}>
                    <img src={columbiaHeader} width={300}></img>
                </div>

                {(status.success || status.error) &&
                    <Alert severity={status.success ? 'success' : 'error'}>{status.success ? status.success : status.error}</Alert>
                }
                <div style={inputBoxContainer}>
                    <div>
                        <h3>Reset Your Password</h3>
                    </div>
                    <TextField id="password" style={inputBoxContainer} label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    <TextField id="password" style={inputBoxContainer} label="Confirm Password" type="password" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} />
                </div>

                <div >
                    <div style={actionsContainer}>
                        <a style={spanLink} href="/admin">Back to Login</a>
                        <Button variant="contained" color="primary" onClick={onResetSubmit}>Reset Password</Button>
                    </div>
                </div>
            </div>
        </div>
    </ThemeProvider>;

};
const inputBoxContainer = {
    width: '100%',
    marginBottom: 10
};

const logo = {
    width: 300,
    marginTop: -110,
    position: 'absolute',
    marginBottom: 0
};

const actionsContainer = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
};

const spanLink = {
    cursor: "pointer",
    color: '#182c49',
    textDecoration: "underline"
};

ResetPassword.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    setUserData: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    theme: PropTypes.string.isRequired,
    userData: PropTypes.object,
};


// const mapStateToProps = state => ({
//     userData: state.auth.userData,
// });

// const mapDispatchToProps = dispatch => {
//     const action = bindActionCreators({ setUserData }, dispatch);
//     return { ...action, dispatch };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
export default ResetPassword;