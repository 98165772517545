import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** @jsx jsx */
const SideMenuOffset = ({ children, ...others }) => {
  return <div 
      css={css`
        flex: 1;
      `}
      {...others}>
        {children}
      </div>;
};

SideMenuOffset.propTypes = {
  children: PropTypes.any,
};

export default SideMenuOffset;