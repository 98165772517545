import axios from '../../../../config/Axios';

export const fetchPatients = (page, search = '', order="ASC", orderBy="mrn", clinicFilterArray=[], providerFilterArray=[], phqCategoryFilterArray=[], suicidalFilterArray=[], treatmentInterestFilterArray=[], assessmentIdFilterArray=[]) => {
    return new Promise((resolve , reject) => {
        const clinicFilterString = clinicFilterArray.join(); 
        const providerFilterString = providerFilterArray.join(); 
        // console.log("ARRAY");
        // console.log(clinicFilterArray);
        // console.log('STRING');
        // console.log(clinicFilterString);
        axios.get('/api/patients?page='+page+'&search='+search+'&sort_direction='+order+'&sort_by='+orderBy+'&filter_clinics='+clinicFilterString+'&filter_providers='+providerFilterString+'&filter_phq_score_category='+phqCategoryFilterArray+'&filter_suicidal='+suicidalFilterArray+'&filter_treatment_interest='+treatmentInterestFilterArray+'&assessment_id='+assessmentIdFilterArray)
        .then((response) => {
            const { data } = response;

            // console.log(data);
            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const dropPatient = (id) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get(`/api/patients/drop-patient/${id}`)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const savePatient = (data) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/patients/add/',{patient:data})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const fetchPatient = (id) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get(`/api/patients/view/${id}`)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const deletePatient = (id) => {
    return new Promise((resolve, reject) => {
        // fake response timeout
        axios.get(`/api/patients/delete/${id}`)
            .then((response) => {
                const { data } = response;

                resolve(data);

            })
            .catch(reject);
    });
};

