import { useState, useEffect, useRef } from 'react';
import Loader from 'react-loader';
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ImageGroup, Button } from 'ComponentLibrary';
import Modal from 'react-modal';
import { registerModal } from './../../../services/InformationService.js';

// import { Document, Page } from 'react-pdf';
import ReactRouterPropTypes from 'react-router-prop-types';
import { css, jsx } from '@emotion/core';
import InformationForm from './InformationForm';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { useSelector } from 'react-redux';
import { getCompletedAssessment} from '../../../services/AssessmentService';
// import picture from './assets/pdf/causes/pdf_causes_and_early_warning_signs-1.png';
// import { useTheme } from 'emotion-theming';

/** @jsx jsx */
export const DepCareEducation = ({ match }) => {
    // const theme = useTheme();
    const { material,token } = match.params;
    console.log(token);
    const [slideshowI, setSlideshowI] = useState(-1);
    
    const [imagesArray, setImagesArray] = useState([]);
    const [pdfs, setPdfs] = useState([]);

    const [images, setImages] = useState([]);
    const { soundOn, language } = useSelector(state => state.auth.userData);
    console.log("sound: ", soundOn);
    console.log("language: ", language);
    const audioElement = useRef();


    useEffect(() => {
        audioElement.current.src = `${window.location.protocol}//${window.location.hostname}${window.location.port ? (':' + window.location.port) : ''}/api/webroot/uploads/audio/${language ? 'english/English_26_32_44_51_67_71_84.mp3' : 'spanish/Spanish_26_32_44_51_67_71_84.mp3'}`;
        // setTimeout(() => {
        if (soundOn) {
            setTimeout(() => {
                console.log(audioElement.current);
                audioElement.current.play().catch(err => console.log(err));
            }, 1000);
        };
        // }, 500);
    }, [soundOn, language]);
    useEffect(() => {

    getCompletedAssessment(token).then((data) => {
        let images_array = [];
        let pdfs_array = [];
        let phq10_exception  =false;

        if(data.data.assessment_variables.cm_interest !== undefined &&
            data.data.assessment_variables.dep_tx_3 !== undefined &&
            data.data.assessment_variables.counseling !== undefined){
                if(!data.data.assessment_variables.counseling &&
                    !data.data.assessment_variables.dep_tx_3 &&
                    data.data.assessment_variables.cm_interest){
                phq10_exception  =true;
                    }
            }
        console.log(data.data.assessment_variables.cm_interest);
    if (language == 1) {
        if (material == 'phq-0-4-no-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "What Causes Depression",
                    key: 'what_causes_depression',
                    overlayText: "What Causes Depression?"
                },
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',
                    overlayText: "The Link Between Stress & Pain"
                },
                {
                    url: "/api/webroot/uploads/education/causes_and_early_warning_signs.jpg",
                    alt: "Causes & Signs",
                    key: 'causes_and_signs',

                    overlayText: "Causes & Early Warning Signs"
                },
                {
                    url: "/api/webroot/uploads/education/self_care_strategies_ico.jpg",
                    alt: "Self Care",
                    key: 'self_care',
                    overlayText: "Self-Care Strategies"
                },
            ];
            pdfs_array = [
                {
                    id: 'causes',
                    key: 'what_causes_depression',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    // error
                    id: 'stress-and-pain',
                    key: 'stress_and_pain',

                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    //error
                    id: 'causes',
                    key: 'causes_and_signs',
                    source: "/api/webroot/uploads/education/pdf_causes_and_early_warning_signs.pdf",
                    numPages: 3
                },
                {
                    id: 'self-care',
                    key: 'self_care',

                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-0-4-yes-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "The Link Between Stress & Pain"
                },
                {
                    url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                    alt: "Regimen",
                    key:'daily_tips_to_complement_your_regimen',
                    overlayText: "Daily Tips to Complement your Regimen"
                },
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Ways to Talk to Your Mental Health Provider"
                },
                {
                    url: "/api/webroot/uploads/education/self_care_strategies_ico.jpg",
                    alt: "Self Care",
                    key: 'self_care',
                    overlayText: "Self-Care Strategies"
                },
            ];
            pdfs_array = [
                {
                    //error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    //error
                    id: 'daily-tips',
                    key:'daily_tips_to_complement_your_regimen',
                    source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                    numPages: 1
                },
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                {
                    id: 'self-care',
                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-5-9-no-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/causes_and_early_warning_signs.jpg",
                    alt: "Causes & Signs",
                    key: 'causes_and_signs',

                    overlayText: "Causes & Early Warning Signs"
                },
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "The Link Between Stress & Pain"
                },
                {
                    url: "/api/webroot/uploads/education/self_care_strategies_ico.jpg",
                    alt: "Self Care",
                    key: 'self_care',
                    overlayText: "Self-Care Strategies"
                },
                {
                    url: "/api/webroot/uploads/education/connect_to_treatment.jpg",
                    alt: "Connect to Treatment",
                    key:'connect_to_treatment',
                    overlayText: "Connect to Treatment"
                },
            ];
            pdfs_array = [
                {
                    //error
                    id: 'causes',
                    key: 'causes_and_signs',

                    source: "/api/webroot/uploads/education/pdf_causes_and_early_warning_signs.pdf",
                    numPages: 3
                },
                {
                    //error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    id: 'self-care',
                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
                {
                    id: 'connect-to-treatment',
                    key:'connect_to_treatment',
                    source: "/api/webroot/uploads/education/pdf_connect_to_treatment.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-5-9-yes-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',
                    overlayText: "Ways to Talk to Your Mental Health Provider"
                },
                {
                    url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                    alt: "Regimen",
                    key:'daily_tips_to_complement_your_regimen',
                    overlayText: "Daily Tips to Complement your Regimen"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',

                    overlayText: "How do Medication & Therapy Compare?"
                },
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causes & Myths"
                },
            ];
            pdfs_array = [
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                {
                    //error
                    id: 'daily-tips',
                    key:'daily_tips_to_complement_your_regimen',
                    source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                    numPages: 1
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-10-plus-no-treatment-no-meds' ) {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causes & Myths"
                },
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "The Link Between Stress & Pain"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',

                    overlayText: "How do Medication & Therapy Compare?"
                },
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Ways to Talk to Your Mental Health Provider"
                },
            ];
            pdfs_array = [
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    //error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
            ];
        }
        else if (material == 'phq-10-plus-no-treatment-yes-meds') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Ways to Talk to Your Mental Health Provider"
                },
                {
                    url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                    alt: "Regimen",
                    key:'daily_tips_to_complement_your_regimen',
                    overlayText: "Daily Tips to Complement your Regimen"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',
                    overlayText: "How do Medication & Therapy Compare?"
                },
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causes & Myths"
                },
            ];
            pdfs_array = [
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                {
                    //error
                    
                    id: 'daily-tips',
                    key:'daily_tips_to_complement_your_regimen',
                    source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                    numPages: 1
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-10-plus-yes-treatment') {
            let single = {
                url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                alt: "Regimen",
                key:'daily_tips_to_complement_your_regimen',
                overlayText: "Daily Tips to Complement your Regimen"
            };
            let single_pdf = {
                //error
                id: 'daily-tips',
                key:'daily_tips_to_complement_your_regimen',
                source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                numPages: 1
            };
            if(phq10_exception){
                single =  {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "The Link Between Stress & Pain"
                };
                single_pdf = {
                    //error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                };
            }
           
            images_array = [
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Ways to Talk to Your Mental Health Provider"
                },
                single,
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causes & Myths"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',

                    overlayText: "How do Medication & Therapy Compare?"
                },
            ];
            pdfs_array = [
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                single_pdf,
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
            ];
        }
        else if(material == 'phq10-yes-treatment'){
            images_array = [{
                url: '/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg',
                alt: 'Stress and pain',
                key: 'Stress_and_pain',
                overlayText: 'Link between Depression & Heart Disease'
            },
            {
                url: '/api/webroot/uploads/education/self_care_strategies_ico.jpg',
                alt: 'Self-care & Treatment',
                key: 'Self-care_and_treatment',
                overlayText: 'Self-care & Treatment'
            },
            {
                //Should be changed to the appropiate image
                url: '/api/webroot/uploads/images/Managing-Heart-Disease_No-Text.png',
                alt: 'Managing my heart disease',
                key: 'managing_my_heart_disease',
                overlayText: 'Managing My Heart Disease'
            }
                
            ];
            pdfs_array = [
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    id: 'self-care',
                    key: 'self_care',

                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
                {
                    
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
            ];
        }
    }
    else{
        if (material == 'phq-0-4-no-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "¿Qué causa la depresión?",
                    key:'what_causes_depression',
                    overlayText: "¿Qué causa la depresión?"
                },
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "Causes & Early Warning Signs"
                },
                {
                    url: "/api/webroot/uploads/education/causes_and_early_warning_signs.jpg",
                    alt: "Causes & Signs",
                    key: 'causes_and_signs',

                    overlayText: "Mitos y señales de alerta temprana"
                },
                {
                    url: "/api/webroot/uploads/education/self_care_strategies_ico.jpg",
                    alt: "Self Care",
                    key: 'self_care',
                    overlayText: "Estrategias de autocuidado"
                },
            ];
            pdfs_array = [
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    // error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    //error
                    id: 'causes',
                    key: 'causes_and_signs',

                    source: "/api/webroot/uploads/education/pdf_causes_and_early_warning_signs.pdf",
                    numPages: 3
                },
                {
                    id: 'self-care',
                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-0-4-yes-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "La relación entre el estrés y el dolor"
                },
                {
                    url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                    alt: "Regimen",
                    key:'daily_tips_to_complement_your_regimen',
                    overlayText: "Consejos diarios para complementar su tratamiento actual"
                },
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Maneras de hablar con su proveedor de salud mental"
                },
                {
                    url: "/api/webroot/uploads/education/self_care_strategies_ico.jpg",
                    alt: "Self Care",
                    key: 'self_care',
                    overlayText: "Estrategias de autocuidado"
                },
            ];
            pdfs_array = [
                {
                    //error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    //error
                    id: 'daily-tips',
                    key:'daily_tips_to_complement_your_regimen',
                    source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                    numPages: 1
                },
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                {
                    id: 'self-care',
                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-5-9-no-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/causes_and_early_warning_signs.jpg",
                    alt: "Causes & Signs",
                    key: 'causes_and_signs',

                    overlayText: "Mitos y señales de alerta temprana"
                },
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "La relación entre el estrés y el dolor"
                },
                {
                    url: "/api/webroot/uploads/education/self_care_strategies_ico.jpg",
                    alt: "Self Care",
                    key: 'self_care',
                    overlayText: "Estrategias de autocuidado"
                },
                {
                    url: "/api/webroot/uploads/education/connect_to_treatment.jpg",
                    key:'connect_to_treatment',
                    alt: "Connect to Treatment",
                    overlayText: "Conectarse al tratamiento"
                },
            ];
            pdfs_array = [
                {
                    //error
                    id: 'causes',
                    key: 'causes_and_signs',

                    source: "/api/webroot/uploads/education/pdf_causes_and_early_warning_signs.pdf",
                    numPages: 3
                },
                {
                    //error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    id: 'self-care',
                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
                {
                    id: 'connect-to-treatment',
                    key:'connect_to_treatment',
                    source: "/api/webroot/uploads/education/pdf_connect_to_treatment.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-5-9-yes-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Maneras de hablar con su proveedor de salud mental"
                },
                {
                    url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                    alt: "Regimen",
                    key:'daily_tips_to_complement_your_regimen',
                    overlayText: "Consejos diarios para complementar su tratamiento actual"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',

                    overlayText: "¿Cuál es la diferencia entre los medicamentos y la terapia?"
                },
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causas y mitos"
                },
            ];
            pdfs_array = [
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                {
                    //error
                    id: 'daily-tips',
                    key:'daily_tips_to_complement_your_regimen',
                    source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                    numPages: 1
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-10-plus-no-treatment-no-meds') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causas y mitos"
                },
                {
                    url: "/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg",
                    alt: "Stress and Pain",
                    key: 'stress_and_pain',

                    overlayText: "La relación entre el estrés y el dolor"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',

                    overlayText: "¿Cuál es la diferencia entre los medicamentos y la terapia?"
                },
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Maneras de hablar con su proveedor de salud mental"
                },
            ];
            pdfs_array = [
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    //error
                    id: 'stress-and-pain',
                    source: "/api/webroot/uploads/education/pdf_the_link_between_stress_and_pain.pdf",
                    numPages: 1
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
            ];
        }
        else if (material == 'phq-10-plus-no-treatment-yes-meds') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Maneras de hablar con su proveedor de salud mental"
                },
                {
                    url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                    alt: "Regimen",
                    key:'daily_tips_to_complement_your_regimen',
                    overlayText: "Consejos diarios para complementar su tratamiento actual"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',

                    overlayText: "¿Cuál es la diferencia entre los medicamentos y la terapia?"
                },
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causas y mitos"
                },
            ];
            pdfs_array = [
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                {
                    //error
                    id: 'daily-tips',
                    key:'daily_tips_to_complement_your_regimen',
                    source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                    numPages: 1
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
            ];
        }
        else if (material == 'phq-10-plus-yes-treatment') {
            images_array = [
                {
                    url: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider_ico.jpg",
                    alt: "MH Provider",
                    key:'ways_to_talk_to_your_mental_health_provider',

                    overlayText: "Maneras de hablar con su proveedor de salud mental"
                },
                {
                    url: "/api/webroot/uploads/education/daily_tips_to_complement_your_regimen.jpg",
                    alt: "Regimen",
                    key:'daily_tips_to_complement_your_regimen',
                    overlayText: "Consejos diarios para complementar su tratamiento actual"
                },
                {
                    url: "/api/webroot/uploads/education/what_causes_depression_ico.jpg",
                    alt: "Causes and Myths",
                    key:'what_causes_depression',
                    overlayText: "Causas y mitos"
                },
                {
                    url: "/api/webroot/uploads/education/how_do_medication_and_therapy_compare.jpg",
                    alt: "Medication and Therapy",
                    key:'how_do_medication_and_therapy_compare',

                    overlayText: "¿Cuál es la diferencia entre los medicamentos y la terapia?"
                },
            ];
            pdfs_array = [
                {
                    id: 'talk-to-your-provider',
                    source: "/api/webroot/uploads/education/pdf_ways_to_talk_to_your_mh_provider.pdf",
                    numPages: 5
                },
                {
                    //error
                    id: 'daily-tips',
                    key:'daily_tips_to_complement_your_regimen',
                    source: "/api/webroot/uploads/education/pdf_daily_tips_to_complement_your_regimen.pdf",
                    numPages: 1
                },
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
            ];
            //Material name should be changed to a more correct name
        }else if(material == 'phq10-yes-treatment'){
            images_array = [{
                url: '/api/webroot/uploads/education/the_link_between_stress_and_pain_ico.jpg',
                alt: 'Stress and pain',
                key: 'Stress_and_pain',
                overlayText: 'La relación entre el estrés y el dolor'
            },
            {
                url: '/api/webroot/uploads/education/self_care_strategies_ico.jpg',
                alt: 'Self-care & Treatment',
                key: 'Self-care_and_treatment',
                overlayText: 'Autocuidado y tratamiento'
            },
            {
                //Should be changed to the appropiate image
                url: '/api/webroot/uploads/education/what_causes_depression_ico.jpg',
                alt: 'Managing my heart disease',
                key: 'managing_my_heart_disease',
                overlayText: 'Cómo controlar mi enfermedad cardíaca'
            }
                
            ];
            pdfs_array = [
                {
                    id: 'causes',
                    source: "/api/webroot/uploads/education/pdf_what_causes_depression.pdf",
                    numPages: 3
                },
                {
                    id: 'self-care',
                    key: 'self_care',

                    source: "/api/webroot/uploads/education/pdf_self_care_strategies.pdf",
                    numPages: 3
                },
                {
                    
                },
                {
                    id: 'medication-and-therapy',
                    source: "/api/webroot/uploads/education/pdf_how_does_medication_and_therapy_compare.pdf",
                    numPages: 1
                },
            ];
        }
    }
    setPdfs(pdfs_array);

    setImagesArray(images_array);
}).catch();
    },[]);

    function closeModal() {
        document.body.classList.remove('stop-scrolling');
        setImages([]);
        setSlideshowI(-1);
    }

    const loadSlideshow = () => {
        document.body.classList.add('stop-scrolling');
        // function importAll(r) {
        //     let images = {};
        //     r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
        //     return Object.values(images);
        // };
        // let imagesCollection = [];
        const pdfId = pdfs[slideshowI].id;
        // console.log(pdfId);
        // let number = 1;
        // if (number === 1){
        switch (pdfId) {
            case 'causes':
                import('./assets/pdf/causes').then(mod => {
                    setImages(mod.imagesArray);
                });
                break;
            case 'connect-to-treatment':
                import('./assets/pdf/connect-to-treatment').then(mod => {
                    setImages(mod.imagesArray);
                });
                break;
            case 'daily-tips':
                import('./assets/pdf/daily-tips').then(mod => {
                    setImages(mod.imagesArray);
                });
                break;
            case 'medication-and-therapy':
                import('./assets/pdf/medication-and-therapy').then(mod => {
                    setImages(mod.imagesArray);
                });
                break;
            case 'self-care':
                import('./assets/pdf/self-care').then(mod => {
                    setImages(mod.imagesArray);
                });
                break;
            case 'stress-and-pain':
                import('./assets/pdf/stress-and-pain').then(mod => {
                    setImages(mod.imagesArray);
                });
                break;
            case 'talk-to-your-provider':
                import('./assets/pdf/talk-to-your-provider').then(mod => {
                    setImages(mod.imagesArray);
                });
                break;
        };
        // return imagesCollection;
        // setImages(imagesCollection);
    };

    const modalStyles = {
        content: {
            position: 'static',
            height: "100%"
        }
    };

    return <div>
        <audio ref={audioElement} id="audio-element" src="" type="audio/mpeg" />
        <div className="contentContainer" css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            // height: 70vh;
            h2, label{
                color: #666a6e;
            }
            .information-form-container{
                margin-bottom: 150px;
            }
        `}>
            <div>
                {language == 1 &&
                <h2 style={{ marginBottom: 10, textAlign: "center" }}>Click on an image to learn more about that topic.</h2>
                }
                 {language == 0 &&
                <h2 style={{ marginBottom: 10, textAlign: "center" }}>Haga clic en una imagen para aprender sobre ese tema. 

                </h2>
                }
                <ImageGroup style={{ height: '600px' }} onClick={(obj) => { registerModal(token,imagesArray[obj].key); setSlideshowI(obj); }} imagesArray={imagesArray} size="medium" textStyle="banner">
                </ImageGroup>
            </div>
            {language == 1 &&
            <div className="information-form-container" style={{marginTop:120}}>     
                <p style={{textAlign:'center',minHeight:400}}>
                CAUTION—Investigational device. Limited by Federal law to investigational use.
                    <p>
                    

                    DepCare eSDM Tool<br />

                    Manufacturer/Distributor: Columbia University Irving Medical Center<br />

                    Center for Behavioral Cardiovascular Health (CBCH) - Presbyterian Building - PH9<br />

                    622 W 168th Street New York, NY 10032
                    </p>
                </p>
            </div>
            }
             {language == 0 &&
            <div className="information-form-container" style={{marginTop:120}}>     
                <p style={{textAlign:'center',minHeight:400}}>
                PRECAUCIÓN: dispositivo de investigación. Limitado por la ley federal al uso en investigación.


                    <p>
                    

                    Herramienta DepCare eSDM

<br />

Fabricante/Distribuidor: Centro Médico Irving de la Universidad de Columbia

<br />

Centro de Salud Conductual Cardiovascular (CBCH) - Edificio Presbiteriano - PH9

<br />

622 W 168th Street Nueva York, NY 10032

                    </p>
                </p>
            </div>
            }
        </div>
        <div className="modalContainer">
            <Modal
                isOpen={(slideshowI >= 0) ? true : false}
                onRequestClose={closeModal}
                width="100%"
                style={modalStyles}
                ariaHideApp={false}
                onAfterOpen={loadSlideshow}
            >
                {slideshowI >= 0 &&
                    <div style={{
                        // margin: "10px 0",
                    }}>
                        {/* {pdfs[slideshowI].numPages === 1 ?
                            // <img src={pdf[slideshowI][0].source} style={{
                            //     width: "100%",
                            //     objectFit: "contain"
                            // }}></img>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            `}>
                                <Document renderMode="svg" file={pdfs[slideshowI]["source"]}>
                                    <Page pageNumber={1} />
                                </Document>
                            </div>
                        : */}
                        <div css={css`
                                // display: flex; 
                                // align-items: center; 
                                // justify-content: center; 
                                height: 100%;                            
                                width: 100%;
                            `}>
                            {/* <Carousel showThumbs={false} showStatus={true} css={css`height: 100%; width: 100%;`}>
                                {[...Array(pdfs[slideshowI]["numPages"]).keys()].slice(0, pdfs[slideshowI]["numPages"]+1).map((pdf, i) => {
                                        console.log("INDEXES");
                                        console.log(i);
                                        return <div key={i} css={css`
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        `}>
                                            <Document file={pdfs[slideshowI]["source"]}>
                                                <Page pageIndex={i} />
                                            </Document>
                                        </div>;
                                    })};
                                </Carousel> */}
                            <Button type={'primary'} style={{ position: "fixed", top: 20, right: 20, zIndex: 100 }} onClick={closeModal}>
                                X
                                </Button>
                            <Loader loaded={images.length > 0}>
                                <AwesomeSlider mobileTouch fillParent>
                                    {images.map((el, i) => {
                                        return (
                                            <div style={{ width: "100%", height: '100%', overflowY: "scroll" }} key={i}>
                                                <img key={i} style={{ width: "100%", height: '95vh', objectFit: "contain" }} src={el} />
                                            </div>
                                        );
                                    })}
                                </AwesomeSlider>
                                
                            </Loader>
                        </div>
                    </div>
                }
            </Modal>
        </div>
    </div>;
};

DepCareEducation.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
};
