import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

/** @jsx jsx */
const SummarySection = ({ children, ...others }) => {
  return <div 
      css={css`
        background-color: #051B2E;
        height: 100vh;
        flex: 0 0 250px;
        left: 0;
        top: 0;
      `}
      {...others}>
        {children}
      </div>;
};

SummarySection.propTypes = {
  children: PropTypes.any,
};

const SummarySectionHeader = ({ children, ...others }) => {
  return <div 
      css={css`
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        padding: 12px 15px;
        &.active {
          border-left: #318886 6px solid;
          background-color: #2F4862;
          padding: 12px 15px 12px 9px;
        }
      `}
      {...others}>
        {children}
    </div>;
};
SummarySectionHeader.propTypes = {
  children: PropTypes.any,
};


const SummarySectionItem = ({ children, to, ...others }) => {
  const style = css`
    color: white;
    padding: 12px 15px;
    text-decoration: none;
    display: block;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      border-left: #318886 6px solid;
      background-color: #2F4862;
      padding: 12px 15px 12px 9px;
    }
  `;

  const item = to ? <NavLink activeClassName="active" exact={true} css={style} to={to}>{ children }</NavLink> : <div css={style} {...others}>{children}</div>;

  return item;
};
SummarySectionItem.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
};

SummarySection.Header = SummarySectionHeader;

SummarySection.Item = SummarySectionItem;

export default SummarySection;