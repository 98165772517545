import { Fragment, useState, useEffect } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import { ImageGroup, Button } from 'ComponentLibrary';
import Modal from 'react-modal';
// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';
import ReactRouterPropTypes from 'react-router-prop-types';
import { css, jsx } from '@emotion/core';
import InformationForm from './InformationForm.js';


/** @jsx jsx */
export const LinkBetweenDepressionAndHeartDisease = ({ match }) => {
  const [slideshowI, setSlideshowI] = useState(-1);
  //getCompletedAssessment
  console.log(123);

  const { material } = match.params;
  let imagesArray = [];
  let subImages = [];
  if (material == 'link-between-depression-and-heart-disease') {
    console.log('IS H');
      imagesArray = [
      {
        url: "/api/webroot/uploads/education/5_things_you_should_know_about_depression_ico.png",
        alt: "Depression Resources",
        overlayText: "5 Things you should know About Depression"
      },
      {
        url: "/api/webroot/uploads/education/link_between_depression_and_heart_disease_ico.png",
        alt: "Heart Health",
        overlayText: "Link between stress & Heart Disease"
      },
    ];
    subImages = [[
      {
        id: 0,
        source: "/api/webroot/uploads/education/5_things_you_should_know_about_depression.png",
      }

    ], [
      {
        id: 1,
        source: "/api/webroot/uploads/education/the_link_between_stress_and_heart_disease.png",
      },
    ]];
  }
  else if (material == 'managing-my-heart-disease') {
      imagesArray = [
      {
        url: "/api/webroot/uploads/education/what_is_a_heart_attack_ico.png",
        alt: "Depression Resources",
        overlayText: "Self-Care Strategies"
      },
      {
        url: "/api/webroot/uploads/education/lower_your_risk_of_a_heart_attack_ico.png",
        alt: "Heart Health",
        overlayText: "Therapy & Medications"
      },
      {
        url: "/api/webroot/uploads/education/how_do_i_reduce_my_stress_levels_ico.png",
        alt: "Heart Health",
        overlayText: ""
      },
    ];
    subImages = [[
      {
        id: 0,
        source: "/api/webroot/uploads/education/what_is_a_heart_attack.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/how_is_heart_disease_diagnosed.png",
      },
    
    ], [
      {
        id: 1,
        source: "/api/webroot/uploads/education/lower_your_risk_of_heart_attack.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/get_a_head_start.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/exercise.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/examples_of_moderate_level_physical_activities.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/healthy_diet.png",
      },
      
    ],[
      {
        source:'/api/webroot/uploads/education/self_care_strategies_for_preventing_depressive_symptoms.png'
      },
      {
        source:'/api/webroot/uploads/education/exercise.png'
      },
      {
        source:'/api/webroot/uploads/education/examples_of_moderate_level_physical_activities.png'
      },
      {
        source:'/api/webroot/uploads/education/healthy_diet.png'
      },
      {
        source:'/api/webroot/uploads/education/things_you_can_do_every_day.png'
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/connect_to_treatment.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/links_to_community_resources.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/free_hotline_numbers.png",
      },
      {
        source:"/api/webroot/uploads/education/how_do_medication_and_therapy_compare.png"
      }
    ]];
  }
  else if (material == 'selfcare-and-treatment') {
    console.log('IS H');
      imagesArray = [
      {
        url: "/api/webroot/uploads/education/self_care_strategies_ico.png",
        alt: "Depression Resources",
        overlayText: "Self-Care Strategies"
      },
      {
        url: "/api/webroot/uploads/education/therapy_and_medication_ico.png",
        alt: "Heart Health",
        overlayText: "Therapy & Medications"
      },
      {
        url: "/api/webroot/uploads/education/exercise_and_cardiac_rehab_ico.png",
        alt: "Heart Health",
        overlayText: "Exercise & Cardiac Rehab"
      },
    ];
    subImages = [[
      {
        id: 0,
        source: "/api/webroot/uploads/education/self_care_strategies_for_preventing_depressive_symptoms.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/exercise.png",
      },
      {
        source:'/api/webroot/uploads/education/examples_of_moderate_level_physical_activities.png'
      },
      {
        source:'/api/webroot/uploads/education/healthy_diet.png'
      },
      {
        source:'/api/webroot/uploads/education/things_you_can_do_every_day.png'
      }
    ], [
      {
        id: 1,
        source: "/api/webroot/uploads/education/ways_to_talk_to_your_mental_health_provider.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/questions.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/connect_to_treatment.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/links_to_community_resources.png",
      },
      {
        id: 1,
        source: "/api/webroot/uploads/education/free_hotline_numbers.png",
      },
      {
        source:"/api/webroot/uploads/education/how_do_medication_and_therapy_compare.png"
      }
    ],[
      {
        source:'/api/webroot/uploads/education/exercise_and_cardiac_rehabilitation.png'
      },
      {
        source:'/api/webroot/uploads/education/cardiac_rehabilitation_and_heart_disease.png'
      }
    ]];
  }
  function closeModal() {
    setSlideshowI(-1);
  }
  useEffect(() => {
  }, []);

  const modalStyles = {
    content : {
        position: 'static',
        height: "100%"      
    }
  };


  return <Fragment>
    <div css={css`
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // height: 70vh;
    `}>
      <h3>Click on an image to learn more about that topic.</h3>
      <ImageGroup style={{ height: '600px' }} onClick={(obj) => { console.log(obj); setSlideshowI(obj); }} imagesArray={imagesArray} size="large">
      </ImageGroup>
      
      <InformationForm material={material} />
    </div>
    <div className="modalContainer">
      <Modal
        isOpen={(slideshowI >= 0) ? true : false}
        onRequestClose={closeModal}
        width="100%"
        style={modalStyles}
      >
        {slideshowI >= 0 &&
          <div style={{
            margin: "10px 0",
          }}>
          {/* {subImages[slideshowI].length === 1?
            <img src={subImages[slideshowI][0].source} style={{
              width: "100%",
              objectFit: "contain"
            }}></img>
          : */}
            <div css={css`
              * img {
                object-fit: contain;
              }
            `}>
              <Button type={'primary'} style={{ position: "fixed", top: 20, right: 20, zIndex: 100}} onClick={() => { console.log('fsdf'); setSlideshowI(-1); }}>
                X
              </Button>
              <AwesomeSlider
                style={{
                  height: "auto", 
                  img: {
                    objectFit: "contain"
                  }
                }}
                mobileTouch fillParent
                media={subImages[slideshowI]}
              />

              {/* <Carousel showThumbs={false} showStatus={false} css={css`
                * img {
                  object-fit: contain;
                  // height: 90vh;
                }
              `
              }>
                {subImages[slideshowI].map((src, i) => {
                  console.log(src.source);
                  return <div key={i}>
                    <img alt="img" src={src.source} />
                  </div>;
                })}
              </Carousel> */}

            </div>
          {/* } */}
          <Button type={'primary'} style={{display: "block", marginTop: "40px"}} onClick={() => { console.log('fsdf'); setSlideshowI(-1); }}>
              Close
          </Button>
          </div>
        }
      </Modal>
    </div>
  </Fragment>;
};

LinkBetweenDepressionAndHeartDisease.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,

};
