import React, { useEffect, useState } from 'react';
import { fetchLocation } from '../../services/LocationsService';
import { saveLocationProvider } from '../../services/ProvidersService';
import { deleteProvider } from '../../services/ProvidersService';
import { Permission } from '../../../../shared/Security/index';

import ReactRouterPropTypes from 'react-router-prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

import SortableTable from './../../components/SortableTable';

const ViewLocation = ({ match }) => {
    const { location_id } = match.params;
    const [location, setLocation] = useState({
        name: '',
        providers: [],
    });
    const [deleteProviderId, setDeleteProviderId] = useState(null);
    const [errors, setErrors] = useState({
        name: {},
    });
    const [tableHeaders] = useState([
        {
            name: 'Name',
            key: 'name',
            type: 'string',
            sortable: true,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Email',
            key: 'email_address',
            type: 'string',
            sortable: true,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Remove Provider',
            key: 'remove_provider',
            type: 'string',
            sortable: false,
            callback: false,
            align: 'center',
            link: false,
            allowed:['Providers.Delete'],
            remove: handleDeleteDialogOpen
        },
    ]);
    const [newProvider, setNewProvider] = useState({ name: '', email_address: '', location_id: location_id });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const handleInputChange = e => {
        const { name, value } = e.target;

        setNewProvider({ ...newProvider, [name]: value });
    };
    function createNewProvider() {
        console.log(newProvider);
        saveLocationProvider(newProvider).then((data) => {
            if (data.success) {
                handleDialogClose();
                getLocationDetail();
            }
            else {
                setErrors(data.data);
            }
        });
    }
    function getLocationDetail() {
        fetchLocation(location_id).then((response) => {
            setLocation(response.data);
        });
    }
    useEffect(() => {
        if (location.name == '') {
            getLocationDetail();
        }
    });

    const handleDialogOpen = () => {
        setDialogOpen(true);

    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setDeleteProviderId(null);

        setDeleteDialogOpen(false);
        getLocationDetail();

    };
    function handleDeleteDialogOpen(id){
        console.log(id + '---');
        setDeleteProviderId(id);
        setDeleteDialogOpen(true);
    };
    const handleDeleteDialogClose = () => {
        setDeleteProviderId(null);

        setDeleteDialogOpen(false);
    };

    function createData(id, name, email_address) {
        return { id, name, email_address };
    }

    const rows = location.providers.map(provider => {
        return createData(
            provider.id, provider.name, provider.email_address
        );
    });

    const errorsToElements = (errors, i) => {
        return <div>
            {Object.values(errors).map(err => <Typography key={`err-${i}`}>{err}</Typography>)}
        </div>;
    };


    const classes = makeStyles({
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        root: {
            marginTop: 30,
        },
        infoContainer: {
            display: "grid",
            gridTemplateColumns: "150px 1fr",
            gridGap: 10
        },
        table: {
            minWidth: 700,
        },
        tableContainer: {
            margin: "30px 0px"
        },
        dialog: {
            padding: 30,
            display: 'grid',
            width: 360,
            rowGap: 20
        },
        "dialog button": {
            marginTop: 10
        },
        content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        infoheader: {
            color: '#263238'
        },
        infobody: {
            color: '#546e7a'
        },
        infosection: {
            marginTop: 20
        },
        providerBtn: {
            justifySelf: 'end'
        },
        buttonsGroup: {
            display: 'flex',
            justifyContent: 'space-between'
        }
    })();
    return <div className={classes.container}>
        <Card className={classes.root}>
            <CardContent className={classes.content}>

                <div>
                    <div className={classes.infoContainer}>
                        <Typography variant="h6" className={classes.infoheader}>
                            <b>{location.name}</b>
                        </Typography>
                    </div>

                    <div className={classes.infosection}>
                        <Permission allowed="Clinics.ViewClinicPassword">
                        <div className={classes.infoContainer}>
                            <Typography variant="subtitle1" className={classes.infoheader}>
                                <b>Passcode:</b>
                            </Typography>
                            <Typography variant="subtitle1" className={classes.infobody}>{location.password}</Typography>
                        </div>
                        </Permission>
                        <div className={classes.infoContainer}>
                            <Typography variant="subtitle1" className={classes.infoheader}>
                                <b>Link: </b>
                            </Typography>
                            <Typography variant="subtitle1" className={classes.infobody}>
                                <Link href={`${window.location.protocol}//${window.location.hostname}${window.location.port? (':' + window.location.port) : ''}/depcare/overview/${location.token}`}>Clinical Dashboard</Link>
                            </Typography>
                        </div>
                    </div>


                    <br></br> <Divider /> <br></br>
                </div>
                <div className="providers">
                    <Permission allowed="Providers.Add">

                    <div className={classes.infoContainer}>
                        <Typography variant="h6" className={classes.infoheader}>Providers</Typography>

                        <Button variant="contained" size='sm' color="primary" className={classes.providerBtn} onClick={handleDialogOpen}>Add Provider</Button>
                    </div>
                    </Permission>
                    <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                        <DialogContent className={classes.dialog}>
                            <Typography variant='h6'>Add Provider</Typography>
                            <TextField
                                required
                                fullWidth={true}
                                id="new-provider-name"
                                name="name"
                                label="Provider Name"
                                variant="outlined"
                                value={newProvider.name ? newProvider.name : ''}
                                onChange={handleInputChange}
                                error={errors.name.length === 0 ? true : undefined}
                                helperText={errors.name.length === 0 ? errorsToElements(errors.name) : ''}
                                style={{ marginBottom: 10 }}
                            />
                            <FormControl
                                fullWidth={true}
                                label="Provider"
                                variant="outlined"
                                error={errors.type ? true : undefined}
                                helperText={errors.type ? errorsToElements(errors.type) : ''}
                            >
                                <InputLabel id="provider-select-label">Type</InputLabel>
                                <Select
                                    labelId="provider-select-label"
                                    id="provider-select"
                                    name="type"
                                    onChange={handleInputChange}
                                    value={newProvider.type}
                                >
                                    <MenuItem key={`provider-0`} value={'physician'}>Physician</MenuItem>
                                    <MenuItem key={`provider-1`} value={'specialty'}>Specialty</MenuItem>
                                    <MenuItem key={`provider-2`} value={'care-manager'}>Care Manager</MenuItem>

                                </Select>
                            </FormControl>
                            <TextField
                                required
                                fullWidth={true}
                                type="email"
                                variant="outlined"
                                id="new-provider-email"
                                name="email_address"
                                // name="new-provider-email"
                                label="Provider Email"
                                value={newProvider.email_address ? newProvider.email_address : ''}
                                onChange={handleInputChange}
                                error={errors.email_address ? true : undefined}
                                helperText={errors.email_address ? errorsToElements(errors.email_address) : ''}
                                style={{ marginBottom: 10 }}
                            />
                            <Button style={{ justifySelf: 'end' }} variant="contained" size='sm' color="primary" onClick={createNewProvider}>Create Provider</Button>
                        </DialogContent>
                    </Dialog>
                    {/* <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} aria-label="customized table" >
                            <TableHead style={{ backgroundColor: '#fff' }}>
                                <TableRow >
                                    <StyledTableCell align="left">Name</StyledTableCell>
                                    <StyledTableCell align="left">Email</StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, i) => (
                                    <StyledTableRow key={`provider-${i}`}>
                                        <StyledTableCell align="left">{row.name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.email_address}</StyledTableCell>

                                        <StyledTableCell align="right">
                                            <Button variant="contained" color="secondary" onClick={() => { handleDeleteDialogOpen(row.id); }}><DeleteIcon /></Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                    <SortableTable
                        className={classes.tableContainer}
                        data={rows}
                        headers={tableHeaders}
                    />
                </div>
                <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                    <DialogContent className={classes.dialog}>
                        <Typography style={{ marginBottom: 10 }} variant="h6" >Are you sure?</Typography>
                        <div className={classes.buttonsGroup}>
                            <Button variant="contained" color="primary" onClick={() => { handleDeleteDialogClose(); }}>Cancel</Button>
                            <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() => { deleteProvider(deleteProviderId); handleDialogClose(); }}>Remove Provider</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </CardContent>
            <CardActions>
            </CardActions>
        </Card>
    </div>;
};

ViewLocation.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
};
export default ViewLocation;