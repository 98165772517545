import React, { useEffect, useState } from 'react';
import { fetchLocations } from '../../services/LocationsService';
import AddLocation from './AddLocation.js';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import SortableTable from '../../components/SortableTable';
import TextField from '@material-ui/core/TextField';
import { Permission } from '../../../../shared/Security/index';

const IndexPage = () => {
    const classes = makeStyles({
        h2: {
            display: 'inline-block'
        },
        search: {
            marginLeft: 20,
            marginTop: 15,
        },
        container: {
            maxWidth: 1080,
            margin: `0px auto 0`
        }

    })();
    const [locations, setLocations] = useState([]);
    const [tableHeaders] = useState([
        {
            name: 'Name',
            key: 'name',
            type: 'string',
            sortable: true,
            callbackPermission:['Clinics.Add'],

            callback: editLocation,
            link: false,
            remove: false,
        },
        {
            name: 'Providers',
            key: 'number_of_providers',
            type: 'number',
            sortable: false,
            callback: false,
            link: false,
            remove: false,
            align: 'center',
        },
        {
            name: 'Assessments Completed',
            key: 'number_of_assessments_completed',
            type: 'number',
            sortable: false,
            callback: false,
            
            link: false,
            remove: false,
            align: 'center',
        },
        {
            name: 'Details',
            key: 'view_location',
            type: 'number',
            sortable: false,
            allowed:['Clinics.View'],
            callback: false,
            link: true,
            linkText: "View Clinic",
            remove: false,
            isButton: true,
            align: 'center',
            isNewPage: true
        },
    ]);
    const [page, setPage] = useState(1);
    const [lastPageLoaded, setLastPageLoaded] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [locationToEdit, setLocationToEdit] = useState(-1);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');


    function loadLocations() {
        // console.log("before the promise");
        fetchLocations(page, search, order, orderBy).then((response) => {
            console.log(response.data);
            setLastPageLoaded(response.__pagination.Locations.page);
            const processedData = processData(response.data);
            setLocations(processedData);
            setTotalPageCount(response.__pagination.Locations.pageCount);

        });
    }

    useEffect(() => {
        console.log('search loop');
        setPage(1);
        loadLocations();
    },[search, order, orderBy]);

    function processData(data){
        const processedData = [];
        data.map(row => {
            processedData.push({
                ...row,
                view_location: `/admin/clinics/view/${row.id}`
            });
        });
        return processedData;
    };

    useEffect(() => {
        if (page != lastPageLoaded) {
            
            loadLocations();
        }
        else if (locations.length == 0 && search === '') {
            loadLocations();
        }
    });
    
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setLocationToEdit(-1);
    };
    const handleAddSuccess = () => {
        handleDialogClose();
        loadLocations();
    };
    function editLocation(id){
        setLocationToEdit(id); 
        handleDialogOpen(); 
    };
    function handleSearch(e){
        console.log(e.target.value);
        console.log("changing");
        setSearch(e.target.value);
    }
    function handleSortChange(key, orderParam){
        setOrder(orderParam);
        setOrderBy(key);
        console.log(order, orderBy);
    }
    const StyledPagination = withStyles(() => ({
        root: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px"
        }
    }))(Pagination);

    return <div className={classes.container}>
        <Grid container justify="space-between" pacing={3}>

            <Grid item xs={9}>
                <h2 className={classes.h2}>Clinics</h2>
                <TextField value={search} onChange={handleSearch} size={'small'} className={classes.search} id="standard-basic" label="Search Clinic Name" variant="outlined"/>            
            </Grid>
            <Permission allowed="Clinics.Index">

            <Grid item xs={2} style={{ textAlign: 'right', marginTop: 20 }}>
                {/* <Link href="/admin/clinics/add"> */}
                <Permission allowed="Clinics.Add">

                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleDialogOpen}
                    >
                        Add
                    </Button>
                    </Permission>
                    <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <AddLocation
                                onSuccess={handleAddSuccess}
                                locationToEdit={locationToEdit}
                            />
                        </DialogContent>
                    </Dialog>
                {/* </Link> */}
            </Grid>
            </Permission>
        </Grid>
        <SortableTable
            data={locations}
            headers={tableHeaders}
            defaultOrder="asc"
            defaultOrderBy="name"
            sortCallback={handleSortChange}
        />
        <StyledPagination
            count={totalPageCount}
            size={"medium"}
            defaultPage={1}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
        />    

    </div>;
};

export default IndexPage;