import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
// import { PaginationItem } from '@material-ui/lab';
// import { Pagination } from '@material-ui/lab';
/** @jsx jsx */
export const Pagination = ({ totalPageCount, current, onPageChange }) => {
  const theme = useTheme();
  const [maxPages, setMaxPages] = useState(window.innerWidth < 768? 3: 5);
  const [pageRange, setPageRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(current);
  useEffect(() => {
    if(pageRange.length !== 0){
      shiftPageRange();
    };
  }, [currentPage, maxPages]);
  useEffect(() => {
    setTimeout(() => {
      if (pageRange.length === 0){
        setPageRange([...Array(totalPageCount).keys()].slice(0, maxPages).map(i => i += 1));
      };
    }, 100);
    const onResize = () => {
      if (window.innerWidth < 768 && maxPages === 5) {
        setMaxPages(3);
        window.removeEventListener('resize', onResize);
      };
      if (window.innerWidth >= 768 && maxPages === 3) {
        setMaxPages(5);
        window.removeEventListener('resize', onResize);
      };
    };
    window.addEventListener('resize', onResize);
  });

  let incPage = () => {
    if (currentPage < totalPageCount) {
      const plusOne = currentPage + 1;
      setCurrentPage(plusOne);
      onPageChange(plusOne);
    }
  };
  let decPage = () => {
    if (currentPage > 0) {
      const minusOne = currentPage - 1;
      setCurrentPage(minusOne);
      onPageChange(minusOne);
    }
  };
  let shiftPageRange = () => {
    let list = [];
    const offset = (maxPages - 1) / 2; // Offset in either direction
    let lower; // Lower bound
    let upper; // Upper bound
    if (totalPageCount <= maxPages){
      lower = 1;
      upper = totalPageCount;
    } else if (currentPage <= offset) {  
      lower = 1;
      upper = maxPages;
    } else if (currentPage > totalPageCount - offset) { // make sure upper is at most totalPageCount
      lower = totalPageCount - 2 * (offset);
      upper = totalPageCount;
    } else {
      lower = currentPage - offset;
      upper = currentPage + offset;
    }
    for( let i = lower; i <= upper; i++) {
      list.push(i); // populate list
    }
    setPageRange(list);
  };

  return (
    <div css={css`display: flex; justify-content: center; width: 100%`}>

    <div
      css={css`
        margin-top: 5vh;
        font-family: ${theme.texts.font.family.default};
        border: 1px solid #B3B3B3;
        border-radius: 4px;
        display: flex;
        width: 90%;
        justify-content: space-between;
        a {
          width: 100%;
          text-decoration: none;
          color: #000000;
          padding: 20px;
          text-align: center;
          &:not(:first-of-type) {
            border-left: 1px solid #B3B3B3;
          }
          &:not(:last-of-type) {
            border-right: 1px solid #B3B3B3;
          }
          &.hidden {
            display: none;
          }
          &:hover {
            background-color: #E1E1E1;
            cursor: pointer;
          }
          &.disabled {
            pointer-events: none;
            color: #B3B3B3;
          }
          &.selected {
            background-color: #E1E1E1;
          }
          @media (max-width: 768px) {
            &.dots, &.prev, &.next {
              display: none;
            }
            font-size: ${theme.texts.font.size.md}px;
          }
          @media (min-width: 768px) {
            &.prevArrow, &.nextArrow, &.firstPage, &.lastPage {
              display: none;
            }
          }
        }
      `}>
      {/* <Pagination
        count={totalPageCount}
        defaultPage={1}
        page={currentPage}
        onChange={(e, page) => {
          setCurrentPage(a); 
          onPageChange(a);
        }}
        hideNextButton={currentPage >= totalPageCount ? true : false}
        hidePrevButton={currentPage === 1 ? true : false}
      /> */}
      <a className={`prevArrow ${currentPage === 1 ? 'disabled' : ''}`} onClick={decPage}>&laquo;</a>
      <a className={`prev ${currentPage === 1 ? 'disabled' : ''}`} onClick={decPage}>Previous</a>
      <a className={`dots ${pageRange[0] == 1 ? 'hidden' : 'disabled'}`}>...</a>
      {pageRange.map(a =>
        <a
          key={a}
          onClick={() => {
            if (a <= totalPageCount) {
              setCurrentPage(a); 
              onPageChange(a);
            }
          }}
          className={currentPage === a ? 'selected' : ''}
        >
          {a}
      </a>)}
      <a className={`dots ${pageRange[pageRange.length - 1] == totalPageCount? 'hidden' : 'disabled'}`}>...</a>
      <a className={`nextArrow ${currentPage >= totalPageCount ? 'disabled' : ''}`} onClick={incPage}>&raquo;</a>
      <a className={`next ${currentPage >= totalPageCount ? 'disabled' : ''}`} onClick={incPage}>Next</a>
    </div>
    </div>

  );
};

Pagination.propTypes = {
  /** Total page count */
  totalPageCount: PropTypes.number.isRequired,
  /** Current Page */
  current: PropTypes.number.isRequired,
  /** callback to execute when a button is clicked */
  onPageChange: PropTypes.func
};

Pagination.defaultProps = {
  current: 1
};
