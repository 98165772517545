import React, { Fragment } from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';

// layouts
import { AdminLayout } from '../layouts/AdminLayout';

// Patients
import IndexPage from '../pages/patients/IndexPage';
import ResultsPage from '../pages/patients/ResultsPage';
import AddPatient from '../pages/patients/AddPatient';
import ViewPatient from '../pages/patients/ViewPatient';

// Locations
import ClinicIndexPage from '../pages/locations/IndexPage';
import AddLocation from '../pages/locations/AddLocation';
import ViewClinic from '../pages/locations/ViewLocation';

//Downloads
import DownloadOverview from '../pages/downloads/IndexPage';


// Admins
import AdminIndexPage from '../pages/users/IndexPage';
import AdminAdd from '../pages/users/AddUser';

import { RolesPage } from '../pages/users/RolesPage';
import AdminGroupIndexPage from '../pages/admin-groups/IndexPage';

import AuthPage from '../pages/auth/AuthPage';
import Unauthorized from '../pages/auth/Unauthorized';
import ResetPassword from '../pages/auth/ResetPassword';

const PluginRouter = () => (
    <Fragment>
        <Switch>
            <Route path='/admin/unauthorized' component={Unauthorized} theme={"depcare"} exact={true} />
            <AdminLayout path='/admin/patients' component={IndexPage} theme={"depcare"} exact={true} />
            <AdminLayout path='/admin/patients/add/' theme={"depcare"} component={AddPatient} exact={true} />
            <AdminLayout path='/admin/patients/edit/:patient_id' theme={"depcare"} component={AddPatient} exact={true} />

            <AdminLayout path='/admin/patients/view/:patient_id' theme={"depcare"} component={ViewPatient} exact={true} />
            <AdminLayout path='/admin/patients/results/:patient_assessment_id' theme={"depcare"} component={ResultsPage} exact={true} />

            <AdminLayout path='/admin/clinics' component={ClinicIndexPage} theme={"depcare"} exact={true} />
            <AdminLayout path='/admin/clinics/add/' theme={"depcare"} component={AddLocation} exact={true} />
            <AdminLayout path='/admin/clinics/edit/:location_id' theme={"depcare"} component={AddLocation} exact={true} />

            <AdminLayout path='/admin/clinics/view/:location_id' theme={"depcare"} component={ViewClinic} exact={true} />

            <AdminLayout path='/admin/admins' component={AdminIndexPage} theme={"depcare"} exact={true} />
            <AdminLayout path='/admin/admins/add/' theme={"depcare"} component={AdminAdd} exact={true} />
            <AdminLayout path='/admin/admins/add/:user_id' theme={"depcare"} component={AdminAdd} exact={true} />

            <AdminLayout path='/admin/downloads' component={DownloadOverview} theme={"depcare"} exact={true} />

            <AdminLayout path='/admin/roles' component={AdminGroupIndexPage} theme={"depcare"} exact={true} />
            <AdminLayout path='/admin/roles/:role_id' component={RolesPage} theme={"depcare"} exact={true} />
            <Route path='/admin/patients/print-results/:patient_assessment_id' theme={"depcare"} component={ResultsPage} exact={true} />

            <Route path='/admin/' theme={"depcare"} component={AuthPage} exact={true} />
            <Route path='/admin/reset/:token' component={ResetPassword} theme={"depcare"} exact={true} />



        </Switch>
    </Fragment>
);

export { PluginRouter };