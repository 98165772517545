import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
/** @jsx jsx */
const SectionIndexItem = ({ active, name, to }) => {
  let item = (<div className={`${active ? 'active' : ''}`} css={css`
          color: white;
          display: flex;
          align-items: stretch;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          padding: 12px 15px;
          &.active {
            border-left: #318886 6px solid;
            background-color: #2F4862;
            padding: 12px 15px 12px 9px;
          }
          .name {
            margin-right: auto;
            font-weight: 600;
          }
          .index-number {
            flex: 0 0 25px;
            font-weight: 600;
          }
          .percentage {
            flex: 0 0 25px;
          }
        `}>
    <div className="name">{name}</div>
  </div>);
  if (to) {
    item = <NavLink to={to}>{item}</NavLink>;
  }
  return item;
};

SectionIndexItem.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,

};

SectionIndexItem.defaultProps = {
  active: false,
};

export default SectionIndexItem;