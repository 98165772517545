import {
    FETCH_USER_DATA_FULFILLED,
    FETCH_USER_DATA_REJECTED,
    SET_USER_DATA,
    LOGOUT_USER,
} from '../actions/AuthActions';

const stringifiedUser = sessionStorage.getItem('userData');
let userData = null;
if(stringifiedUser && typeof stringifiedUser === 'string' && stringifiedUser !== 'undefined') {
    try {
        userData = JSON.parse(stringifiedUser);
    }
    catch(e) {
        console.error(e);
    }
}
const initialState = stringifiedUser && userData !== null ? { userData, loggedIn: true, retrieved: false } : {
    loggedIn: false,
    retrieved: false,
    userData: {
        language:1,
        soundOn:false,
        theme: (document.URL.indexOf('depcare') !== -1)?'depcare':'iheart',
        token: null,
    }
};

export const AuthReducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_USER_DATA_FULFILLED:
            return {
                ...state,
                loggedIn: true
            };
        case FETCH_USER_DATA_REJECTED:
            return {
                ...state,
                loggedIn: false
            };
        case SET_USER_DATA:
            return {
                ...state,
                retrieved: true,
                loggedIn: action.payload && action.payload.id ? true : false,
                userData: action.payload,
            };
        case LOGOUT_USER:
            return {
                ...state,
                userData: null,
                loggedIn: false
            };
        default:
            return state;
    }
};