import React, { Fragment } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MainLayout from '../layouts/MainLayout';
// import AuthPage from '../pages/auth/AuthPage';
// import IHeartStep1PatientInfo from '../pages/iheart/introductions/Step1-PatientInfo';
import { TakeAssessment } from '../pages/questionnaire/TakeAssessment';

// import  IndexPage from '../pages/Index';
import { TokenPage } from '../pages/auth/TokenPage';
import { LinkBetweenDepressionAndHeartDisease } from '../pages/education/LinkBetweenDepressionAndHeartDisease';
import { DepCareEducation } from '../pages/education/DepCareEducation';

import Overview from '../pages/providers/Overview';


import { PluginRouter as AdminRouter } from 'Plugins/admin/routers/PluginRouter';

const AppRouter = () => {
  

    const plugins = [
        AdminRouter
    ];

    return <BrowserRouter>
        <Fragment>
            <Switch>
                {/* Authentication */}
                {/* <Route path='/admin' component={AuthPage} exact={true} /> */}


                {/* <Route path='/' component={IndexPage}  exact={true} /> */}

                <Route path='/:provider/token/:token/:language/:method' component={TokenPage}  exact={true} />
                <MainLayout path='/iheart/education/:material/:token' component={DepCareEducation} exact={true} />
                <MainLayout path='/depcare/education/:material/:token' component={DepCareEducation}  exact={true} />

                <MainLayout path='/depcare/overview/:token' component={Overview}  exact={true} />
                <MainLayout path='/iheart/overview/:token' component={Overview}  exact={true} />

                <MainLayout path='/'  component={TakeAssessment}  exact={true} />
            </Switch>
            { plugins.map( (PluginRouters, index) => <PluginRouters key={`plugin-router-${index}`} /> )}
        </Fragment>
    </BrowserRouter>;
};

export { AppRouter };
