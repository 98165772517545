import React, { useEffect, useState } from 'react';
import { fetchUsers } from '../../services/UsersService';
import AddUser from './AddUser.js';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import SortableTable from './../../components/SortableTable';
import { useHistory } from 'react-router-dom';
import { Permission } from '../../../../shared/Security/index';

const IndexPage = () => {
    const [users,setUsers] = useState([]);
    const [tableHeaders] = useState([
        {
            name: 'Last Name',
            key: 'last_name',
            type: 'string',
            sortable: true,
            callback: editUser,
            link: false,
            remove: false
        },
        {
            name: 'First Name',
            key: 'first_name',
            type: 'string',
            sortable: true,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Admin Email',
            key: 'email_address',
            type: 'string',
            sortable: true,
            callback: false,
            link: false,
            remove: false,
            align: 'right'
        },
      
    ]);
    const [page, setPage] = useState(1);
    const [lastPageLoaded, setLastPageLoaded] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userToEdit, setUserToEdit] = useState(-1);
    const history = useHistory();

    const classes = makeStyles({
        container: {
            maxWidth: 960,
            margin: '0 auto'
        }
    })();

    function loadUsers(){
        fetchUsers(page).then((response) => {
            // console.log(response);
            setLastPageLoaded(response.__pagination.Users.page);
            console.log(response.data);
            setUsers(response.data);
            setTotalPageCount(response.__pagination.Users.pageCount);
            
        });
    }
    useEffect(() => {
        if(page != lastPageLoaded){
            loadUsers();
        }
        else if(users.length == 0){
            loadUsers();
        }
    });

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setUserToEdit(-1);
    };
    const handleAddSuccess = () => {
        handleDialogClose();
        loadUsers();
    };
    function editUser(id){
        setUserToEdit(id); 
        handleDialogOpen();
    };
    const StyledPagination = withStyles(() => ({
        root: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
        }
    }))(Pagination);

    return <div className={classes.container}>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <h2>Admins</h2>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: 20 }}>
                {/* <Link href="/admin/clinics/add"> */}
                <Permission allowed="Roles.View">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            history.push('/admin/roles');
                        }}
                    >
                        Roles
                    </Button>
                </Permission>
                {/* <Button
                    variant="contained"
                    color="default"
                    onClick={handleDialogOpen}
                >
                    Add
                </Button> */}
                <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <AddUser
                            onSuccess={handleAddSuccess}
                            userToEdit={userToEdit}
                        />
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
        <SortableTable
            data={users}
            headers={tableHeaders}
        />
        <StyledPagination
            count={totalPageCount}
            size={"medium"}
            defaultPage={1}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
        />
    </div>;
};

export default IndexPage;