import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
// import FormError from '../FormError/FormError';

/** @jsx jsx */
export const FormGroup = ({ label, children, errors }) => {
    // const theme = useTheme();
    return <div css={css``} className={`form-group ${Object.values(errors).length > 0 ? 'errors' : ''}`}>
        <label>{ label }</label>
        <div>{ children }</div>
        {/* { typeof props.errors === 'object' && (<FormError error={Object.values(props.errors)} />)} */}
    </div>;
};

FormGroup.propTypes = {
    /** Title of the input */
    label: PropTypes.string.isRequired,
    /** Content of the FormGroup, usually a \<Input> */
    children: PropTypes.any,
    /** Set when this FormGroup has an error //TODO: not used? */
    errors: PropTypes.object,
};

FormGroup.defaultProps = {
    errors: {}
};