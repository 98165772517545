import React, { useEffect, useState, Fragment } from 'react';
import { fetchPermissions } from '../../services/UsersService';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory, useParams } from 'react-router-dom';
import { fetchRole, saveRole } from '../../services/RolesService';
import { Button } from '../../../../shared/index';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
}));

export const RolesPage = () => {
    const classes = useStyles();
    const params = useParams();
    const history = useHistory();
    const [checked, setChecked] = useState([0]);
    const [ permissions, setPermissions ] = useState({});
    const [ role, setRole ] = useState({ permissions: {} });

    useEffect(() => {
        fetchPermissions().then(response => {
            setPermissions(response.data.permissions);
        });
        fetchRole(params.role_id).then(response => {
            const r = { ...response.role };
            r.permissions = JSON.parse(r.permissions);
            if(!r.permissions) {
                r.permissions = {};
            }
            setRole(r);
        });
    }, []);


    const handleToggle = (value) => () => {
        const r = { ...role };
        r.permissions[value] = !r.permissions[value];
        setRole(r);
    };

    const onSave = () => {
        saveRole(role).then(response => {
            history.push('/admin/roles/');
        });
    };

    const keys = Object.keys(permissions);

    return role && <div>
        <h1>{ role.name }</h1>
        <div style={{ background: 'white', padding: '25px', borderRadius: '15px' }}>
        { keys.map(key => {
            return <>
                <h3>{ key }</h3>
                <List key={`list-${key}`} className={classes.root}>
                { permissions[key].map((value) => {
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                    <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                        <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={!!role.permissions[value]}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value} />
                    </ListItem>
                    );
                })}
            </List>
            </>;
      })}
      <div style={{textAlign: 'right'}}>
            <Button style={{margin: "10px 0px"}} onClick={onSave} size="large" type="submit" variant="contained" color="primary">
                Save
            </Button>
      </div>
      </div>
    </div>;
};