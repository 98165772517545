import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

/** @jsx jsx */
export const Button = ({ children, size, type, ...rest }) => {
  const theme = useTheme();
  return (
    <button
      css={css`
        background-color: ${theme.colors[type]};
        color: ${theme.texts.colors[type]};
        border-radius: 100px;
        border: none;
        padding: 10px 25px;
        font-size: ${theme.texts.font.size[size]}px;
        cursor: pointer;
        &:hover {
          background-color: ${theme.colors[`${type}Hover`]};
        }
      `}
      {...rest}
      >
        {children}
    </button>
  );
};

Button.propTypes = {
  /** Defines the type of the button */
  type: PropTypes.oneOf(['default', 'primary', 'info', 'warning', 'danger', 'success']),
  /**  Defines the size of the button */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**  @ignore */
  children: PropTypes.string.isRequired,
};

Button.defaultProps = {
  size: 'md',
  type: 'default',
};
