import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";

/** @jsx jsx */
export const ButtonGroup = ({
  canGoBack,
  language,
  canGoNext,
  onLeftClick,
  onRightClick,
}) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        position: relative;
        width: ${language === 1 ? "400px" : "550px"};
        margin: 0 auto;
        .text {
          margin-right: 60px;
        }
        .right-arrow,
        .left-arrow {
          position: absolute;
          display: block;
          width: 60px;
          top: 0;
          height: 100%;
        }

        button {
          position: relative;
        }

        .right-arrow {
          right: 0;
          border-left: 2px solid hsla(216, 7%, 55%, 0.4);
          padding-left: 15px;
          position: absolute;
        }

        .left-arrow {
          left: 0;
          border-right: 2px solid hsla(216, 7%, 55%, 0.4);
          padding-right: 15px;
          position: absolute;
        }

        i {
          display: inline-block;
          padding: 7px;
          position: relative;
          top: 10%;
        }

        .left {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          border: solid #858c95;
          border-width: 0 4px 4px 0;
        }
        .right {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          border: solid ${theme.colors.primary};
          border-width: 0 4px 4px 0;
        }

        .back-button,
        .next-button {
          border: none;
          letter-spacing: 4.8px;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 75px;
          cursor: pointer;
          padding: 10px 25px;
          font-size: 1.25rem;
          outline: none;
        }

        .back-button {
          border-radius: ${canGoNext ? "40px 0 0 0" : "40px 40px 0 0"};
          background-color: #dddddd;
          color: #858c95;
          height: 100%;
          width: ${canGoNext ? "50%" : "100%"};
          text-align: ${canGoNext ? "right" : "center"};
        }
        .next-button {
          background-color: #fff;
          color: ${theme.colors.primary};
          width: ${canGoBack ? "50%" : "100%"};
          border-radius: ${canGoBack ? "0 40px 0 0" : "40px 40px 0 0"};
          text-align: ${canGoBack ? "left" : "center"};
          &:hover {
            color: ${theme.colors.primaryHover};
          }
        }
        .hide-button {
          display: none;
        }
      `}
    >
      <button
        className={`back-button ${canGoBack ? "" : "hide-button"}`}
        onClick={onLeftClick}
      >
        <span className="left-arrow">
          <i className="left"></i>
        </span>
        {language == 1 && <span>Back</span>}
        {language == 0 && <span>Atrás</span>}
      </button>
      <button
        className={`next-button ${canGoNext ? "" : "hide-button"}`}
        onClick={onRightClick}
      >
        {language == 1 && <span>Next</span>}
        {language == 0 && <span>Siguiente</span>}
        <span className="right-arrow">
          <i className="right"></i>
        </span>
      </button>
    </div>
  );
};

ButtonGroup.propTypes = {
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  canGoBack: PropTypes.bool,
  language: PropTypes.number,
  canGoNext: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  onLeftClick: () => {},
  onRightClick: () => {},
  language: 1,
  canGoBack: true,
  canGoNext: true,
};
