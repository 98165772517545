import { SET_SIDEMENU_ITEMS } from '../actions/SampleAction';

const initialState = {
    sideMenuLoaded: false,
};

export const SampleReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SIDEMENU_ITEMS:
            return {
                ...state,
                sideMenuItems: action.payload,
                sideMenuLoaded: true,
            };
        default:
            return state;
    }
};