import {css, jsx} from '@emotion/core';
import PropTypes from 'prop-types';
import {useTheme} from 'emotion-theming';

/** @jsx jsx */
export const ProgressDots = ({ max, value }) => {
  const theme = useTheme();

  return (<div css={css `
        font-family: ${theme.texts.font.family.default};
        display: flex;
        justify-content: center;
        .complete {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: ${theme.colors.primary};
          margin-right: 8px;
        }
        .incomplete {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: #d8d8d8;
          margin-right: 8px;
        }
      `}>
      {Array(max).fill().map((und, i) =>
        <div key={i} className={value > i ? 'complete' : 'incomplete' }></div>
      )}
  </div>);
};

ProgressDots.propTypes = {
  /** The total # of dots */
  max: PropTypes.number.isRequired,
  /** The filled # of dots */
  value: PropTypes.number.isRequired
};

ProgressDots.defaultProps = {
  value: 0
};
