import axios from '../config/Axios';

export const checkToken = (data) => {
    return new Promise((resolve, reject) => {
        // fake response timeout
        axios.post('/api/clinics/checkToken/', data)
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch(reject);
    });
};

export const startAssessment = (token) => {
    return new Promise((resolve, reject) => {
        // fake response timeout
        axios.post('/api/patient-assessments/add/', {token:token})
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch(reject);
    });
};

export const getLatestAssessments = (page, token) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/clinics/getLatestAssessments/${token}?page=${page}`)
            .then((response) => {
                resolve(response);
            })
            .catch(reject);
    });
};