import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
// import columbiaHeader from './../../../../../assets/cu-header.svg';
import depcareHeader from './../../../../../assets/depcare-header.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Authentication
import { loginRequest, forgotPassword } from '../../services/AuthService';

// Components
import ThemeProvider from 'ComponentLibrary/ThemeProvider';
const AuthPage = ({ theme }) => {

    const [username] = useState('');
    const [status, setStatus] = useState({});
    const [password] = useState('');
    const [forgot, setForgot] = useState(false);
    const [bannerOpen, setBannerOpen] = useState(false);

    const onForgotPasswordSubmit = () => {
        forgotPassword({ "email_address": username }).then(response => {
            console.log(response);
            if (response.data.success) {
                setStatus({success: response.data.successMessage[0]});
            } else {
                setStatus({error: "Incorrect Email Address"});
            };
        });
    };
    return <ThemeProvider theme={theme}>
        <div className="login-page">
            <div className="banner">
                <div className="banner-content">
                <button onClick={() => setBannerOpen(state => !state)}><h3>Authorized Access Only  </h3>{bannerOpen? <ExpandMoreIcon/>: <ChevronRightIcon/> }</button>
                    <p className={bannerOpen? 'active' : ''}><b>Acceptable Use Notice: </b> 
The information in University Systems at Columbia University is private and confidential and may be used only on a need-to-know basis.  All access is logged.  Unauthorized or improper use of a University System or the data in it may result in dismissal and/or civil or criminal penalties.  </p>
                </div>
            </div>
            <div className="login-window">
            <div style={logo}>
                    <img src={depcareHeader} style={{margin: "auto"}} width={200}></img>
                </div>

                {(status.success || status.error) &&
                    <Alert severity={status.success? 'success': 'error' }>{status.success? status.success: status.error}</Alert>
                }
                <div style={inputBoxContainer}>
                    {forgot &&
                        <div>
                            <h3>Forgot Password</h3>
                            <p>Enter your email, and we will send you a one-time link to reset your password</p>
                        </div>
                    }
                   
                </div>

                <div >
                    {forgot ?
                        <div style={{...actionsContainer, flexDirection: "column"}}>
                            <Button variant="contained" color="primary" onClick={onForgotPasswordSubmit}>Send Reset Instructions</Button>
                            <span style={{...spanLink, marginTop: 5}} onClick={() => {setForgot(false); setStatus({});}}>Back to Login</span>
                        </div>
                        :
                        <div style={actionsContainer}>
                            Your account is unrecognized and needs to be activated by an administrator before you can start using the administrative features of DepCare.
                        </div>
                    }
                </div>
            </div>
        </div>
    </ThemeProvider>;

};
const inputBoxContainer = {
    width: '100%',
    marginBottom: 10
};

const logo = {
    width: 300,
    marginTop: -110,
    position: 'absolute',
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'center'
};

const actionsContainer = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
};

const spanLink = {
    cursor: "pointer",
    color: '#182c49',
    textDecoration: "underline"
};

AuthPage.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    theme: PropTypes.string.isRequired,
};

export default AuthPage;