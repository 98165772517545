import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";

/** @jsx jsx */
export const Header = ({ text, align, size }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        font-family: ${theme.texts.font.family.default}, 'Open Sans', sans-serif;  
        font-size: 1.25rem;
        text-align: ${align};
        ${ size === 'large' ? `
          font-size: 35px;
          letter-spacing: 1px;
        ` : ''}
        ${ size === 'mega' ? `
          font-weight: bold;
          font-size: 55px;
        ` : ''}
      `}
      dangerouslySetInnerHTML={{ __html: text.replace(/(<? *script)/gi, 'illegalscript') }}>
    </div>
  );
};

Header.propTypes = {
  /** The title */
  text: PropTypes.string,
  /** Alignment of the header */
  align: PropTypes.oneOf(["left", "right", "center"]),
  /** Size of the header */
  size: PropTypes.oneOf(["normal", "large", "mega"]),
};

Header.defaultProps = {
  align: "left",
  size: "normal"
};
