import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import SecondaryNavigationItem from './SecondaryNavigationItem';

/** @jsx jsx */
const SecondaryNavigation = ({ children, ...others }) => {
  return (
    <div css={css`
          border-bottom: #3FAD9F 1px solid;
          display: flex;
          flex-direction: row;
          padding: 0 0 10px 0;
        `} 
        {...others}>
        { children }
    </div>
  );
};

SecondaryNavigation.Item = SecondaryNavigationItem;

SecondaryNavigation.propTypes = {
  children: PropTypes.any,
};

SecondaryNavigation.Item = SecondaryNavigationItem;

export default SecondaryNavigation;