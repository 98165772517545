import { useEffect, useState } from 'react';
import { fetchLocation,saveLocation } from '../../services/LocationsService';
import { css, jsx } from '@emotion/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

import { Button, TextField, Typography } from '@material-ui/core';

/** @jsx jsx */
const AddLocation = ({match, onSuccess, locationToEdit}) => {
    const location_id = (match != undefined) ? match.params.location_id : ((locationToEdit >= 0) ? locationToEdit : null);
    const [location,setLocation] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [errors, setErrors] = useState({});
   
    useEffect(() => {
        console.log(location_id);
        if (location_id >= 1 && location.length == 0) {
            fetchLocation(location_id).then((response) => {
                console.log(response.data);
                setLocation(response.data);
            });
        }
    });
    function submit(e){

        if(e != undefined){
        e.preventDefault();
        }

        setDisabled(true);
        saveLocation(location).then((data) => {
            if(data.success){
                console.log(data);
                setDisabled(false);
                onSuccess();
                // window.location = `/admin/clinics/view/${data.data.id}/`;
            } else {
                setErrors(data.data);
                setDisabled(false);
                console.log(errors);
            }
        });
    }
    const handleInputChange = e => {
        const {name, value} = e.target;
        setLocation({...location, [name]: value});
    };

    return <div css={css`
        display: flex;
        justify-content: center;
        align-items: center
    `}>
        <form onSubmit={submit} method="" css={css`
            display: grid;
            row-gap: 25px;
            min-width: 360px;
            padding-bottom: 20px;
        `}>
        <Typography variant='h6'>{locationToEdit >= 0 ? "Edit Location" : "Add a Location"}</Typography>
        <TextField
            required
            variant="outlined"
            fullWidth={true}
            id="name"
            label="Name"
            name="name"
            value={location.name ? location.name : ''}
            onChange={handleInputChange}
            error={errors.name ? true : undefined}
            helperText={errors.name ? errors.name._empty : ''}
        />
         <TextField
            required
            variant="outlined"
            fullWidth={true}
            id="admin_email"
            label="Admin Email"
            name="admin_email"
            value={location.admin_email ? location.admin_email : ''}
            onChange={handleInputChange}
            error={errors.admin_email ? true : undefined}
            helperText={errors.admin_email ? errors.admin_email._empty: ''}
        />

        <div css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            button{
                width: 120px;
            }    
        `}>
            <Button disabled={disabled} onClick={submit} size="large" type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </div>    
        </form>
    </div>;
};
AddLocation.propTypes = {
    match: ReactRouterPropTypes.match,
    onSuccess: PropTypes.func,
    locationToEdit: PropTypes.number
};
export default AddLocation;