import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Button } from './Button';
import { useTheme } from 'emotion-theming';
import { useEffect, useState, Fragment } from 'react';
import ReactModal from 'react-modal';
/** @jsx jsx */
export const CardGroup = ({ cardArray, size, onRecordClickedItem }) => {
    const theme = useTheme();
    const [scale, setScale] = useState(150);
    const [open, setOpen] = useState(false);
    const [modalIndex, setModalIndex] = useState(-1);
    
    useEffect( () => {
        switch (size) {
            case 'small':
                setScale(100);
                break;
            case 'large':
                setScale(200);
                break;
            default:
                setScale(150);
        }
    });

    const modalStyles = {
        zIndex: "1000"
    };

    return(
        <div css={css`
        
            @media only screen and (max-width: 600px){
                .ReactModal__Content{
                position: absolute;
                inset: 0px!important;
                width: 300px;
                border: 1px solid rgb(204, 204, 204);
                background: rgb(255, 255, 255);
                overflow: auto;
                border-radius: 4px;
                outline: none;
                margin-left: 50%;
                left: -150px;
                margin-top: 100px;
                padding: 10px;
                }
            }
            @media only screen and (max-width: 600px){
                .card-group-container{
                    flex-direction: column;
                }
                .box{
                    width: 100% !important;
                }
            }
        `}>
            <div className="card-group-container" css={css`
                font-family: ${theme.texts.font.family.default};      
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                * {
                    font-family: ${theme.texts.font.family.default};
                }
                img {
                    // height: ${scale * 1.5}px;
                    // width: ${scale / 5}%;
                    height: 30%;
                    z-index: 0;
                    object-fit: contain;
                }
                .box{
                    // margin: 1px;
                    background-color: white;
                    height: ${scale * 1.5}px;
                    // height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    position: relative;
                    width: ${Math.round(scale / 2 / cardArray.length)}%;
                }
                .box:hover{
                    background-color: rgb(236, 236, 236);
                    cursor: pointer;
                }
                .text{
                    font-family: ${theme.texts.font.family.default};
                    font-size: ${scale / 10}px;
                }
            `}>

                {cardArray.map((card, index) => {
                    return <Fragment key={`card-${index}`}>
                        <div 
                            className="box" 
                            onClick={() => {
                                setModalIndex(index);
                                setOpen(true);
                                onRecordClickedItem(card.coding);
                            }}
                            css={css`
                                margin-right: ${index != cardArray.length - 1 ? '1px' : '0'};
                            `}
                        >
                            <img src={card.imageUrl} alt={card.alt}></img>
                            <div className="text" >
                            <b dangerouslySetInnerHTML={{ __html: (card.overlayText? card.overlayText: '').replace(/(<? *script)/gi, 'illegalscript') }}></b>

                            </div>
                        </div>
                    </Fragment>;
                })}
                <ReactModal
                    isOpen={open}
                    ariaHideApp={false}
                    style={modalStyles}
                >
                    <div css={css`
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size:12px;
                        h1{
                            width: 80%;
                            margin: auto;
                            font-family: ${theme.texts.font.family.default};
                        }
                        button{
                            margin: auto;
                            /* width: 80%; */
                        }
                    `}>
                        <div>
                            <h2 dangerouslySetInnerHTML={{ __html: (cardArray[modalIndex] ? cardArray[modalIndex].detailText: '').replace(/(<? *script)/gi, 'illegalscript') }}></h2>
                            <Button size="lg" type="primary" onClick={() => setOpen(false)}>Close</Button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        </div>
    );
};


CardGroup.propTypes = {
    /** An array of the cards to be rendered */
    cardArray: PropTypes.arrayOf(PropTypes.shape({
        /** The URL of the image being loaded */
        imageUrl: PropTypes.string.isRequired,
        /** Alt text to display if the image is not available. */
        alt: PropTypes.string.isRequired,
        /** Text to be rendered underneath the image. */
        overlayText: PropTypes.string,
        /** Text to be shown in the modal when the card is clicked. */
        detailText: PropTypes.string
    })
    ).isRequired,
    /** The size of the Image Group */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /** callback, must have a parameter for the index of the card clicked. */
    onRecordClickedItem: Function
};

CardGroup.defaultProps = {
    size: 'medium'
};
