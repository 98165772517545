import axios from '../config/Axios';


export const setAssessmentLanguage = (token,language) => {
    return new Promise((resolve, reject) => {
        axios.post('/api/patient-assessments/set-language/'+token,{language})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
            .catch(reject);
    });
};

export const setAssessmentSound = (token, soundState) => {
    console.log("soundState " + soundState);    
    return new Promise((resolve, reject) => {
        axios.post('/api/patient-assessments/set-sound/'+token, {"enabled": soundState})
        .then((response) => {
            console.log("hello from the service");
            const {data} = response;
            resolve(data);
        })
        .catch(reject);
    });
};
