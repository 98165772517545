import React, { useEffect, useState } from 'react';
import { fetchPatient, deletePatient } from '../../services/PatientsService';
import { savePatientAssessment } from '../../services/PatientAssessmentsService';
import moment from 'moment';

import ReactRouterPropTypes from 'react-router-prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SendAssessment from './SendAssessment';
import SendSmsAssessment from './SendSmsAssessment';

import DeleteIcon from '@material-ui/icons/Delete';
import { Dialog, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import './ViewPatient.css';
import { Permission } from '../../../../shared/Security/index';

import SortableTable from './../../components/SortableTable';

const ViewPatient = ({ match }) => {
    const { patient_id } = match.params;
    const [addAssessmentSuccess, setAddAssessmentSuccess] = useState(false);
    const [newAssessment, setNewAssessment] = useState({ assessment_id: 1, patient_id: patient_id });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [sendAssessmentId, setSendAssessmentId] = useState(false);
    const [sendSmsAssessment, setSendSmsAssessmentId] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [patient, setPatient] = useState({
        first_name: '',
        last_name: '',

        provider: {
            name: ''
        },
        clinic: {
            name: ''
        },
        last_assessment_results:{
            phq_score:'',
            suicidal:''
        },
        patient_assessments: []
    });
    const [tableHeaders] = useState([
        {
            name: 'Type',
            key: 'type',
            type: 'string',
            sortable: true,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Assessment last sent on',
            key: 'last_email_sent',
            type: 'string',
            sortable: true,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Assessment due on',
            key: 'assessmentDueDate',
            type: 'string',
            sortable: true,
            callback: false,
            link: false,
            remove: false
        },
        {
            name: 'Link',
            key: 'link',
            type: 'string',
            allowed:['PatientAssessments.SendAssessment'],

            sortable: false,
            callback: false,
            link: true,
            linkText: 'Start In-person Assessment',
            remove: false
        },
        {
            name: 'Email action',
            key: 'send_email',
            allowed:['PatientAssessments.SendAssessment'],

            type: 'string',
            sortable: false,
            callback: sendEmail,
            link: false,
            remove: false
        },
        {
            name: 'SMS action',
            key: 'send_sms',
            type: 'string',
            allowed:['PatientAssessments.SendSms'],

            sortable: false,
            callback: sendSms,
            link: false,
            remove: false
        },
        {
            name: 'Results',
            key: 'results',
            allowed:['PatientAssessments.Results'],

            type: 'string',
            sortable: false,
            callback: false,
            link: true,
            linkText: 'See Results',
            conditionalLink: true,
            altLinkText: 'Assessment not completed',
            remove: false,
            conditional: true
        }
    ]);
    function createNewAssessment() {
        setNewAssessment({...newAssessment, assessment_id: (patient.clinic.type === "depression"? 1 : 2)});
        savePatientAssessment(newAssessment).then((data) => {
            if (data.success) {
                setAddAssessmentSuccess(true);
                getPatientDetail();
                setTimeout(() => setAddAssessmentSuccess(false), 3000);
                // handleDialogClose();
            }
        });
    }
    function getPatientDetail() {
        fetchPatient(patient_id).then((response) => {
            setPatient(response.data);
        });
    };

    function removePatient() {
        deletePatient(patient_id).then((response) => {
            if (response.success) {
                handleDeleteDialogClose();
                window.location.replace("/admin/patients");
            }
        });
    }
    useEffect(() => {
        if (patient.first_name == '') {
            getPatientDetail();
        }
    }, []);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        getPatientDetail();
        setSendAssessmentId(-1); 
        setSendSmsAssessmentId(-1); 

        setDialogOpen(false);
        
    };
    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true);
    };
    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };
    function sendSms(id){
        setSendSmsAssessmentId(id); 
        handleDialogOpen();
    }
    function sendEmail(id){
        setSendAssessmentId(id); 
        handleDialogOpen();
    }
    // const StyledTableCell = withStyles((theme) => ({
    //     head: {
    //         backgroundColor: theme.palette.common.black,
    //         color: theme.palette.common.white,
    //     },
    //     body: {
    //         fontSize: 14,
    //     },
    // }))(TableCell);

    // const StyledTableRow = withStyles((theme) => ({
    //     root: {
    //         '&:nth-of-type(odd)': {
    //             backgroundColor: theme.palette.action.hover,
    //         },
    //     },
    // }))(TableRow);

    function createData(id,ends_on,last_email_sent,type, is_completed, link, language, completedOn, results) {
        return {id:id,assessmentDueDate:ends_on,last_email_sent:last_email_sent, type, is_completed, mailto:'mailto:'+patient.email_address+'?subject=Welcome to Columbia&body=Welcome to Columbia%0D%0A%0D%0A'+link, link:(results != null)?'':link, language, completedOn, results,send_email:(results != null)?'':'Send Via Email',send_sms:(results != null)?'':'Send Via SMS' };
    }

    const rows = patient.patient_assessments.map( assessment => {
        console.log(assessment,'assessmentDueDate');

        return createData(
            assessment.id,
            assessment.ends_on,
            (assessment.last_email_sent == '' || assessment.last_email_sent == undefined)?'N/A':moment(assessment.last_email_sent).format('MM/DD'),
            assessment.assessment_id == 1 ? "iHeart": (assessment.assessment_id == 2? "DepCare": "N/a"),
            assessment.is_completed,
            `${window.location.protocol}//${window.location.hostname}${window.location.port? (':' + window.location.port) : ''}/${(assessment.assessment_id == 1) ? 'iheart' : 'depcare'}/token/${assessment.token}/${(patient.language == 'english')?1:0}/in-person`,
            patient.language,
            assessment.is_completed? assessment.completed_at: "N/a",
            assessment.is_completed? `/admin/patients/results/${assessment.id}`: null
        );
    });
    console.log(rows);

    // console.log(rows);

    const classes = makeStyles({
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        root: {
            marginTop: 30,
        },
        infoContainer: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: 10
        },
        infoSubContainer: {
            display: "grid",
            gridTemplateColumns: "115px 1fr"
        },
        infoheader: {
            color: '#263238'
        },
        infobody: {
            color: '#546e7a'
        },
        table: {
            minWidth: 700,
        },
        tableContainer: {
            margin: "30px 0px"
        },
        dialog: {
            padding: 30,
            "*": {
              marginBottom: 10  
            }
        },
        content: {
            // height: "75vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
    })(); 
    return <div className={classes.container}> 
        <Card className={classes.root}>
            {addAssessmentSuccess && 
                <Alert style={{transition: "0.5s"}}variant="filled" severity="success">
                    Assessment Successfully Added
                </Alert>
            }
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    {sendAssessmentId > 0 &&
                        <SendAssessment patientId={sendAssessmentId} emailAddress={patient.email_address} onSuccess={handleDialogClose} />
                    }
                </DialogContent>
                <DialogContent>
                    {sendSmsAssessment > 0 &&
                        <SendSmsAssessment patientId={sendSmsAssessment} phoneNumber={patient.phone} onSuccess={handleDialogClose} />
                    }
                </DialogContent>
                
            </Dialog>
            <CardContent className={classes.content}>
                <Typography variant="h6" className={classes.infoheader}><b>Patient detail</b></Typography>
                <br />
                <div className={classes.infoContainer}>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>PT MRN #: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{patient.mrn}</Typography>
                    </div>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>Name: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{patient.last_name}, {patient.first_name}</Typography>
                    </div>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>Provider: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{(patient.provider != null)?patient.provider.name:'N/A'}</Typography>
                    </div>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>Clinic: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{patient.clinic.name}</Typography>
                    </div>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>Phone: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{patient.phone}</Typography>
                    </div>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>Email: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{patient.email_address}</Typography>
                    </div>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>PHQ-9 Score: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{(patient.last_assessment_results.phq_score || patient.last_assessment_results.phq_score == 0)?patient.last_assessment_results.phq_score:'N/A'}</Typography>
                    </div>
                    <div className={classes.infoSubContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>Suicidality: </b>
                        </Typography>
                        {patient.suicidal == 0 || patient.suicidal == 1 &&
                        <Typography variant="subtitle1" className={classes.infobody}>{(patient.suicidal == 0)?'No':'Yes'}</Typography>
                        }
                        {patient.suicidal != 0 && patient.suicidal != 1 && 
                        <Typography variant="subtitle1" className={classes.infobody}>N/A</Typography>
                        }
                    </div>
                    <div className={classes.infoContainer}>
                        <Typography variant="subtitle1" className={classes.infoheader}>
                        <b>Assessment Due Date: </b>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.infobody}>{patient.appointmentDate ? moment(patient.appointmentDate).format('MM/DD/YY') : 'N/A'}</Typography>
                    </div>
                </div>
                <Divider />
                <div className="assessments">
                    {/* <Permission allowed="PatientAssessments.Add">

                    <div className={classes.infoContainer}>
                        <Typography variant="h6">Assessments</Typography>
                        <Button style={{ justifySelf: 'end' }} variant="contained" size='sm' color="primary" onClick={createNewAssessment}>Create Assessment</Button>
                    </div>
                    </Permission> */}
                        {/* <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                            <DialogContent className={classes.dialog}>
                                <FormControl style={{ width: 200, marginTop: "10px" }}>
                                    <InputLabel id="demo-mutiple-name-label">Assessment Type</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        // displayEmpty
                                        value={newAssessment.assessment_id}
                                        name="assessment_id"
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value={1}>IHeart</MenuItem>
                                        <MenuItem value={2}>DepCare</MenuItem>
                                    </Select>
                                </FormControl> <br/> <br/>
                                <Button variant="contained" size='sm' color="primary" onClick={createNewAssessment}>Create Assessment</Button> 
                            </DialogContent>
                        </Dialog> */}
                    {/* <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell > Type</StyledTableCell>
                                    <StyledTableCell align="center">Assessment last sent on</StyledTableCell>

                                    <StyledTableCell align="center">Link</StyledTableCell>
                                    <StyledTableCell align="center">Email action</StyledTableCell>

                                    <StyledTableCell align="center">Results</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, i) => (
                                    <StyledTableRow key={`assessment-${i}`}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.type}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.last_email_sent}
                                        </StyledTableCell>
                                        <StyledTableCell align="center"><a target="_blank" rel="noopener noreferrer" href={row.link}>Go to Assessment</a></StyledTableCell>
                                        <StyledTableCell align="center"><a  rel="noopener noreferrer" href="#" onClick={() => {setSendAssessmentId(row.id); handleDialogOpen();}}>Send email</a></StyledTableCell>

                                        <StyledTableCell align="center">
                                            {row.is_completed == true && 
                                            <a href={row.results}>Show results</a>
                                            }
                                            {row.is_completed == false && 
                                                <div>Not completed yet</div>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                    <SortableTable
                    style={{marginTop:0}}
                        className={classes.tableContainer}
                        data={rows}
                        headers={tableHeaders}
                    />
                </div>
                <Permission allowed="Patients.Delete">
                <div className={classes.infoContainer}>
                    <Button style={{ justifySelf: 'start' }} variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleDeleteDialogOpen}>Remove Patient</Button>
                </div>
                
                <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                    <DialogContent className={classes.dialog}>
                        <Typography style={{ marginBottom: 10, textAlign: "center" }} variant="h5">Are you sure?</Typography>
                        <Button fullWidth variant="contained" color="secondary" startIcon={<DeleteIcon/>}onClick={removePatient}>Remove Patient</Button>
                    </DialogContent>
                </Dialog>
                </Permission>
            </CardContent>
            <CardActions>
            </CardActions>
        </Card>
        
    {/* <div>
        <table>
            <thead>
                <tr>
                    <td>
                        <h4>PT MRN #: </h4>

                    </td>
                    <td>
                        <span>{patient.mrn}</span>
                    </td>
                    <td>
                        <h4>
                            Name:
                        </h4>
                    </td>
                    <td>
                        <span>{patient.first_name} {patient.last_name}</span>
                    </td>
                </tr>

                <tr>
                    <td> <h4>Provider: </h4>
                    </td>
                    <td>
                        <span>{patient.provider.name}</span>
                    </td>
                    <td> <h4>Clinic: </h4>
                    </td>
                    <td>
                        <span>{patient.clinic.name}</span>
                    </td>

                </tr>
                <tr>
                    <td> <h4>Phone: </h4>
                    </td>
                    <td>
                        <span>{patient.phone}</span>
                    </td>
                    <td> <h4>Email: </h4>
                    </td>
                    <td>
                        <span>{patient.email}</span>
                    </td>

                </tr>

            </thead>
        </table>
        <div>
            <div>
                <h3>Create Assessment</h3>
            </div>
            <div>
                <select value={newAssessment.assessment_id} name="assessment_id" onChange={handleInputChange}>
                    <option value="1">IHeart</option>
                    <option value="2">DepCare</option>
                </select>
            </div>
            <div>
                <Button onClick={() => createNewAssessment()}>
                    Create
               </Button>
            </div>
            <div>
                <Button onClick={() => removePatient()}>
                    Delete
               </Button>
            </div>
        </div>
        <h1>Assessments</h1>
        <table cellSpacing="2">
            <thead>
                <tr>
                    <th>
                        Assessment type
                </th>
                    <th>
                        Schedule date
                </th>
                    <th>
                        Link
                </th>
                    <th>
                        Completed on
                </th>
                    <th>
                        Results
                </th>
                </tr>
            </thead>
            <tbody>
                {patient.patient_assessments.map((patient_assessment, i) =>

                    <tr key={`user-${i}`}>
                        <td>
                            {patient_assessment.assessment_id == 1 &&
                                <div>IHeart</div>
                            }
                            {patient_assessment.assessment_id == 2 &&
                                <div>DepCare</div>
                            }
                        </td>
                        <td>
                            {patient_assessment.created_at}
                        </td>
                        <td>
                            {`https://${window.location.hostname}/${(patient_assessment.assessment_id == 1)?'iheart':'depcare'}/token/${patient_assessment.token}`}
                        </td>
                        <td>
                            {patient_assessment.is_completed &&
                                <div>
                                    {patient_assessment.completed_at}
                                </div>
                            }
                            {!patient_assessment.is_completed &&
                                <div>
                                    N/A
                            </div>
                            }
                        </td>
                        <td>
                            {patient_assessment.is_completed &&
                                <a href={`/admin/patients/results/${patient_assessment.id}`}>View Report</a>
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div> */}


    </div>;
};

ViewPatient.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
};
export default ViewPatient;