 import axios from '../../../../config/Axios';


export const getLocationsAndProviders = () => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get('/api/locations/getLocationsAndProviders/')
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const getLocationsWithPatients = () => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get('/api/locations/getLocationsWithPatients/')
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const getList = () => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get('/api/locations/getList/')
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};


export const fetchLocations = (page, search = '', order="asc", orderBy="name") => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get('/api/locations?page='+page+'&search='+search+'&sort_direction='+order+'&sort_by='+orderBy)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const saveLocationProvider = (data) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/locations/add/',{location:data})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const saveLocation = (data) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/locations/add/',{location:data})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const fetchLocation = (id) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get(`/api/locations/view/${id}`)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
