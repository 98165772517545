import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";
import { useEffect, useState } from "react";

/** @jsx jsx */
export const ColumnQuestion = ({ answers, children }) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        font-family: ${theme.texts.font.family.default};
        .label {
          background-color: #ddd;
          overflow: hidden;
          border-radius: 8px 8px 0 0;
          margin-left: auto;
          width: 202px;
          display: flex;
        }
        .answers {
          width: 50%;
          text-align: center;
          font-size: 1.25rem;
          font-weight: 700;
          letter-spacing: 4px;
          padding: 5px 0 5px;
          color: #464d56;
          &:not(:last-of-type) {
            border-right: 2px solid #f2f2f2;
          }
        }
      `}
    >
      <div className="label">
        {answers.map((ans) => (
          <div
            className="answers"
            key={ans.id}
            dangerouslySetInnerHTML={{
              __html: ans.answer.replace(/(<? *script)/gi, "illegalscript"),
            }}
          ></div>
        ))}
      </div>
      {children}
    </div>
  );
};

ColumnQuestion.propTypes = {
  /** Answers for the question */
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      /** Unique id of the answer, not shown to user */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Answer value */
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** ColumnQuestion.Question elements */
  children: PropTypes.arrayOf(PropTypes.element),
};

ColumnQuestion.defaultProps = {};

const Question = ({ question, answers, selected, errors, onChange }) => {
  const theme = useTheme();
  const [errorArray, setErrorArray] = useState(errors ? errors : []);
  useEffect(() => {
    if (errors) {
      setErrorArray(errors);
    }
  });

  return (
    <div>
      <div
        css={css`
          font-family: ${theme.texts.font.family.default};
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          padding: 15px;
          position: relative;
          margin-bottom: 2px;
          /* ${errorArray.length > 0 ? "border: 1px solid red;" : ""} */
          .question {
            font-size: 1.25rem;
            color: #464d56;
            text-align: left;
            width: 70%;
            overflow-wrap: break-word;
            margin: 3px;
            margin-right: 200px;
            ${errorArray.length > 0 ? "color: #D75938;" : ""}
          }
          .options {
            ${"" /* position: relative; */}
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            height: 100%;
          }
          .option {
            border-left: 2px solid #f2f2f2;
            padding-right: 50px;
            padding-left: 50px;
            position: relative;
          }
          /* Hide the browser's default radio button */
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          .radio {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 25px;
            width: 25px;
            background-color: #fff;
            border: 2px solid #999;
            border-radius: 50%;
          }
          /* When the radio button is checked, add a blue background */
          .options input:checked ~ .radio {
            background-color: #fff;
            border: 2px solid ${theme.colors.primary};
          }

          /* Create the indicator (the dot/circle - hidden when not checked) */
          .radio:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the indicator (dot/circle) when checked */
          .options input:checked ~ .radio:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          .options .radio:after {
            top: 52%;
            left: 52%;
            transform: translate(-52%, -52%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: ${theme.colors.primary};
          }
        `}
      >
        <div
          className="question"
          dangerouslySetInnerHTML={{
            __html: question.replace(/(<? *script)/gi, "illegalscript"),
          }}
        ></div>
        <div className="options">
          {answers.map((ans) => (
            <div key={ans.id} className="option">
              <label>
                <input
                  type="radio"
                  value={ans.answer}
                  checked={selected === ans.id}
                  onChange={(event) => onChange(ans, event)}
                  className="form-check-input"
                />
                <span className="radio"></span>
              </label>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="errors" css={css`
          div {
            color: red;
            margin-bottom: 5px;
          }
        `}>
        {errorArray.map((err, i) => {
          return <div key={`err-${i}`}>{err}</div>;
        })}
      </div> */}
    </div>
  );
};

Question.propTypes = {
  /** Question to use */
  question: PropTypes.string.isRequired,
  /** Answers for the question */
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      /** Unique id of the answer, not shown to user */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Answer value */
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Currently selected answer, value should be the answer id selected or null */
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** array of errors to be passed to this question */
  errors: PropTypes.array,
  /** Event when an answer is clicked, arguments are: answer object, event. Should change selected prop */
  onChange: PropTypes.func.isRequired,
};

Question.defaultProps = {
  errors: [],
};

ColumnQuestion.Question = Question;
