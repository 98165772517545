import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

/** @jsx jsx */
export const Input = ({ errors, size, ...others }) => {
  const [errorArray, setErrorArray] = useState(errors? [...errors]: []);
  useEffect( () => {
    if(errors){
      setErrorArray(errors);
    }
  });
  const fontSize = ((param) => ({
    "sm": "1rem",
    "lg": "2.5rem",
    "md": "2rem"
  }[param] || 'md'))(size);

  return (
    <div>
    <input css={css`
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: .375rem 0;
        font-size: ${fontSize};
        font-weight: 300;
        line-height: 2;
        color: #495057;
        background-color: #fff;
        border: none;
        border-bottom: ${errorArray.length == 0? 'none': '1px solid #D75938'};
        outline: none;
        &::placeholder {
          color: #ccc;
        }
        
        `} {...others}
        />
        <div className="errors" css={css`
          div {
            color: #D75938;
          }
        `}>
          {errorArray.filter(e => e !== 'Not empty' && e !== 'No answer given').map((err, i) => {
            return <div key={`err-${i}`}>{err}</div>;
          })}
        </div>
  </div>
  );
};

Input.propTypes = {
  /** Input type */
  type: PropTypes.oneOf(["text", "password", "email", "number"]).isRequired,
  /** Placeholder text to show when input is empty */
  placeholder: PropTypes.string,
  /** Set to true to make the input immutable */
  readOnly: PropTypes.bool,
  /** Value of the input, should change with onChange event */
  value: PropTypes.string,
  /** Fired when input changes, argument is the event */
  onChange: PropTypes.func,
  /** Array of errors associated with the input */
  errors: PropTypes.array,
  /** Size of the input */
  size: PropTypes.oneOf(["sm", 'md', "lg"])
};

Input.defaultProps = {
  type: "text",
  size: "md"
};
