import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import { AppReducer } from '../reducers/AppReducer';
import { AuthReducer } from '../reducers/AuthReducer';
import { SampleReducer } from '../reducers/SampleReducer';

// import { PluginReducers as AdminReducers } from 'Plugins/admin/state/stores/PluginStore';

const reducers = combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    sample: SampleReducer,

    // plugins
    // admin: AdminReducers
});

export const createAppStore = () => {
    return createStore(reducers, applyMiddleware(thunk, promiseMiddleware()));
};