import React, { Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../state/actions/AuthActions';

export const TokenPage = ({ match, history }) => {

    // This is how you get the redux dispatcher through a hook. You call any of the dispatcher functions
    // on here that are defined in ./components/state/actions, and it will go through the redux flow.
    const dispatch = useDispatch();
    
    // Get the provider and token from Match and put them in a const with the same name.
    const { provider, token,language,method } = match.params;

    // Dispatch an object through setUserData
    dispatch(setUserData({ theme: provider, token,language:parseInt(language),soundOn:false,method:method }));

    history.push('/');

    return <Fragment />;
};

TokenPage.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};