// @TODO: Need to figure out exiting the SideMenu
import React, { useEffect,useState } from 'react';


import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserData, logoutUser } from '../../../state/actions/AuthActions';
// import { LayoutContainer } from 'ComponentLibrary';
import { css } from '@emotion/core';

import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { green, orange } from '@material-ui/core/colors';

// import ThemeProvider from 'ComponentLibrary/ThemeProvider';
import AppBar from '@material-ui/core/AppBar';

// import Link from '@material-ui/core/Link';
import { Link } from 'react-router-dom';


import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { Permission } from '../../../shared/Security/index';

// import columbiaHeader from './../../../../assets/cu-header.svg';
import depcareHeader from './../../../../assets/depcare-header.png';

export const AdminLayout = ({ component: Komponent, theme, ...rest }) => {

    const adminTheme = createMuiTheme({
        palette: {
            primary: {
                light: '#41b3e1',
                main: '#12a1da',
                dark: '#0c7098',
                contrastText: '#fff',
            },
            secondary: {
                main: orange[500],
                contrastText: '#fff',
            },
        },
      });
      
    const classes = makeStyles({
        navLink: {
            textDecoration: 'none'
        }
    })();

    const { retrieved, loggedIn, userData } = useSelector(s => s.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLocalEnvironment,setIsLocalEnvironment] = useState((window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('crizit.io') !== -1)?true:false);

    const sanityCheck = () => {
        if(retrieved) {
            if(userData && userData.role_id == 5) {
                history.push('/admin/unauthorized/');
            }
            if(!loggedIn) {
                history.push('/admin/');
            }
        }
        else {
            dispatch(fetchUserData());
        }
    };

    useEffect(() => {
        console.log('test');
        sanityCheck();
    }, []);

    useEffect(() => {
        sanityCheck();
    }, [ retrieved, loggedIn ]);


    return loggedIn && <Route {...rest} render={matchProps => {
        

        return <ThemeProvider theme={adminTheme}>

            <AppBar position="static">
                {isLocalEnvironment && 
                    <div style={{backgroundColor:'#ff0000',color:'#fff',textAlign:'center',fontWeight:'bold'}}>This is a STAGING environment.</div>
                }
                <Toolbar style={style.header} css={css`
                       

                    `}>
                    <img src={`${depcareHeader}`} style={{ width: 125, marginTop: '0px', content: `url(${depcareHeader})` }}></img>
                    <div style={style.menu}  >
                        <Permission allowed="Clinics.Index">
                        <Link className={classes.navLink} to="/admin/clinics/" >
                            <ListItem button key={'Clinics'} selected={window.location.pathname === '/admin/clinics/' ? true : false}>
                                <ListItemText style={style.listItemText} primary={'Clinics'} />
                            </ListItem>
                        </Link>
                        </Permission>
                        <Permission allowed="Patients.Index">

                        <Link className={classes.navLink} to="/admin/patients/" style={style.listItemText}>
                            <ListItem button key={'Patients'} selected={window.location.pathname === '/admin/patients/' ? true : false}>

                                <ListItemText style={style.listItemText} primary={'Patients'} />
                            </ListItem>
                        </Link>
                        </Permission>
                        <Permission allowed="Data.DownloadCsv">

                        <Link className={classes.navLink} to="/admin/downloads/" style={style.listItemText}>
                            <ListItem button key={'Download Data'} selected={window.location.pathname === '/admin/downloads/' ? true : false}>


                                <ListItemText primary={'Download Data'} />
                            </ListItem>
                        </Link>
                        </Permission>
                        <Permission allowed="Admins.Index">


                        <Link className={classes.navLink} to="/admin/admins/" style={style.listItemText}>

                            <ListItem button key={'Admins'} selected={window.location.pathname === '/admin/admins/' ? true : false}>

                                <ListItemText primary={'Admins'} />
                            </ListItem>
                        </Link>
                        </Permission>

                    </div>
                    <Link className={classes.navLink} to="/admin/" style={style.lastListItemText}>

                        <ListItem button key={'Logout'} >

                            <ListItemText primary={'Logout'} />
                        </ListItem>
                    </Link>


                </Toolbar>
            </AppBar>
            <div  style={style.layout}>
                {/* <Drawer anchor={'left'} open={drawer} onClose={() => { toggleDrawer(); }}>
                    <div style={style.drawer}>

                        <h3>Admin</h3>
                        <List style={style.submenuList}>

                         
                          
                            <Divider></Divider>
                           
                           


                        </List>
                        <List style={style.lastListItemText}>
                           
                        </List>
                    </div>
                </Drawer> */}
                <Komponent {...matchProps} />
                </div>
        </ThemeProvider>;
    }} />;
};

AdminLayout.propTypes = {
    theme: PropTypes.string.isRequired,
    component: PropTypes.any,
};

AdminLayout.defaultProps = {

};
const style = {
    layout:{
        padding:20,
    },
    drawer: {
        height: '100%',
        textAlign: 'center'
    },
    menu: {
        flexDirection: 'row',
        marginLeft:50,
        display: 'flex',
        justifyContent: 'space-between'
    },
    listItemText: {
        color: '#fff',
        size: 8,
    },
    lastListItemText: {
        textAlign: 'center',
        color: '#fff',

        position: 'absolute',
        right: 20
    },
    header: {
        background: 'linear-gradient(90deg, rgb(18, 162, 218) 0px, rgb(19, 212, 199))',
    },
    logo: {
        marginTop: 10,
        width: 80,
    },
    submenuList: {
        width: 275
    }
};

