import { useEffect, useState } from 'react';
import { sendSmsAssessment } from '../../services/PatientAssessmentsService';
import { css, jsx } from '@emotion/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

// import { Button } from 'ComponentLibrary/index';
// import { Input } from 'ComponentLibrary/index';
// import { Select } from '../../../../shared/components/Elements/Select';

import { Button, TextField, Typography } from '@material-ui/core';


/** @jsx jsx */
const SendReportEmailForm = (
    // {match}
    props
    ) => {
    const patientId = props.patientId;
    // const patient_id = null;
    
    const [loading,setLoading] = useState(false);
    const [errors, setErrors] = useState({
        // mrn: {},
        // first_name: {},
        // last_name: {},
        // email: {},
        // phone:{},
        // current_provider_id: {},
        // current_clinic_id: {}
    });
    const [report,setReport] = useState({
        
        phone_number:props.phoneNumber,
       
    });
    useEffect(() => {
       
    });
    function submit(e){
        if(e != undefined){
        e.preventDefault();
        }
        // console.log('sdfsd');
        setLoading(true);
        // console.log(patient);
        sendSmsAssessment(patientId,report.phone_number).then((data) => {
            setLoading(false);
            if(data.success){
                props.onSuccess();
            } else {
                setErrors(data.data);
            }
        });
    }
    const handleInputChange = e => {
        const {name, value} = e.target;
    
        setReport({...report, [name]: value});
    };

    const errorsToElements = (errors, i) => {
        return <div>
            {Object.values(errors).map(err => <p key={`err-${i}`}>{err}</p>)}
        </div>;
    };
    return <div css={css`
        display: flex;
        justify-content: center;
        align-items: center
    `}>
        <form method="" onSubmit={submit} css={css`
            *{
                margin: 5px 0px;
            }
        `}>
        <Typography variant='h5'>SMS Assessment</Typography>
    
        <TextField 
            fullWidth={true} 
            type="text" 
            id="sms" 
            name="phone_number" 
            label="Phone Number" 
            value={report.phone_number? report.phone_number: ''} 
            onChange={handleInputChange} 
            error={errors.phone_number ? true : undefined}
            helperText={errors.phone_number ? errorsToElements(errors.phone_number) : ''} 
        />
       
        <div css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            button{
                width: 70%;
            }
        `}>
            <Button type="submit" variant="contained" disabled={loading == true} color="primary">
                Submit
            </Button>        
        </div>    
        </form>
    </div>;
};
SendReportEmailForm.propTypes = {
    match: ReactRouterPropTypes.match,
    onSuccess: PropTypes.func,
    phoneNumber:PropTypes.any,
    patientId: PropTypes.number
};
export default SendReportEmailForm;