import {css, jsx} from '@emotion/core';
import PropTypes from 'prop-types';
import {useTheme} from 'emotion-theming';
import {useState, useRef, useEffect} from 'react';
import triangle from '../../assets/img/Triangle.png';

/** @jsx jsx */
export const ResultBar = ({ score, labels, min, max }) => {
  const theme = useTheme();
  const [width, setWidth] = useState(0);
  const myRef = useRef();

  useEffect(() => {
    console.log({score, labels, min, max});
    setWidth(myRef.current.offsetWidth);
    // if(width < 600){
    //   labels.
    // };
  }, [myRef]);

  window.addEventListener("resize", () => {
    setWidth(myRef.current.offsetWidth);
  });

  let createLines = () => {
    let lines = [];
    for (var i = min; i <= max + 2; i++) {
      lines.push(<span className="line" key={i}></span>);
    }
    return lines;
  };

  return (<div css={css `
        * {
          font-family: 'PT Sans' !important;
        }
        .bar-img {
          width: 100%;
          height: 100%;
          margin-bottom: 30px;
        }
        .score-labels {
          display: flex;
          justify-content: space-between;
        }
        .label {
          padding: 0 5%;
          text-align: center;
          font-size: 16px;
          line-height: 40px; */
        }
        .score-label {
          padding: 5px 20px;
        }
        .active {
          background-color: #e76f4b;
          border-radius: 30px;
          color: #fff;
        }
        .result-number {
          border-radius: 20px;
          background-color: #fff;
          width: 100px;
          height: 100px;
          margin-bottom: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 2px 2px 0 rgba(0,0,0,.1);
          position: relative;
          left: ${-45 + ((score - min + 1) * ((width - 2) / (max - min + 2)))}px;
          &::after {
            content: "";
            width: 20px;
            height: 10px;
            background-image: url(${triangle});
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            bottom: -10px;
          }
        }
        .number {
          color: #e76f4b;
          font-size: 2em;
          line-height: 86px;
        }
        .bar {
          position: relative;
        }
        .marker {
          height: 80px;
          width: 5px;
          border-radius: 3px;
          background-color: #e76f4b;
          position: absolute;
          top: -27px;
          display: flex;
          justify-content: center;
          left: ${(score - min + 1) * ((width - 2) / (max - min + 2))}px;
          &::after {
            position: absolute;
            content: "";
            background-color: #e76f4b;
            width: 21px;
            height: 21px;
            border-radius: 50%;
            top: 40%;
          }
        }
        .result-bar {
          background:
            linear-gradient(
              90deg,
              rgba(255,255,255,1) -50%,
              #13D3C6 100%);
          width: 100%;
          height: 30px;
          border-radius: 50px;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
        }
        .line {
          background-color: #fff;
          width: 1px;
          height: 100%;
          &:first-of-type, &:last-of-type {
            visibility: hidden;
          }
        }
        @media (max-width: 992px) {
          .label{
            font-size: 16px;
          }
        } 

      `}>
    <div className="result-number-wrapper">
      <div className="result-number">
        <span className="number">{score}</span>
      </div>
    </div>
    <div className="bar" ref={myRef}>
      <div className="result-bar">
        {createLines()}
      </div>
      <div className="marker"></div>
    </div>
    <div className="score-labels">
      {labels.map((label,i) => {
        if(width <= 600){
          if((i === 0) || (i === 2) || (i === 4)){
            return <div key={i} className={`score-label ${score >= label.min && score <= label.max ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: label.label }}></div>;
          }
        } else {
          return <div key={i} className={`score-label ${score >= label.min && score <= label.max ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: label.label }}></div>;
        };
      })}
    </div>

  </div>);
};

ResultBar.propTypes = {
  /** The current score */
  score: PropTypes.number.isRequired,
  /** The minimum score */
  min: PropTypes.number.isRequired,
  /** The maximum score */
  max: PropTypes.number.isRequired,
  /** The labels of the bar */
  labels: PropTypes.arrayOf(PropTypes.shape({
    /** Label text */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    /** Value where this label starts */
    min: PropTypes.number.isRequired,
    /** Value where this label ends */
    max: PropTypes.number.isRequired,
  })).isRequired,
};

ResultBar.defaultProps = {
};

export default ResultBar;