import { useEffect, useState } from 'react';
import { fetchPatient, savePatient, dropPatient } from '../../services/PatientsService';
import { getLocationsAndProviders } from '../../services/LocationsService';
import { css, jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import { Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@material-ui/core';


/** @jsx jsx */
const AddPatient = ({ match, onSuccess, patientToEdit }) => {
    const patient_id = (match != undefined) ? match.params.patient_id : ((patientToEdit >= 0) ? patientToEdit : null);
    const [locations, setLocations] = useState([]);
    const [errors, setErrors] = useState({});
    const [originalScreening, setOriginalScreening] = useState();
    
    const history = useHistory();
    const [confirmDropout, setConfirmDropout] = useState(false);

    const languages = [{ id: 'english', name: 'English' }, { id: 'spanish', name: 'Spanish' }];
    const tools = [{ id: '1', name: 'IHeart' }, { id: '2', name: 'Depcare' }];
    const assessedByMD = [{ id: false, name: 'No' }, { id: true, name: 'Yes' }];
    const screenedByCoordinator = [{ id: false, name: 'No' }, { id: true, name: 'Yes' }];


    const preferredRecruitment = [{ id: 'in_person', name: 'In-person' }, { id: 'remote_without_assistance', name: 'Remote Without Assistance' }, { id: 'remote_with_assistance', name: 'Remote With Assistance' }];

    const [patient, setPatient] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        language: 'english',
        tool: '1',

        email_address: '',
        mrn: '',
        date_of_birth: '',
        current_clinic_i: 0,
        current_provider_id: -1,
        current_clinic_id: -1,
        gad_2: '',
        phq_9_score: '',
        suicidality: false,
        preferredRecruitment: 'remote_without_assistance',
        assessedByMd: false,
        screenedByCoordinator: true,
        appointmentDate: new Date()
    });
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (locations.length == 0) {
            getLocationsAndProviders().then((locations) => {
                console.log(locations);

                if (locations.data.length > 0 && patient.current_clinic_id == -1) {
                    console.log('set patient');
                    //let update = {current_clinic_id:locations.data[0].id,current_provider_id:-1};
                    patient.current_clinic_id = locations.data[0].id;
                    if (locations.data[0].providers.length >= 0) {
                        patient.current_provider_id = locations.data[0].providers[0].id;

                    }
                    setPatient(patient);


                }
                setLocations(locations.data);
            }).catch(err => {
                console.log(err);
            });
        }
        if (patient_id >= 1 && patient.id == undefined) {
            fetchPatient(patient_id).then((response) => {
                console.log(response.data);
                let pat = response.data;
                console.log(pat,'pat-data');
                pat.screenedByCoordinator = (pat.screenedByCoordinator || pat.screenedByCoordinator == 'true') ? true : false;
                pat.assessedByMd = (pat.assessedByMd == 'true' || pat.assessedByMd ) ? true : false;
                pat.suicidality = (pat.suicidality || pat.suicidality == 'true') ? true : false;

                console.log(pat,'pat');
              //  return false;


                setPatient(pat);
                setOriginalScreening(pat.screenedByCoordinator);
            });
        }
    });
    function submit(e) {
        if (e != undefined) {
            e.preventDefault();
        }
        setDisabled(true);
        savePatient(patient).then((data) => {
            if (data.success) {
                setDisabled(false);
                if (!(patientToEdit >= 0)) {
                    history.push(`/admin/patients/view/${data.data.id}`);
                }
                onSuccess();
            } else {
                setErrors(data.data);
                setDisabled(false);
                console.log(errors);
            }
        });
    }
    function dropOut() {
        dropPatient(patient.id).then((data) => {
            if (data.success) {
                setDisabled(false);

                onSuccess();
            } else {
                setErrors(data.data);
                setDisabled(false);
                console.log(errors);
            }
        });
    }
    const handleInputChange = e => {
        const { name, value } = e.target;
        
        setPatient({ ...patient, [name]: value });
        if(name == 'tool'){
            if(value == 1){
                //
                        setPatient({ ...patient, screenedByCoordinator: true });

            }
          
        }
    };

    const handleDateChange = date => {
        setPatient({ ...patient, ['appointmentDate']: date });
    };

    const errorsToElements = (errors, i) => {
        return <div>
            {Object.values(errors).map(err => <p key={`err-${i}`}>{err}</p>)}
        </div>;
    };
    return <div css={css`
        display: flex;
        justify-content: center;
        align-items: center
       
    `}>
        <form method="" onSubmit={submit} css={css`
            display: grid;
            row-gap: 10px;
            min-width: 400px;
           
            .MuiOutlinedInput-root{
                margin:0px!important;
            }
        `}>
            <Typography variant='h5'>{patientToEdit >= 0 ? "Edit Patient" : "Add a Patient"}</Typography>
            <TextField
                variant="outlined"
                // required
                fullWidth={true}
                id="mrn"
                label="MRN"
                name="mrn"
                value={patient.mrn ? patient.mrn : ''}
                onChange={handleInputChange}
                error={errors.mrn ? true : undefined}
                helperText={errors.mrn ? errorsToElements(errors.mrn) : ''}
            />
            <TextField
                variant="outlined"
                // required
                fullWidth={true}
                id="first-name"
                name="first_name"
                label="First Name"
                value={patient.first_name ? patient.first_name : ''}
                onChange={handleInputChange}
                error={errors.first_name ? true : undefined}
                helperText={errors.first_name ? errorsToElements(errors.first_name) : ''}
            />
            <TextField fullWidth={true}
                variant="outlined"
                id="last-name"
                name="last_name"
                label="Last Name"
                value={patient.last_name ? patient.last_name : ''}
                onChange={handleInputChange}
                error={errors.last_name ? true : undefined}
                helperText={errors.last_name ? errorsToElements(errors.last_name) : ''}
            />

<FormControl
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: 10 }}
                error={errors.tool ? true : undefined}
                helperText={errors.tool ? errorsToElements(errors.tool) : ''}
            >
                <InputLabel id="tool-label" style={{ margin: -10 }}>Tool</InputLabel>
                <Select
                    // required
                    labelId="tool-label"
                    disabled={originalScreening != null}

                    id="tool"
                    value={patient.tool}
                    onChange={handleInputChange}
                    name="tool"
                >
                    {tools.map((tool, i) =>
                        <MenuItem key={`language-${i}`} value={tool.id}>
                            {tool.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: 10 }}
                error={errors.language ? true : undefined}
                helperText={errors.language ? errorsToElements(errors.language) : ''}
            >
                <InputLabel id="language-label" style={{ margin: -10 }}>Language</InputLabel>
                <Select
                    // required
                    labelId="language-label"
                    id="language"
                    value={patient.language}
                    onChange={handleInputChange}
                    name="language"
                >
                    {languages.map((language, i) =>
                        <MenuItem key={`language-${i}`} value={language.id}>
                            {language.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: 10 }}
                error={errors.current_clinic_id ? true : undefined}
                helperText={errors.current_clinic_id ? errorsToElements(errors.current_clinic_id) : ''}
            >
                <InputLabel id="clinic-select-label" style={{ margin: -10 }}>Clinic</InputLabel>
                <Select
                    // required
                    labelId="clinic-select-label"
                    id="clinic-select"
                    value={patient.current_clinic_id}
                    onChange={handleInputChange}
                    name="current_clinic_id"
                >
                    {locations.map((location, i) =>
                        <MenuItem key={`location-${i}`} value={location.id}>
                            {location.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: 10 }}
                label="Provider"
                error={errors.current_provider_id ? true : undefined}
                helperText={errors.current_provider_id ? errorsToElements(errors.current_provider_id) : ''}
            >
                <InputLabel id="provider-select-label" style={{ margin: -10 }}>Provider</InputLabel>
                <Select
                    // required
                    labelId="provider-select-label"
                    id="provider-select"
                    name="current_provider_id"
                    disabled={patient.current_clinic_id == -1}
                    onChange={handleInputChange}
                    value={patient.current_provider_id}
                >
                    {locations.find(l => l.id === patient.current_clinic_id) != undefined && locations.find(l => l.id === patient.current_clinic_id).providers.map((provider, i) =>
                        <MenuItem key={`provider-${i}`} value={provider.id}>
                            {provider.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <TextField
                variant="outlined"
                fullWidth={true}
                type="email"
                id="email"
                name="email_address"
                label="Email Address"
                value={patient.email_address ? patient.email_address : ''}
                onChange={handleInputChange}
                error={errors.email_address ? true : undefined}
                helperText={errors.email_address ? errorsToElements(errors.email_address) : ''}
            />
            <TextField
                variant="outlined"
                // required
                fullWidth={true}
                id="phone"
                name="phone"
                label="Phone Number"
                value={patient.phone ? patient.phone : ''}
                onChange={handleInputChange}
                error={errors.phone ? true : undefined}
                helperText={errors.phone ? errorsToElements(errors.phone) : ''}
            />
            <FormControl
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: 10 }}
                label="Provider"
                error={errors.preferredRecruitment ? true : undefined}
                helperText={errors.preferredRecruitment ? errorsToElements(errors.preferredRecruitment) : ''}
            >
                <InputLabel id="preferredRecruitment-select-label" style={{ margin: -10 }}>Preferred Recruitment</InputLabel>
                <Select
                    // required
                    labelId="preferredRecruitment-select-label"
                    id="preferredRecruitment-select"
                    name="preferredRecruitment"
                    disabled={patient.preferredRecruitment == -1}
                    onChange={handleInputChange}
                    value={patient.preferredRecruitment}
                >
                    {preferredRecruitment.map((assessedBy, i) =>
                        <MenuItem key={`language-${i}`} value={assessedBy.id}>
                            {assessedBy.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: -15 }}
                error={errors.suicidality ? true : undefined}
                helperText={errors.suicidality ? errorsToElements(errors.suicidality) : ''}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: -10 }}>
                    <KeyboardDatePicker
                        autoOk={true}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        error={(errors.appointmentDate) ? true : false}
                        id="date-picker-inline"
                        label="Assessment Due Date*"
                        value={patient.appointmentDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </FormControl>



            <FormControl
                variant="outlined"
                fullWidth={true}
                style={{ marginTop: 10 }}
                label="Provider"
                error={errors.screenedByCoordinator ? true : undefined}
                helperText={errors.screenedByCoordinator ? errorsToElements(errors.screenedByCoordinator) : ''}
            >
                <InputLabel id="screenedByCoordinator-select-label" style={{ margin: -10 }}>Screened by a Coordinator</InputLabel>
                <Select

                    // required
                    labelId="screenedByCoordinator-select-label"
                    id="screenedByCoordinator-select"
                    name="screenedByCoordinator"
                    disabled={originalScreening != null}
                    onChange={handleInputChange}
                    value={patient.screenedByCoordinator}
                >
                    {screenedByCoordinator.map((assessedBy, i) =>
                        <MenuItem key={`language-${i}`} value={assessedBy.id}>
                            {assessedBy.name}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>


            {patient.screenedByCoordinator == 1 &&
                <div>
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        style={{ marginTop: 0 }}
                        label="Provider"
                        error={errors.phq_9_score ? true : undefined}
                        helperText={errors.phq_9_score ? errorsToElements(errors.phq_9_score) : ''}
                    >
                        <TextField
                            variant="outlined"
                            // required
                            fullWidth={true}
                            id="phq_9_score"
                            name="phq_9_score"
                            label="PHQ-9 Score (0-27)"
                            value={patient.phq_9_score ? patient.phq_9_score : ''}
                            onChange={handleInputChange}
                            error={errors.phq_9_score ? true : undefined}
                            helperText={errors.phq_9_score ? errorsToElements(errors.phq_9_score) : ''}
                        />
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        style={{ marginTop: 10 }}
                        label="Provider"
                        error={errors.gad_2 ? true : undefined}
                        helperText={errors.gad_2 ? errorsToElements(errors.gad_2) : ''}
                    >
                        <TextField
                            variant="outlined"
                            // required
                            fullWidth={true}
                            id="gad_2"
                            name="gad_2"
                            label="GAD-2 Score (0-6)"
                            value={patient.gad_2}
                            onChange={handleInputChange}
                            error={errors.gad_2 ? true : undefined}
                            helperText={errors.gad_2 ? errorsToElements(errors.gad_2) : ''}
                        />

                    </FormControl>
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        style={{ marginTop: 10 }}
                        label="Provider"
                        error={errors.gad_2 ? true : undefined}
                        helperText={errors.gad_2 ? errorsToElements(errors.gad_2) : ''}
                    >
                        <TextField
                            variant="outlined"
                            // required
                            fullWidth={true}
                            id="gad_7"
                            name="gad_7"
                            label="GAD-7 Score (0-21)"
                            value={patient.gad_7}
                            onChange={handleInputChange}
                            error={errors.gad_7 ? true : undefined}
                            helperText={errors.gad_7 ? errorsToElements(errors.gad_7) : ''}
                        />

                    </FormControl>
                    <FormControl
                        variant="outlined"
                        fullWidth={true}
                        style={{ marginTop: 20 }}
                        error={errors.suicidality ? true : undefined}
                        helperText={errors.suicidality ? errorsToElements(errors.suicidality) : ''}
                    >
                        <InputLabel id="suicidality-label" style={{ margin: -10 }}>Suicidality</InputLabel>
                        <Select
                            // required
                            labelId="suicidality-label"
                            id="suicidality"
                            value={patient.suicidality}
                            onChange={handleInputChange}
                            name="suicidality"
                        >

                            <MenuItem key={`suicidality-yes`} value={true}>Yes</MenuItem>
                            <MenuItem key={`suicidality-no`} value={false}>No</MenuItem>
                        </Select>
                    </FormControl>

                    {patient.suicidality == true &&
                        <FormControl
                            variant="outlined"
                            fullWidth={true}
                            style={{ marginTop: 20 }}
                            label="Provider"
                            error={errors.assessedByMd ? true : undefined}
                            helperText={errors.assessedByMd ? errorsToElements(errors.assessedByMd) : ''}
                        >
                            <InputLabel id="assessedByMd-select-label" style={{ margin: -10 }}>Assessed By MD</InputLabel>
                            <Select
                                // required
                                labelId="assessedByMd-select-label"
                                id="assessedByMd-select"
                                name="assessedByMd"
                                disabled={patient.assessedByMd == -1}
                                onChange={handleInputChange}
                                value={patient.assessedByMd}
                            >
                                {assessedByMD.map((assessedBy, i) =>
                                    <MenuItem key={`language-${i}`} value={assessedBy.id}>
                                        {assessedBy.name}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    }



                </div>
            }
            {confirmDropout == false &&


                <div css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
        `}>
                    {patient.id > 0 &&
                        <Button disabled={disabled} type="button" variant="contained" color="secondary" onClick={() => { setConfirmDropout(true); }}>Drop out</Button>
                    }

                    <Button disabled={disabled} type="submit" variant="contained" color="primary">Submit</Button>

                </div>
            }
            {confirmDropout == true &&


                <div css={css`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-top: 10px;
`}>

                    <Button disabled={disabled} type="button" variant="contained" color="secondary" onClick={() => { setConfirmDropout(false); }}>No</Button>

                    <Button disabled={disabled} type="submit" variant="contained" color="primary" onClick={() => { dropOut(); }}>Yes</Button>

                </div>
            }
        </form>
    </div>;
};
AddPatient.propTypes = {
    match: ReactRouterPropTypes.match,
    onSuccess: PropTypes.func,
    patientToEdit: PropTypes.number
};
export default AddPatient;