import axios from '../../../../config/Axios';

export const fetchAuthOptions = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/oauth/options')
            .then(resolve)
            .catch(reject);
    });
};
export const loginRequest = (options) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.API_URL}/users/login`,options)
            .then(resolve)
            .catch(reject);
    });
};
export const forgotPassword = (options) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.API_URL}/users/forgot-password`,options)
            .then(resolve)
            .catch(reject);
    });
};
export const fetchUserData = () => {
    return new Promise((resolve, reject) => {
        axios.get('/api/users/me')
            .then(resolve)
            .catch(reject);
    });
};
export const logoutUser = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.API_URL}/users/logout`)
            .then(resolve)
            .catch(reject);
    });
};

export const checkToken = (data) => {
    return new Promise((resolve, reject) => {
        // // fake response timeout
        // console.log("checking token");
        // console.log(`${process.env.API_URL}users/checkToken/`);
        // axios.post(`${process.env.API_URL}users/checkToken/`, data)
        //     .then((response) => {
        //         const { data } = response;
        //         resolve(data);
        //     })
        //     .catch(reject);

        // Will randomly return success: 0 or success: 1
        console.log(data);
        const toReturn = {success: Math.round(Math.random())};
        resolve(toReturn);
        reject(); 
    });
};

export const resetRequest = data => {
    return new Promise((resolve, reject) => {
        // console.log(`${process.env.API_URL}users/setup-password/`);
        // axios.post(`${process.env.API_URL}users/setup-password/`, data)
        //     .then(response => {
        //         const { data } = response;
        //         resolve(data); 
        //     })
        //     .catch(reject);
        console.log(data);
        const toReturn = {success: Math.round(Math.random())};
        resolve(toReturn);
        reject(); 
    });
};