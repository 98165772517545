import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useState, useEffect, useRef } from 'react';

const videoScales = {
    small: 50,
    medium: 70,
    large: 90,
    default: 70
};

/** @jsx jsx */
export const Video = ({ source, size, format, onStart, onEnd, hasButton,allowSkip }) => {

    const theme = useTheme();

    const [progressBar, setProgressBar] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playState, setPlayState] = useState(null);
    const [allowedSkip, setAllowedSkip] = useState(false);

    const videoReference = useRef();

    const scale = videoScales[size] || 70;

    const playPause = () => {
        videoReference.current.paused ? videoReference.current.play(): videoReference.current.pause();
    };
    useEffect(() => {
        setAllowedSkip(false);
    },[source]);
    useEffect(() => {
        // Your code here
    

        
      }, []);
    useEffect(() => {
        videoReference.current.removeEventListener('timeupdate',() => {    setAllowedSkip(false);});
        videoReference.current.removeEventListener('play',() => {    setAllowedSkip(false);});
        videoReference.current.removeEventListener('pause',() => {    setAllowedSkip(false);});
        videoReference.current.removeEventListener('ended',() => {    setAllowedSkip(false);});
        videoReference.current.addEventListener('timeupdate', (e) => {
            console.log('time update');
            setProgressBar(e.target.currentTime);
            setDuration(e.target.duration);  
            
            if(e.target.duration / 2 <= e.target.currentTime && !allowedSkip){
                setAllowedSkip(true);
                allowSkip(true);
                console.log('allowed skipping04video');
            }
        });
        videoReference.current.addEventListener('play', () => {
            if(typeof onStart === 'function') {
                setPlayState('playing');
         


                onStart();
            } 
        });
        videoReference.current.addEventListener('pause', () => {
            setPlayState('paused');
        });
        videoReference.current.addEventListener('ended', () => {
            if(typeof onEnd === 'function') {
                onEnd();
            }
        });
       
    }, [ videoReference ]);
    return(
        <div css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            .button {
                border: 0;
                background: transparent;
                box-sizing: border-box;
                width: 0;
                height: 74px;
                position: absolute;

                border-color: transparent transparent transparent #ffffff;
                transition: 100ms all ease;
                cursor: pointer;

                // play state
                border-style: solid;
                border-width: 37px 0 37px 60px;

                &.paused {
                    border-style: double;
                    border-width: 0px 0 0px 60px;
                    opacity: 0;
                }

                &:hover {
                    border-color: transparent transparent transparent #ffffff;
                }
            }
            &:hover {
                .button {
                    opacity: 1;
                }
            }
        `}>
            { source && <video id="iheart-video" autoPlay={true} src={`${source}`} format={format} width={`${scale}%`} ref={videoReference}></video> }
            <button className={`button ${playState === 'playing' ? 'paused' : ''}`} onClick={playPause} style={{display: (hasButton? "inline": "none")}}></button>
            <div id="outer-bar" css={css`
                width: ${scale}%;
                background-color: white;
                margin: 10px;
                border: 1px solid ${theme.colors.primary};
                #inner-bar{
                    background-color: ${theme.colors.primary};
                    width: ${(progressBar / duration) * 100}%;
                    height: 10px;
                }
            `}>
                <div id="inner-bar"></div>
            </div>
        </div>
    );
};

Video.propTypes = {
    /** The source of the video */
    source: PropTypes.string.isRequired,  
    /** The format of the video. */ 
    format: PropTypes.string.isRequired,
    /** The size of the video */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /** Should a play/pause button be rendered? */
    hasButton: PropTypes.bool,
    /** callback on video start */
    onStart: PropTypes.func,
    /** callback on video end */
    onEnd: PropTypes.func,    

    allowSkip:PropTypes.func,
    
};

Video.defaultProps = {
    size: "medium",
    hasButton: false
};

