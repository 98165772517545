import { useEffect, useState } from 'react';
import { fetchUser, saveUser, deleteUser } from '../../services/UsersService';
import { getList } from '../../services/LocationsService';

import { css, jsx } from '@emotion/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { Button, Typography, TextField, FormLabel, RadioGroup, FormControlLabel, Radio, InputLabel, Select, MenuItem, FormControl, Input } from '@material-ui/core';
import { fetchRoles } from '../../services/RolesService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


/** @jsx jsx */
const AddUser = ({ match, onSuccess, userToEdit }) => {
    const [user_id] = useState((match != undefined) ? match.params.user_id : ((userToEdit >= 0) ? userToEdit : null));
    const [user, setUser] = useState({ location_ids: [] });
    const [roles, setRoles] = useState([]);
    const [locations, setLocations] = useState([]);
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [confirmDropout, setConfirmDropout] = useState(false);

    useEffect(() => {
        if (user_id >= 1 && user.id === undefined) {
            fetchUser(user_id).then((response) => {
                setUser({ ...user, ...response.data });
            });
        }
        if (locations.length == 0) {
            getList().then((data) => {
                setLocations(data.data);
            });
        }
        fetchRoles().then(r => {
            setRoles(r.roles);
        });
    }, []);

    function submit(e) {
        if (e != undefined) {
            e.preventDefault();
        };
        saveUser(user).then((data) => {
            if (data.success) {
                onSuccess();

            } else {
                setErrors(data.data);
            }
        });
    }
    function deleteuser() {
        deleteUser(user.id).then((data) => {
            if (data.success) {
                setDisabled(false);

                onSuccess();
            } else {
                setErrors(data.data);
                setDisabled(false);
                console.log(errors);
            }
        });
    }
    const handleInputChange = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const errorsToElements = (errors, i) => {
        return <div>
            {Object.values(errors).map(err => <p key={`err-${i}`}>{err}</p>)}
        </div>;
    };

    return <div css={css`
        display: flex;
        justify-content: center;
        align-items: center;
    `}>
        <form onSubmit={submit}>
            <Typography variant='h5'>{userToEdit >= 0 ? "Edit Admin" : "Add an Admin"}</Typography>
            <TextField
                fullWidth={true}
                id="first_name"
                label="First Name"
                name="first_name"
                value={user.first_name ? user.first_name : ''}
                onChange={handleInputChange}
                error={errors.first_name ? true : undefined}
                helperText={errors.first_name ? errorsToElements(errors.first_name) : ''}
            />
            <TextField
                fullWidth={true}
                id="last_name"
                label="Last Name"
                name="last_name"
                value={user.last_name ? user.last_name : ''}
                onChange={handleInputChange}
                error={errors.last_name ? true : undefined}
                helperText={errors.last_name ? errorsToElements(errors.last_name) : ''}
            />
            <TextField
                type="email"
                fullWidth={true}
                id="email"
                label="Email Address"
                name="email_address"
                value={user.email_address ? user.email_address : ''}
                onChange={handleInputChange}
                error={errors.email_address ? true : undefined}
                helperText={errors.email_address ? errorsToElements(errors.email_address) : ''}
            />
            <FormLabel component="legend" style={{ marginTop: 20 }}>Access {typeof user.role_id}</FormLabel>
            <RadioGroup aria-label="role_id" name="role_id" value={parseInt(user.role_id)} onChange={handleInputChange}>
                {roles.map(r => <FormControlLabel key={`role-${r.id}`} value={r.id} control={<Radio color="primary" />} label={r.name} />)}
            </RadioGroup>
            {user.role_id > 1 &&
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-mutiple-name-label">Name</InputLabel>
                    <Select
                        labelId="demo-mutiple-name-label"
                        id="location_ids"

                        name="location_ids"
                        multiple
                        value={user.location_ids}
                        onChange={handleInputChange}
                        input={<Input />}
                        MenuProps={MenuProps}
                    >
                        {locations.map((location) => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            {confirmDropout == false &&
                <div css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            `}>

                    <Button style={{ margin: "10px 0px" }} onClick={submit} size="large" type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                    {user.id > 0 &&
                        <Button disabled={disabled} type="button" variant="contained" color="secondary" onClick={() => { setConfirmDropout(true); }}>Delete</Button>
                    }



                </div>
            }
            {confirmDropout == true &&


                <div css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;
                    `}>

                    <Button disabled={disabled} type="button" variant="contained" color="secondary" onClick={() => { setConfirmDropout(false); }}>No</Button>

                    <Button disabled={disabled} type="button" variant="contained" color="primary" onClick={() => { deleteuser(); }}>Yes</Button>

                </div>
            }
        </form>
    </div>;
};
AddUser.propTypes = {
    match: ReactRouterPropTypes.match,
    onSuccess: PropTypes.func,
    userToEdit: PropTypes.number
};
export default AddUser;