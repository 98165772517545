 import axios from '../../../../config/Axios';


export const download = (assessment_id,from,to,clinic_ids) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
      
        axios.get('/api/data/download?assessment_id='+assessment_id+'&from='+from+'&to='+to+'&clinic_ids='+clinic_ids)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
