import { useEffect, useState } from 'react';
import { fetchRole, saveRole } from '../../services/RolesService';
import { getList } from '../../services/LocationsService';

import { css, jsx } from '@emotion/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { Button, Typography, TextField, FormLabel,RadioGroup,FormControlLabel,Radio,InputLabel,Select,MenuItem,FormControl,Input } from '@material-ui/core';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


/** @jsx jsx */
const AddRole = ({ match, onSuccess, roleToEdit }) => {
    const [role_id] = useState((match != undefined) ? match.params.role_id : ((roleToEdit >= 0) ? roleToEdit : null));
    const [role, setRole] = useState({});
    const [locations, setLocations] = useState([]);

    const [errors, setErrors] = useState({
        // first_name: {},
    });
   
    useEffect(() => {
        if (role_id >= 1 && role.id === undefined) {
            fetchRole(role_id).then((response) => {
                setRole({...role, ...response.data});
            });
        }
        if(locations.length == 0){
            getList().then((data) => {
                setLocations(data.data);
            });
        }
    });
    function submit(e) {
        if (e != undefined) {
            e.preventDefault();
        };
        saveRole(role).then((data) => {
            if (data.success) {
                console.log(data);
                onSuccess();

            } else {
                setErrors(data.data);
                console.log(errors);
            }
        });
    }
    const handleInputChange = e => {
        const { name, value } = e.target;
        setRole({ ...role, [name]: value });
    };

    const errorsToElements = (errors, i) => {
        return <div>
            {Object.values(errors).map(err => <p key={`err-${i}`}>{err}</p>)}
        </div>;
    };

    return <div css={css`
        display: flex;
        justify-content: center;
        align-items: center
    `}>
        <form onSubmit={submit} method="">
            <Typography variant='h5'>{roleToEdit >= 0 ? "Edit Role" : "Add Role"}</Typography>
            <TextField
                // required
                fullWidth={true}
                id="name"
                label="Name"
                name="name"
                value={role.name ? role.name : ''}
                onChange={handleInputChange}
                error={errors.name ? true : undefined}
                helperText={errors.name ? errorsToElements(errors.name) : ''}
            />
            <div css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                button{
                    width: 70%;
                }    
            `}>
                <Button style={{margin: "10px 0px"}} onClick={submit} size="large" type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </div> 
        </form>
    </div>;
};

AddRole.propTypes = {
    match: ReactRouterPropTypes.match,
    onSuccess: PropTypes.func,
    roleToEdit: PropTypes.number
};
export default AddRole;