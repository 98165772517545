import {css, jsx} from '@emotion/core';
import PropTypes from 'prop-types';
import {useTheme} from 'emotion-theming';

/** @jsx jsx */
export const ProConList = ({ data }) => {
  const theme = useTheme();

  return (<div css={css `
        ${'' /* * { outline: 1px solid red; outline-offset: -1px; } */}
        font-family: ${theme.texts.font.family.default};
        background-color: #fff;
        .options {
          display: flex;
          position: relative;
          &:not(:last-of-type) {
            border-bottom: 1px solid #ddd;
          }
        }
        .option {
          width: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #ddd;
          flex-direction: column;
          font-size: 1.25rem;
          padding: 20px;
        }
        .label-wrapper {
          width: 75%;
          padding-left: 40px;
          padding-right: 40px;
          padding-top: 25px;
          padding-bottom: 15px;
        }
        .label {
          display: flex;
        }
        .label-text {
          text-transform: uppercase;
          margin-right: 30px;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .pro-text {
          color: #ea2a7a;
        }
        .con-text {
          color: #858c95;
        }
        .label-content {
          color: #464d56;
        }
        .options img {
          margin-bottom: 5px;
          height: 30px;
          width: auto;
        }
      `}>
      {data.map(options =>
        <div className="options" key={options.id}>
          <div className="option">
            <img
              src={options.icon}
              alt={options.label}
            ></img>
            {options.label}
          </div>
          <div className="label-wrapper">
            {options.answers.map(label =>
              <div className="label" key={label.id}>
                <div className={`label-text ${label.type === "pro" ? 'pro-text' : 'con-text'}`}>{label.type}</div>
                <div className="label-content" dangerouslySetInnerHTML={{ __html: label.answer.replace(/(<? *script)/gi, 'illegalscript') }}></div>
              </div>)}
          </div>
        </div>
      )}
  </div>);
};

ProConList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    /** Unique id of the statement, not shown to user */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /** Image url to icon */
    icon: PropTypes.string,
    /** Label to show */
    label: PropTypes.string,
    /** Answers for the statement */
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        /** Unique id of the answer, not shown to user */
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        /** Answer type */
        type: PropTypes.oneOf(["pro", "con"]),
        /** Answer value */
        answer: PropTypes.string.isRequired,
      })
    ).isRequired,
  })).isRequired,
};

ProConList.defaultProps = {
};
