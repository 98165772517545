import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

/** @jsx jsx */
export const Select = ({errors, children, ...others}) => {
    const [errorArray, setErrorArray] = useState([]);
    useEffect(() => setErrorArray(errors));
    return <div css={css`
        select{
            // margin: 50px;
            width: 100%;
            padding: 5px 35px 5px 5px;
            font-size: 16px;
            border: 1px solid #CCC;
            height: 60px;
            background-color: white;
            // -webkit-appearance: none;
            // -moz-appearance: none;
            // appearance: none;
        }   
    `}>
        <select {...others}>
            {children}
        </select>
        <div css={css`
            div{
                color: red;
            }
        `}>
            {errorArray.map((err, i) => {
                return <div key={`err-${i}`}>{err}</div>;
            })}
        </div>
    </div>;
};

Select.propTypes = {
    /** Value of the select, should change with onChange event */
    value: PropTypes.number,
    /** Fired when input changes, argument is the event */
    onChange: PropTypes.func,
    /** Array of errors associated with the input */
    errors: PropTypes.array,
    /** Name of the select */
    name: PropTypes.string,
    /** Option elements */
    children: PropTypes.array
};