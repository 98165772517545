import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
// import Constant from '../config/constants';

/** @jsx jsx */
const ProgressText = ({ percentage }) => {
  let color = 'white';
  // if (percentage < 50) {
  //   color = Constant.Colors.Fatal;
  // } else if (percentage < 75) {
  //   color = Constant.Colors.Warning;
  // } else {
  //   color = Constant.Colors.Success;
  // }
  return <span css={css`
      font-size: 14px;
      color: ${color};
    `}>{ percentage }%</span>;
};

ProgressText.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default ProgressText;