import axios from '../../../../config/Axios';

export const fetchRoles = () => {
    return new Promise((resolve , reject) => {
        axios.get(`/api/roles/index`)
        .then((response) => {
            const { data } = response;
            resolve(data);
        })
        .catch(reject);
    });
};

export const fetchRole = (id) => {
    return new Promise((resolve , reject) => {
        axios.get(`/api/roles/view/${id}`)
        .then((response) => {
            const { data } = response;
            resolve(data);
        })
        .catch(reject);
    });
};

export const saveRole = (r) => {
    const { users, ...role } = r;
    role.permissions = JSON.stringify(role.permissions);
    return new Promise((resolve , reject) => {
        axios.post(`/api/roles/add/`, { role })
        .then((response) => {
            const { data } = response;
            resolve(data);
        })
        .catch(reject);
    });
};