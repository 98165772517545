import axios from '../config/Axios';


// export const fetchAssessment = (token) => {
//     console.log(token+'token');
//     return new Promise((resolve, reject) => {
//         axios.get('/api/patient-assessments/take/'+token)
//         .then((response) => {
//             console.log(response);
//             const { data } = response;

//             resolve(data);

//         })
//             .catch(reject);
//     });
// };

export const getCompletedAssessment = (token) => {
        
    return new Promise((resolve, reject) => {
        axios.post('/api/patient-assessments/getCompletedAssessment/'+token)
        .then((response) => {
            console.log(response);
            const { data } = response;

            resolve(data);

        })
            .catch(reject);
    });
};
export const submitAssessment = (token,answers,method) => {
    return new Promise((resolve, reject) => {
        axios.post('/api/patient-assessments/answer/'+token,{answers,method})
        .then((response) => {
            console.log(response);
            const { data } = response;

            resolve(data);

        })
            .catch(reject);
    });
};
export const previousQuestion = (token) => {
    
    return new Promise((resolve, reject) => {
        axios.post('/api/patient-assessments/previousQuestion/'+token)
        .then((response) => {
            console.log(response);
            const { data } = response;

            resolve(data);

        })
            .catch(reject);
    });
};

export const fetchAssessment = (token) => {
    console.log(token+'token');
    return new Promise((resolve, reject) => {
        axios.get('/api/patient-assessments/take/'+token)
        .then((response) => {
            console.log(response);
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
    });
};

export const getProviders = () => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get(`/api/clinics/getProviders`)
        .then((response) => {
            const {data} = response;
            resolve(data);
        })
        .catch(reject);
    });
};

// export const fetchAssessment = (token) => {
//     console.log(token+'token');
//     return new Promise((resolve, reject) => {
//         axios.get('/api/iheart.json')
//         .then((response) => {
//             console.log(response);
//             response.data.data = response.data;
            
//                 console.log(response);
//             resolve(response.data);

//         })
//             .catch(reject);
//     });
// };