import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** @jsx jsx */
const SecondaryNavigationItem = ({ active, children, ...others }) => {
  return (
    <div css={css`
          margin: 0 30px 0 30px;
          cursor: pointer;
          &:first-of-type {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            color: #3FAD9F;
          }
          &.active {
            font-weight: 600;
            color: #3FAD9F;
          }
        `} 
        className={`menu-item ${ active ? 'active' : '' }`} {...others}>
        { children }
    </div>
  );
};

SecondaryNavigationItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any,
};

SecondaryNavigationItem.defaultProps = {
  active: false,
};

export default SecondaryNavigationItem;