import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** @jsx jsx */
const LayoutContainer = ({ children, ...others }) => {
  return <div
      css={css`
        display: flex;
        flex-direction: column;
        max-width: 800px;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;

        @media (max-width: 800px) {
          width: 85%;
        }
      `}
      {...others}>
        {children}
      </div>;
};

LayoutContainer.propTypes = {
  children: PropTypes.any,
};

export default LayoutContainer;
