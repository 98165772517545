import React, { useEffect, useState } from 'react';
import { download } from '../../services/DownloadService';
import { fetchLocations } from '../../services/LocationsService';
import moment from "moment";

import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
const ViewPatient = () => {
    const [clinic_ids, setClinicianIds] = useState([]);
    const [loading, setLoading] = useState([]);

    const [locations, setLocations] = useState([]);
    const [assessment_id, setAssessmentId] = useState(2);

    const [from, setFrom] = React.useState(moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD'));
    const [to, setTo] = React.useState(moment(new Date()).format('YYYY-MM-DD'));


    const handleChange = (event) => {
        setClinicianIds(event.target.value);
    };
    useEffect(() => {
        fetchLocations(0).then((response) => {
            // console.log(response);
            setLocations(response.data);

        });
    },[]);
    const fromHandle = (date) => {
        setFrom(date);
    };
    const toHandle = (date) => {
        setTo(date);
    };
    const downloadData = () => {
        setLoading(1);

        download(assessment_id,moment(from).format("YYYY-MM-DD"),moment(to).format("YYYY-MM-DD"),clinic_ids).then((data) => {
           // console.log('/api/data/dl/'+data.data.filename);
           if(data.data.filename != ''){
            window.location.href = '/api/data/dl/'+data.data.filename;
           }
           else{
               alert('No data for given period');
           }
            setLoading(0);

        }).catch(() => {

        });
        
    };
    return <div>
        <h2 style={{ marginBottom: 0 }}>Download Data</h2>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <Grid container justify="">
            <FormControl variant="outlined" style={{ width: 100, marginTop: 16, marginRight: 20 }}>
                    <InputLabel id="demo-mutiple-name-label">Assessment</InputLabel>
                    <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"

                        
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}

                        value={assessment_id}
                        onChange={(event) => {
                            setAssessmentId(event.target.value);
                        }}
                        input={<Input />}
                    >
                        {[{name:'IHeart',id:1},{name:'Depcare',id:2}].map((clinic) => (
                            <MenuItem key={clinic.id} value={clinic.id} >
                                {clinic.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ width: 230, marginTop: 16, marginRight: 20 }}>
                    <InputLabel id="demo-mutiple-name-label">Clinic</InputLabel>
                    <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"

                        multiple
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}

                        value={clinic_ids}
                        onChange={handleChange}
                        input={<Input />}
                    >
                        {locations.map((clinic) => (
                            <MenuItem key={clinic.id} value={clinic.id} >
                                {clinic.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="From"
                    style={{ width: 140, marginRight: 20 }}

                    value={from}
                    onChange={fromHandle}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    id="date-picker-inline-2"
                    label="To"
                    format="MM/dd/yyyy"
                    value={to}
                    style={{ width: 140, marginRight: 20 }}
                    onChange={toHandle}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <div>
                    {loading == 0 &&
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 35 }}
                            onClick={downloadData}
                            size="small"
                            startIcon={<GetAppIcon />}
                        >
                            Download
                     </Button>
                    }
                    {loading == 1 &&
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={true}
                            style={{ marginTop: 35 }}
                            onClick={downloadData}
                            size="small"
                            startIcon={<GetAppIcon />}
                        >
                            Gathering data..
                 </Button>
                    }

                </div>
            </Grid>
        </MuiPickersUtilsProvider>

    </div>;
};

export default ViewPatient;