import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';

import iheartTheme from './theme/iheart';
import depcareTheme from './theme/depcare';

const textToTheme = {
  'iheart': iheartTheme,
  'depcare': depcareTheme
};

const ThemedProvider = props => (
  <ThemeProvider theme={textToTheme[props.theme]}>
    { props.children }
  </ThemeProvider>
);

ThemedProvider.propTypes = {
  children: PropTypes.any.isRequired,
  theme: PropTypes.string,
};

ThemedProvider.defaultProps = {
  theme: 'iheart',
};

export default ThemedProvider;