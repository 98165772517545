import { getInformation } from './../../../services/InformationService.js';

import { useState } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { Button } from 'ComponentLibrary/index';
import { Input } from 'ComponentLibrary/index';
import { PhoneInput } from 'ComponentLibrary/index';
import { useSelector } from 'react-redux';



/** @jsx jsx */
const InformationForm = ({ material,token }) => {
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const {  language } = useSelector(state => state.auth.userData);

    const [errors, setErrors] = useState({
        // phone_number: '',
        // email_address: '',
        // general: ''
    });
    const [patientInfo, setPatientInfo] = useState({
        phone_number: '',
        email_address: '',
        token:token,
        url: window.location.href,
        material: material
    });
    function submit(e) {
        if (e != undefined) {
            e.preventDefault();
        }
        // console.log(patientInfo);
        getInformation(patientInfo).then((data) => {
            // console.log(data);
            if (data.success) {
                // console.log("success!");
                setSuccess(true);
                setSuccessMessage(data.data.successMessage);
                setErrors({});
            } else {
                // console.log("found an error");
                setErrors(data);
            }
        });
    }
    const handleInputChange = e => {
        const { name, value } = e.target;

        setPatientInfo({ ...patientInfo, [name]: value });
    };

    return <div css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        *{
            width: 25vw;
            min-width: 300px;
            text-align: center;
            margin: 2px 0;
        }
        .err{
            color: red;
            text-align: center;
        }
        .form-group{
            margin-bottom: 10px;
        }
        label{
            font-size: 0.75rem;
        }
    `}>
        {language == 1 &&
            <h2 style={{ textAlign: "center", marginBottom: 10, width: "80vw" }}>Send me this information</h2>
        }
        {language == 0 &&
            <h2 style={{ textAlign: "center", marginBottom: 10, width: "80vw" }}>Mándeme más información:</h2>
        }
        {success == true &&
            <p style={{ borderColor: '#E6F4D9', backgroundColor: '#ECFFD7', border: 1, padding: 10, fontSize: 14, color: '#72B065' }}>
                {successMessage}
            </p>
        }
        {success == false &&
            <form method="" onSubmit={submit} css={css`
            ${errors.general ? 'border: 1px solid red;' : ''}
            padding: 3px;
        `}>


                {language == 1 &&
                    <div className="form-group">
                        <label>EMAIL</label>
                        <Input
                            size="sm"
                            placeholder="Tap To Enter Your Email"
                            type='email'
                            name="email_address"
                            value={patientInfo.email_address}
                            className="input"
                            // errors={errors.email_address !== ''? errors.email_address : []}
                            onChange={handleInputChange}
                            style={{ marginBottom: 5 }}
                        />
                    </div>
                }
                {language == 0 &&
                    <div className="form-group">

                        <label>CORREO ELECTRÓNICO</label>
                        <Input
                            size="sm"
                            placeholder="Toque para ingresar su correo electrónico"
                            type='email'
                            name="email_address"
                            value={patientInfo.email_address}
                            className="input"
                            // errors={errors.email_address !== ''? errors.email_address : []}
                            onChange={handleInputChange}
                            style={{ marginBottom: 5 }}
                        />
                    </div>

                }
                {language == 1 &&

                    <div className="form-group">
                        <label>PHONE NUMBER</label>
                        <PhoneInput
                            size="sm"
                            placeholder="Tap To Enter Your Phone #"
                            name="phone_number"
                            value={patientInfo.phone_number}
                            className="input"
                            // errors={errors.phone_number !== ''? errors.phone_number : []}
                            onChange={handleInputChange}
                            style={{ marginBottom: 5 }}
                        />
                    </div>
                }
                {language == 0 &&
                    <div className="form-group">
                        <label>NÚMERO DE TELÉFONO</label>
                        <PhoneInput
                            size="sm"
                            placeholder="Toque para ingresar su número de teléfono #"
                            name="phone_number"
                            value={patientInfo.phone_number}
                            className="input"
                            // errors={errors.phone_number !== ''? errors.phone_number : []}
                            onChange={handleInputChange}
                            style={{ marginBottom: 5 }}
                        />
                    </div>
                }


                <Button type="primary" style={{ backgroundColor: "#5fb0bb" }}>{"Submit"}</Button>
            </form>
        }
        {errors["general"] ?
            errors["general"].map((err, i) => {
                return <div className="err" key={`err-${i}`}>{err}</div>;
            })
            :
            <div></div>
        }
    </div>;
};

InformationForm.propTypes = {
    material: PropTypes.string,
    token:PropTypes.string,
};
export default InformationForm;