import { css, jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

/** @jsx jsx */
const SideMenuItem = ({ to, children, ...others }) => {
  return <NavLink exact
      css={css`
        display: block;
        padding: 15px;
        color: white;
        text-decoration: none;
        text-align: center;
        font-size: 24px;
        &.active {
          background-color: #051B2E;
        }
        
      `}
      to={to} {...others}>
        {children}
      </NavLink>;
};

SideMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default SideMenuItem;