import { useEffect, useState } from 'react';
import { sendReportEmail } from '../../services/PatientAssessmentsService';
import { css, jsx } from '@emotion/core';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

// import { Button } from 'ComponentLibrary/index';
// import { Input } from 'ComponentLibrary/index';
// import { Select } from '../../../../shared/components/Elements/Select';

import { Button, TextField, Typography } from '@material-ui/core';


/** @jsx jsx */
const SendReportEmailForm = (
    // {match}
    props
    ) => {
    const reportId = props.reportId;
    // const patient_id = null;
    
    const [loading,setLoading] = useState(false);
    const [errors, setErrors] = useState({
        // mrn: {},
        // first_name: {},
        // last_name: {},
        // email: {},
        // phone:{},
        // current_provider_id: {},
        // current_clinic_id: {}
    });
    const [report,setReport] = useState({
        
        email_address:'',
       
    });
    useEffect(() => {
       
    });
    function submit(e){
        if(e != undefined){
        e.preventDefault();
        }
        // console.log('sdfsd');
        setLoading(true);
        // console.log(patient);
        sendReportEmail(reportId,report.email_address).then((data) => {
            setLoading(false);
            if(data.success){
                props.onSuccess();
            } else {
                setErrors(data.data);
            }
        });
    }
    const handleInputChange = e => {
        const {name, value} = e.target;
    
        setReport({...report, [name]: value});
    };

    const errorsToElements = (errors, i) => {
        return <div>
            {Object.values(errors).map(err => <p key={`err-${i}`}>{err}</p>)}
        </div>;
    };
    return <div css={css`
        display: flex;
        justify-content: center;
        align-items: center
    `}>
        <form method="" onSubmit={submit} css={css`
            *{
                margin: 5px 0px;
            }
        `}>
        <Typography variant='h5'>Email Report</Typography>
    
        <TextField 
            fullWidth={true} 
            type="email" 
            id="email" 
            name="email_address" 
            label="Email Address" 
            value={report.email_address? report.email_address: ''} 
            onChange={handleInputChange} 
            error={errors.email_address ? true : undefined}
            helperText={errors.email_address ? errorsToElements(errors.email_address) : ''} 
        />
       
        <div css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            button{
                width: 70%;
            }
        `}>
            <Button type="submit" variant="contained" disabled={loading == true} color="primary">
                Submit
            </Button>        
        </div>    
        </form>
    </div>;
};
SendReportEmailForm.propTypes = {
    match: ReactRouterPropTypes.match,
    onSuccess: PropTypes.func,
    reportId: PropTypes.number
};
export default SendReportEmailForm;