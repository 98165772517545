import React, { useState, useEffect } from 'react';
import { Input, Button } from 'ComponentLibrary/index';
import { useSelector } from 'react-redux';
import { checkToken, startAssessment, getLatestAssessments } from '../../../services/ProviderService';
// import sampleData from './sampleData.json';
import ReactRouterPropTypes from 'react-router-prop-types';
// import ReactPaginate from 'react-paginate';
import Pagination from '@material-ui/lab/Pagination';
const Overview = ({ match, history }) => {

    const userData = useSelector(state => state.auth.userData);

    const [ password, setPassword ] = useState('');
    const [ loggedIn, setLoggedIn ] = useState(false);
    const [ assessments, setAssessments ] = useState([]);

    const [ pagination, setPagination ] = useState({page: 1});
    
    const login = () => {
        checkToken({ token: match.params.token, password }).then(r => {
            if(r.success) {
                console.log(userData);
                loadAssessments(1);
                setLoggedIn(true);
            }
        });
    };

    const loadAssessments = (pageParam) => {
        getLatestAssessments(pageParam, match.params.token).then(({data}) => {
            console.log(data);
            setLoggedIn(true);
            setAssessments(data.data.patient_assessments);
            setPagination(data.__pagination.PatientAssessments);
        });
    };

    const start = () => {
        startAssessment(match.params.token).then(r => {
            
            // const token = r.token;
            
           
            console.log(r);
            if(r.success == 1){
             history.push(`/depcare/token/${r.data.token}/1`);
            }
        });
    };

    useEffect(() => {
        var input = document.getElementById("pwd-input");
        // Execute a function when the user releases a key on the keyboard
        if (input != null){
            input.addEventListener("keyup", function(event) {
                // Number 13 is the "Enter" key on the keyboard
                if (event.keyCode === 13) {
                    // Cancel the default action, if needed
                    event.preventDefault();
                    // Trigger the button element with a click
                    document.getElementById("login-btn").click();
                }
            });
        }
    });
    useEffect(() => {

    loadAssessments(1);
    },[]);

    return (
        <div style={{marginTop: 20}}>
            { !loggedIn && (
                <>
                    <div style={{textAlign: 'center'}}>
                        <h2>Provider Password</h2>
                        <p>Please enter your personal 5-digit provider password below.</p>
                    </div>
                    <Input placeholder="*****" id="pwd-input" type="password" maxLength={5} style={{ letterSpacing: '15px'}} onChange={e => setPassword(e.target.value)} />
                    <div style={{ textAlign: 'center', marginTop: '15px'}}>
                        <Button onClick={() => login()} id="login-btn" style={{ backgroundColor: '#12A3DA', color: 'white', fontSize: '18px'}}>Login</Button>
                    </div>
                </>
            )}
            { loggedIn && (
                <>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: 'center', marginTop: '15px'}}>
                        <Button onClick={() => start()} style={{ backgroundColor: '#12A3DA', color: 'white', fontSize: '18px'}}>Start Assessment</Button>
                        <table className="table" style={{
                            marginTop: 30, 
                            borderCollapse: "collapse", 
                            columnGap: 30,
                            tr: {
                                paddingLeft: 10
                            },
                            th: {
                                paddingLeft: 10
                            },
                        }}>
                          <thead>
                            <tr>
                                <th>MRN</th>
                                <th>Date Completed</th>
                                <th>Report</th>
                                <th>Provider</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assessments? assessments.map((assessment, i) => {
                              return <tr key={`row-${i}`}>
                                <td>{assessment.mrn}</td>
                                <td>{assessment.is_completed? assessment.completed_at: 'Not Completed'}</td>
                                <td>{assessment.is_completed? <a href={`/admin/patients/results/${assessment.id}`}>See Report</a>: 'N/a'}</td>
                                <td>{assessment.provider}</td>
                              </tr>;
                            }) : <> </>}
                          </tbody>
                        </table>
                        <Pagination
                            count={pagination.pageCount}
                            size={"medium"}
                            defaultPage={1}
                            page={pagination.page}
                            onChange={(e, newPage) => loadAssessments(newPage)}
                        />                    
                    </div>
                </>
            )}
        </div>
    );
};

Overview.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};

    // const [ lastPageLoaded, setLastPageLoaded ] = useState(0);
export default Overview;