import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

/** @jsx jsx */
export const PhoneInput = ({ errors, size, ...others }) => {
    const [errorArray, setErrorArray] = useState(errors ? [...errors] : []);
    useEffect(() => {
        if (errors) {
            setErrorArray(errors);
        }
    });
    const fontSize = ((param) => ({
        "sm": "1rem",
        "lg": "2.5rem",
        "md": "2rem"
    }[param] || 'md'))(size);

    return (
        <div>
            <NumberFormat format="(###) ###-####" css={css`
        text-align: center;
        display: inline-block;
        width: 100%;
        padding: .375rem 0;
        font-size: ${fontSize};
        font-weight: 300;
        line-height: 2;
        color: #495057;
        background-color: #fff;
        border: ${errorArray.length == 0 ? 'none' : '1px solid red'};
        outline: none;
        &::placeholder {
          color: #ccc;
        }
        
        `} {...others}
            />
            <div className="errors" css={css`
          div {
            color: red;
          }
        `}>
                {errorArray.map((err, i) => {
                    return <div key={`err-${i}`}>{err}</div>;
                })}
            </div>
        </div>
    );
};

PhoneInput.propTypes = {
    /** Placeholder text to show when input is empty */
    placeholder: PropTypes.string,
    /** Set to true to make the input immutable */
    readOnly: PropTypes.bool,
    /** Value of the input, should change with onChange event */
    value: PropTypes.string,
    /** Fired when input changes, argument is the event */
    onChange: PropTypes.func,
    /** Array of errors associated with the input */
    errors: PropTypes.array,
    /** Size of the input */
    size: PropTypes.oneOf(["sm", 'md', "lg"])
};

PhoneInput.defaultProps = {
    size: "md"
};
