 import axios from '../../../../config/Axios';
 
 export const deleteProvider = (id) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get(`/api/providers/delete/${id}`)
        .then((response) => {
            const { data } = response;
            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const saveLocationProvider = (data) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/providers/add/',{provider:data})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const getProvidersWithPatients = () => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get('/api/providers/getProvidersWithPatients/')
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

