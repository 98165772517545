import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Permission = props => {
    try {
        if(!props.loggedIn) {
            throw '401';
        }

        const userPermissions = props.userData.role.permissions;

        let hasAccess = false;
        if(userPermissions['*']) {
            hasAccess = true;
        }
        let permissions = props.allowed;
        if(typeof props.allowed === 'string') {
            permissions = [props.allowed];
        }
        for(let p in permissions) {
            if(userPermissions[permissions[p]]) {
                hasAccess = true;
            }
        }
        if(permissions.indexOf('Always.Deny') !== -1) {
            hasAccess = false;
        }
        return hasAccess ? props.children : <span></span>;
    }
    catch(e) {
        console.log(e);
        return <span></span>;
    }
};

Permission.propTypes = {
    allowed: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    children: PropTypes.element
};

const mapStateToProps = state => ({
    userData: state.auth.userData,
    loggedIn: state.auth.loggedIn
});

export default connect(mapStateToProps, null)(Permission);