import Color from 'color-js';

const Theme = {
    name: "Crizit Default",
    texts: {
        font: {
            family: {
                default: 'PT Sans',
                secondary: 'PT Sans',
            },
            size: {
                default: 12,
                sm: 10,
                md: 12,
                lg: 16
            }
        },
        colors: {
            default: '#00375D',
            primary: '#FFFFFF',
            disabled: '#005F9F',
            warning: '#FFFFFF',
            danger: '#FFFFFF',
            success: '#FFFFFF',
        }
    },
    colors: {
        default: '#FFFFFF',
        primary: '#13D3C6',
        disabled: '#F5F6FA',
        warning: '#F2C249',
        danger: '#E07264',
        success: '#02AF75',
        navbar: 'linear-gradient(90deg,#12a2da 0,#13d4c7)',
    },
    utilities: {
        breakpoints: {
            xs: 0,
            sm: '576px',
            md: '768px',
            lg: '992px',
            xl: '1200px'
        }
    },
    components: {
        borderRadius: 5
    }
};

// Create extra color sets that are slightly dark for hovers
for(let color in Theme.colors) {
    Theme.colors[`${color}Hover`] = Color(Theme.colors[color]).darkenByRatio(.1).toCSS();
}

export default Theme;
