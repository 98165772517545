import axios from '../config/Axios';

export const registerModal = (token,modal) => {
    return new Promise((resolve, reject) => {
        // fake response timeout
        axios.post('/api/information/registerModal/', {token,modal})
            .then((response) => {
                const { data } = response;

                resolve(data);

            })
            .catch(reject);
    });
};

export const getInformation = (data) => {
    return new Promise((resolve, reject) => {
        // fake response timeout
        axios.post('/api/information/getInformation/', data)
            .then((response) => {
                const { data } = response;

                resolve(data);

            })
            .catch(reject);
    });
};
