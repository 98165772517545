import axios from '../../../../config/Axios';


export const getLocationsAndProviders = () => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get('/api/users/getLocationsAndProviders/')
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const fetchUsers = (page) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get('/api/users?page='+page)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const saveLocationProvider = (data) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/users/add/',{location:data})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const deleteUser = (id) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/users/delete/'+id)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const saveUser = (data) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/users/add/',{user:data})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const fetchUser = (id) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.get(`/api/users/view/${id}`)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};

export const fetchPermissions = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.API_URL}/roles/collect-permissions`)
            .then(resolve)
            .catch(reject);
    });
};