import React, { useEffect, useState } from 'react';
import { fetchUsers } from '../../services/UsersService';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AddRole from './AddRole';

import SortableTable from './../../components/SortableTable';
import { fetchRoles } from '../../services/RolesService';
import { useHistory } from 'react-router-dom';

const IndexPage = () => {
    const [ roles, setRoles ] = useState([]);
    const history = useHistory();
    const [tableHeaders] = useState([
        {
            name: 'Name',
            key: 'name',
            type: 'string',
            sortable: true,
            link: false,
            remove: false,
            callback: viewRole,
        },
    ]);
    const [page, setPage] = useState(1);
    const [lastPageLoaded, setLastPageLoaded] = useState(0);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [roleToEdit, setRoleToEdit] = useState(-1);

    function loadUsers(){
        fetchRoles().then((response) => {
            console.log(response.roles);
            setRoles(response.roles);
        });
    }
    useEffect(() => {
        if(page != lastPageLoaded){
            loadUsers();
        }
        else if(roles.length == 0){
            loadUsers();
        }
    }, []);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setRoleToEdit(-1);
    };
    const handleAddSuccess = () => {
        handleDialogClose();
        loadUsers();
    };
    function editRole(id){
        setRoleToEdit(id); 
        handleDialogOpen();
    };
    function viewRole(id) {
        history.push(`/admin/roles/${id}`);
    }
    const StyledPagination = withStyles(() => ({
        root: {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
        }
    }))(Pagination);

    return <div>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <h2>Roles</h2>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: 20 }}>
                {/* <Link href="/admin/clinics/add"> */}
                <Button
                    variant="contained"
                    color="default"
                    onClick={handleDialogOpen}
                >
                    Add
                    </Button>
                 <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <AddRole
                            onSuccess={handleAddSuccess}
                            roleToEdit={roleToEdit}
                        />
                    </DialogContent>
                </Dialog> 
            </Grid>
        </Grid>
        <SortableTable
            data={roles}
            headers={tableHeaders}
        />
        <StyledPagination
            count={totalPageCount}
            size={"medium"}
            defaultPage={1}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
        />
    </div>;
};

export default IndexPage;