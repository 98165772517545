import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import FilterListIcon from '@material-ui/icons/FilterList';
import Link from '@material-ui/core/Link';
// import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Permission } from '../../../shared/Security/index';
import { makeStyles } from '@material-ui/core/styles';


// const useStyles = makeStyles((theme) => ({
//     root: {
//     width: '100%',
//     },
//     paper: {
//     width: '100%',
//     marginBottom: theme.spacing(2),
//     },
//     table: {
//     minWidth: 750,
//     },
//     visuallyHidden: {
//     border: 0,
//     clip: 'rect(0 0 0 0)',
//     height: 1,
//     margin: -1,
//     overflow: 'hidden',
//     padding: 0,
//     position: 'absolute',
//     top: 20,
//     width: 1,
//     },
// }));

const SortableTable = (props) => {
    const {
        data,
        headers,
        defaultOrderBy, 
        defaultOrder,
        sortCallback,
        ...others
    } = props;
    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [filterOptions, setFilterOptions] = useState({});
    const [filterStates, setFilterStates] = useState({});
    
    const classes = makeStyles({
        tableFilters: {
            padding: '16px 16px 25px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: '#fff',
        },
        filtersContainer: {
            
            gap: 25,
            display: 'grid',
            gridAutoFlow: 'column',
        }
    })();

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
    }))(TableRow);
    const StyledTableCell = withStyles(() => ({
        // @todo: ask bas about this color
        head: {
            backgroundColor: '#fff',
            fontWeight: 'bold',
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);
    const StyledHeadRow = withStyles(() => ({
        // @todo: ask bas about this color
        // head: {
        //     backgroundColor: '#e0e0e0',
        //     fontWeight: 'bold',
        // },
        // body: {
        //     fontSize: 14,
        // },
        root: {
            border: '0px',
            borderRadius: "15px",
            backgroundColor: '#fff',
        }
    }))(TableRow);

    const StyledPaper = withStyles(() => ({
        // @todo: ask bas about this color
        // head: {
        //     backgroundColor: '#e0e0e0',
        //     fontWeight: 'bold',
        // },
        // body: {
        //     fontSize: 14,
        // },
        root: {
            borderRadius: 4
        }
    }))(Paper);

    useEffect(() => {
        // console.log(data);
        // console.log(headers);
        headers.map((header) => {
            if(header.filterable){
                header.getFilterOptions().then(res => {
                    setFilterOptions(prev => {
                        let obj = {...prev};
                        obj[header.key] = res;
                        return obj;
                    });
                }).catch(err => console.log(err));
            };
        });
    }, [headers]);

    useEffect(() => {
        if(sortCallback){
            sortCallback(orderBy, order);
        };
    }, [order, orderBy]);
      
    function sortLabelListener(key){
        if(key === orderBy){
            if(order === 'asc'){
                setOrder('desc');
            } else {
                setOrder('asc');
            }
        } else {
            setOrderBy(key);
            setOrder('asc');
        }
    }

    return <TableContainer {...others} component={StyledPaper}>
        <div className={classes.tableFilters}>
            {(headers.filter(header => header.filterable === true).length !== 0) && 
                <div className={classes.filtersContainer}>
                    {headers.map(header => {
                        function handleFilterChange(e, newValue) {
                            header.onFilterChange(e, newValue); 
                            let obj = {...filterStates};
                            obj[header.key] = newValue;
                            setFilterStates(obj); 
                        }
                    
                        return (
                            header.filterable && (
                                <div className={`${header.key}`} key={header.key}>
                                    <Permission allowed={(header.allowed != undefined)?header.allowed:['Clinics.Index']} key={header.key}>
                                        {header.filterable?
                                            <Autocomplete
                                                multiple
                                                id={header.key}
                                                options={filterOptions[header.key]? filterOptions[header.key].ids : []}
                                                limitTags={1}
                                                getOptionLabel={option => filterOptions[header.key]? filterOptions[header.key].labels[option]: '' }
                                                value={filterStates[header.key]? filterStates[header.key] : []}
                                                onChange={handleFilterChange}
                                                renderInput={(params) => <TextField {...params} label={header.name}/>}
                                            />
                                        :
                                        <></>}
                                    </Permission>
                                </div>
                            )
                        );
                    })}
                </div>
            }
        </div>
        <Table aria-label="customized table">
            <TableHead>
                <StyledHeadRow>
                    {headers.map(header => {
                        // return <StyledTableCell key={header.key} align={header.remove? 'right': 'left'}>
                        return <StyledTableCell key={header.key} align={header.align ? `${header.align}` : header.remove ? 'right': 'left'}>
                            <Permission allowed={(header.allowed != undefined)?header.allowed:['Clinics.Index']} key={header.key}>
                            {header.sortable? 
                                <TableSortLabel
                                    active={orderBy === header.key}
                                    direction={orderBy === header.key ? order : 'asc'}
                                    onClick={() => sortLabelListener(header.key)}
                                >
                                    {header.name}
                                </TableSortLabel>
                            : 
                                header.name
                            }
                            </Permission>
                            </StyledTableCell>;
                        })}
                </StyledHeadRow>

            </TableHead>
            <TableBody>
                {data.map((row) => {                    
                    return(
                        <StyledTableRow key={`patient-${row.id}`}>
                            {headers.map(header => {
                                // return <StyledTableCell key={header.key} align={header.remove? 'right': 'left'}>
                                return <StyledTableCell key={header.key} align={header.align ? `${header.align}` : header.remove ? 'right': 'left'}>
                                                                <Permission allowed={(header.allowed != undefined)?header.allowed:['Clinics.Index']} key={header.key}>

                                    {!header.link && !header.callback && !header.remove && !header.isHtml &&
                                        row[header.key]
                                    }
                                      {!header.link && !header.callback && !header.remove && header.isHtml &&
                                          <div dangerouslySetInnerHTML={{ __html: row[header.key]}}></div>

                                    }
                                    {header.link && !header.conditionalLink && header.isButton &&
                                            (header.isNewPage ? 
                                            <Link target="_blank" rel="noreferrer" href={row[header.key]}><Button variant="outlined" color="primary" endIcon={<LaunchIcon />}>{header.linkText}</Button></Link>
                                            : <Link href={row[header.key]}><Button variant="contained">{header.linkText}</Button></Link>)
                                    }
                                    {header.link && !header.conditionalLink && !header.isButton && row[header.key] != '' &&
                                            <Link href={row[header.key]}>{header.linkText}</Link>                                        
                                    }
                                    {header.link && header.conditionalLink &&
                                        !header.isButton &&
                                            row[header.key] ?
                                                <Link href={row[header.key]}>{header.linkText}</Link>                                        
                                            :
                                                header.altLinkText && 
                                        header.isButton && 
                                            row[header.key] ?
                                                (header.isNewPage ?
                                                <Link href={row[header.key]} ><Button variant="outlined" color="primary" endIcon={<LaunchIcon />}>{header.linkText}</Button></Link>                                        
                                                : <Link href={row[header.key]}><Button variant="contained">{header.linkText}</Button></Link>                                        
                                                )
                                            :
                                                header.altLinkText
                                    }
                                    {header.callback &&
                                    <div>
                                        <Permission allowed={(header.callbackPermission != undefined)?header.callbackPermission:['Clinics.Index']}>
                                             <Link onClick={() => header.callback(row.id)}>{header.fixedText? header.fixedText : row[header.key]}</Link>
                                        </Permission>
                                       <Permission allowed={(header.callbackPermission != undefined)?[false]:header.callbackPermission}>
                                       {header.fixedText? header.fixedText : row[header.key]}
                                       </Permission>
                                        </div>
                                    }
                                    {header.remove && 
                                        <IconButton aria-label="delete" color="secondary" onClick={() => header.remove(row.id)}><DeleteIcon /></IconButton>
                                    }
                                    </Permission>
                                </StyledTableCell>;
                            })}
                        </StyledTableRow>
                    );
                })}
            </TableBody>
        </Table>
    </TableContainer>;
};

SortableTable.propTypes = {
    data: PropTypes.array,
    headers: PropTypes.object,
    defaultOrder: PropTypes.string,
    defaultOrderBy: PropTypes.string,
    sortCallback: PropTypes.function
};

export default SortableTable;