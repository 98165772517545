import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";
import { useState, useEffect } from 'react';

/** @jsx jsx */
export const GroupedQuestion = ({
  questions,
  selected,
  errors,
  onChange,
  ...others
}) => {
  const theme = useTheme();
  const [errorArray, setErrorArray] = useState(errors ? errors : []);
  useEffect(() => {
    if (errors) {
      console.log(errors);
      setErrorArray(errors);
    };
  });
  return (
    <div
      css={css`
        font-family: ${theme.texts.font.family.default};
        background-color: #fff;
        .options {
          display: flex;
          position: relative;
          &:not(:last-of-type) {
            border-bottom: 1px solid #ddd;
          }
        }
        .option {
          width: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #ddd;
          flex-direction: column;
          font-size: 1.25rem;
          padding: 20px;
        }
        .label-wrapper {
          width: 75%;
          padding-left: 40px;
          padding-right: 40px;
          padding-top: 25px;
          padding-bottom: 15px;

          p {
            margin-left: -10px;
            margin-top: 0;
          }

          .items-wrap {
            display: flex;
            flex-wrap: wrap;
            > div {
              flex-basis: 33%;
            }
          }
        }
        .label {
          display: flex;
        }
        .label-text {
          text-transform: uppercase;
          margin-right: 30px;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .pro-text {
          color: #ea2a7a;
        }
        .con-text {
          color: #858c95;
        }
        .label-content {
          color: #464d56;
        }
        .options img {
          margin-bottom: 5px;
          height: 30px;
          width: auto;
        }
        /* Hide the browser's default radio button */
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
        .radio, .checkbox {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-50%, -50%);
          height: 15px;
          width: 15px;
          background-color: #fff;
          border: 2px solid #999;
        }

        .radio {
          border-radius: 50%;
        }

        /* When the radio button is checked, add a blue background */
        .options input:checked ~ .radio, .options input:checked ~ .checkbox {
          background-color: #fff;
          border: 2px solid ${theme.colors.primary};
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .radio:after, .checkbox:after {
          content: "";
          position: absolute;
          display: none;
          top: 54%;
          left: 54%;
          transform: translate(-54%, -54%);
          width: 10px;
          height: 10px;
          background: ${theme.colors.primary};
        }

        /* Show the indicator (dot/circle) when checked */
        input:checked ~ .radio:after, input:checked ~ .checkbox:after  {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .options .radio:after {
          border-radius: 50%;
        }
        .answers {
          display: flex;
          position: relative;
          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
        .answer {
          margin-left: 25px;
        }
      `}
      {...others}
    >
      {questions.map((question) => (
        <div className="options-box" key={question.id}>
          <div className="options" css={css`
            ${errorArray[question.id] ? 'border: 1px solid red !important;' : ''}
          `}>
            <div className="option">
              <img
                // src={options.label === "Counseling" ? counseling : (options.label === "Exercise" ? exercise : (options.label === "Medication" ? rx : ''))}
                src={question.icon}
                alt={question.label}
              ></img>
              {question.label}
            </div>
            <div className="label-wrapper">
              {question.question && (<p>{question.question}</p>)}
              <div className={`${question.alignAnswers === 'wrap' ? 'items-wrap' : ''}`}>
                {question.answers.map((ans) => (
                  <div key={ans.id} className="answers">
                    <div className="answer" dangerouslySetInnerHTML={{ __html: ans.answer.replace(/(<? *script)/gi, 'illegalscript') }}></div>
                    <label className="radio-wrapper">
                      <input
                        type={question.groupType === "multi" ? 'checkbox' : 'radio'}
                        value={ans.id}
                        checked={Array.isArray(selected[question.id]) ? selected[question.id].indexOf(ans.id) !== -1 : selected[question.id] === ans.id}
                        onChange={(event) => onChange(question, ans, event)}
                        className="form-check-input"
                      />
                      <span className={question.groupType === "multi" ? 'checkbox' : 'radio'}></span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="errors" css={css`
              div {
                color: red;
                margin-bottom: 5px;
              }
          `}>
            {errorArray[question.id] ?
              errorArray[question.id].map((err, i) => {
                return <div key={`err-${i}`}>{err}</div>;
              })
              :
              <div></div>
            }
          </div>
        </div>
      ))}
    </div>
  );
};

GroupedQuestion.propTypes = {
  /** Questions */
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Unique id of the question, not shown to user */
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      /** Image url to icon */
      icon: PropTypes.string,
      /** Label to show */
      label: PropTypes.string,
      /** Answers for the question */
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          /** Unique id of the answer, not shown to user */
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
          /** Answer value */
          answer: PropTypes.string.isRequired,
        })
      ).isRequired,
      /** Question formatting, default: "stack" */
      alignAnswers: PropTypes.oneOf(["stack", "wrap"]),
      /** Type of answer, default "single" */
      grouptype: PropTypes.oneOf(["single", "multi"]),
      /** Question to show above the answer possibilities */
      question: PropTypes.string,
    })
  ).isRequired,
  /** Currently selected answer, key should be the question id and value should be the answer id selected (array when groupType multi) or null */
  selected: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.arrayOf(PropTypes.any)])
  ).isRequired,
  /** array of errors to be passed to this question */
  errors: PropTypes.array,
  /** Event when an answer is clicked, arguments are: question object, answer object, event. Should change selected prop */
  onChange: PropTypes.func.isRequired,
};

GroupedQuestion.defaultProps = {
  selected: {},
  grouptype: "single"
};
