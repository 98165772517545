// @TODO: Need to figure out exiting the SideMenu
import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavBar, LayoutContainer, SideMenu, Button } from 'ComponentLibrary';
import { setAssessmentLanguage, setAssessmentSound } from '../../services/PatientService';
import { useSelector,useDispatch } from 'react-redux';
import { setUserData } from '../state/actions/AuthActions';
import ThemeProvider from 'ComponentLibrary/ThemeProvider';

import depcareLogo from '../../assets/depcare-logo.png';

const languages = [{id:1,name:'English'},{id:0,name: 'Spanish'}];

const MainLayout = ({ component: Komponent, ...rest }) => {

  const { theme } = useSelector(state => state.auth.userData);

  let t = theme || 'depcare';
  
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.userData);
  console.log(userData,'userDatauserData');
  
  const { token, language, soundOn } = userData;

  const [sideMenu, setSideMenu] = useState(false);

  const setSound = soundOn => {
    setAssessmentSound(token, soundOn).then((result) => {
      console.log("api call result:");
      console.log(result);
      dispatch(setUserData({ ...userData, soundOn }));
    });
  };

  const languageChange = language => setAssessmentLanguage(token, languages.find(l => l.id === language).name).then(() => {
    dispatch(setUserData({ ...userData, language }));
});

  return <Route {...rest} render={matchProps => {
    return <ThemeProvider theme={t}>
      <div className="fixed-nav">
        <NavBar>
          <span className="company-name">
            {t === 'iheart' &&
              <div>iHeart</div>
            }
             {t === 'depcare' &&
              <div><img src={depcareLogo} /></div>
            }
          </span>
          {token !== null && 
          <>
            {/* <div className="round-btn settings-btn" onClick={() => setSideMenu(false)}></div>
            <div className={`round-btn sound-btn ${ !soundOn ? 'active' : '' }`}></div> */}
          </>
          }
        </NavBar>
        <SideMenu>
          <div className={sideMenu ? 'overlay' : ''} onClick={() => setSideMenu(false)}></div>
          {token !== null && 
            <div>
              <div  className={`round-btn settings-btn ${sideMenu ? 'active' : ''}`} onClick={() => setSideMenu(!sideMenu)}></div>
              <div id="soundIcon" className={`round-btn sound-btn ${ !soundOn ? 'active' : '' }`} onClick={() => {setSound(!soundOn);console.log("setting sound" + soundOn);}}></div>
            </div>
          }
          <div className={`side-menu ${sideMenu ? 'active' : ''}`}>
            <div className="options">
              <p className="options-title">
              
              {language === 1 && <span>Options</span>}
              {language === 0 && <span>Opciones</span>}

              </p>
              {languages.map((lang, i) =>
                <div className="checkbox" key={i}>
                  <label style={{ cursor: 'pointer' }}>

                    <input
                      type="radio"
                      value={lang.id}
                      checked={userData.language === lang.id}
                      onChange={() => languageChange(lang.id)}
                      className="form-check-input"
                    />
                    <span className="radio"></span>

                    <p className="language">{lang.name}</p>
                  </label>
                </div>
              )}
            </div>
            <div className="buttons">
              {/* <Button size="lg" type="primary" onClick={() => setSideMenu(false)}>
              {language === 1 && <span>Restart Test</span>}
              {language === 0 && <span>Reiniciar prueba</span>}

              </Button> */}
              <Button size="lg" type="default" onClick={() => setSideMenu(false)}>
                {language === 1 && <span>EXIT SETTINGS</span>}
                {language === 0 && <span>SALIR AJUSTES</span>}
                </Button>
            </div>
          </div>
        </SideMenu>
        <LayoutContainer>
          <Komponent {...matchProps} />
        </LayoutContainer>
      </div>
    </ThemeProvider>;
  }} />;
};

MainLayout.propTypes = {
  component: PropTypes.any,
};

MainLayout.defaultProps = {
};

export default MainLayout; 
