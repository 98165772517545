 import axios from '../../../../config/Axios';

export const savePatientAssessment = (data) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/patient-assessments/add/',{assessment:data})
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const sendSmsAssessment = (id,phone_number) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/patient-assessments/sendSmsAssessment/'+id,{
            phone_number:phone_number
        })
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const sendAssessment = (id,email_address) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/patient-assessments/sendAssessment/'+id,{
            email_address:email_address
        })
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const sendReportEmail = (id,email_address) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post('/api/patient-assessments/sendReport/'+id,{
            email_address:email_address
        })
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
export const fetchResult = (id) => {
    return new Promise((resolve , reject) => {
        // fake response timeout
        axios.post(`/api/patient-assessments/getResult/${id}`)
        .then((response) => {
            const { data } = response;

            resolve(data);

        })
        .catch(reject);
       
       
    });
};
