import {css, jsx} from '@emotion/core';
import PropTypes from 'prop-types';
import {useTheme} from 'emotion-theming';
import {useEffect, useState} from 'react';

/** @jsx jsx */
export const SingleQuestion = ({ question, answers, selected, errors, onChange, ...others }) => {
  const theme = useTheme();
  const [errorArray, setErrorArray] = useState(errors ? errors : []);
  useEffect(() => {
    if (errors) {
      setErrorArray(errors);
    };
  });
  return (<div css={css `
        font-family: ${theme.texts.font.family.default};
        text-align: center;
        /* ${errorArray.length != 0? 
          `border: 1px solid red;
          border-radius: 5px;
          padding: 5px 0;`
        : 
          ''
        } */
        .choice-box {
          background-color: #fff;
          color: #464d56;
          margin-bottom: 5px;
          font-size: 1.375rem;
          font-weight: 600;
          padding: 13px 0;
          cursor: pointer;
          user-select: none;
        }
        .selected {
          background-color: ${theme.colors.primary};
          color: #fff;
        }
        .choice-box:first-of-type {
          border-radius: 8px 8px 0 0;
        }
        .choice-box:last-of-type {
          border-radius: 0 0 8px 8px;
        }
        .question {
          font-size: 1.5rem;
          margin-bottom: 30px;
          ${errorArray.length != 0? 
          `color: #D75938;`
        : 
          ''
        }
        }
      `} { ...others }>
      <div className="question"  dangerouslySetInnerHTML={{ __html: question.replace(/(<? *script)/gi, 'illegalscript') }} />
      <div className="choices">
        {answers.map(option =>
          <div
            key={option.id}
            className={`choice-box ${selected === option.id ? 'selected' : ''}`}
            onClick={(event) => onChange(option, event)} dangerouslySetInnerHTML={{ __html: option.answer.replace(/(<? *script)/gi, 'illegalscript') }}>
          </div>
        )}
      </div>
      {/* <div className="errors" css={css`
            div {
              color: red;
              margin-bottom: 5px;
            }
          `}>
        {errorArray.map((err, i) => {
          return <div key={`err-${i}`}>{err}</div>;
        })}
      </div> */}

  </div>);
};

SingleQuestion.propTypes = {
  /** event called when answer is changed, arguments: answer object, event */
  onChange: PropTypes.func.isRequired,
  /** Currently selected id */
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The question */
  question: PropTypes.string.isRequired,
  /** Answers for the question */
  answers: PropTypes.arrayOf(PropTypes.shape({
    /** Unique id of the answer, not shown to user */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Answer value */
    answer: PropTypes.string.isRequired,
  })).isRequired,
  /** array of errors to be passed to this question */
  errors: PropTypes.array,
};

SingleQuestion.defaultProps = {
  errors: []
};
