import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import settingsIcon from '../../assets/img/setting-icon.png';
import audioIconOn from '../../assets/img/audio_icon.png';

/** @jsx jsx */
export const NavBar = ({ children }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        font-family: ${theme.texts.font.family.default};
        padding-bottom: 50px;
        .container {
          background: ${theme.colors.navbar};
          display: flex;
          justify-content: center;
          position: relative;
          width: 100%;
        }
        .company-name {
          font-size: ${theme.texts.font.size.lg}px;
          color: #fff;
          /* padding-top: 35px;
          padding-bottom: 25px; */
          img {
            height: 30px;
            margin: 26px 0 16px 0;
          }
        }
        .round-btn {
          position: absolute;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          -webkit-box-shadow: 0 2px 2px rgba(0,0,0,.2);
          box-shadow: 0 2px 2px rgba(0,0,0,.2);
          cursor: pointer;
          -webkit-transition: all .6s ease;
          -o-transition: all .6s ease;
          transition: all .6s ease;
        }
        .settings-btn {
          top: 45px;
          left: 40px;
          background: url(${settingsIcon}) no-repeat #fff;
          background-position: 50%;
          background-size: 30px;
        }
        .sound-btn {
          top: 45px;
          left: 120px;
          background: url(${audioIconOn}) no-repeat #fff;
          background-position: 50%;
          background-size: 30px;
        }
        @media only screen and (max-width: 600px){
          .round-btn{
            top: 100px !important;
          }
        }
      `}>
      <div className="container">
        {children}
      </div>

    </div>
  );
};

export default NavBar;

NavBar.propTypes = {
  children: PropTypes.node,
};
