// This page was designed to work with the data found in /data/patientresults.json
import { css, jsx } from '@emotion/core';
import { useState, useEffect, useRef, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import ResultBar from './../../../../shared/components/Elements/ResultBar';
import Title from './../../../../shared/components/Elements/Title';
import { fetchResult } from '../../services/PatientAssessmentsService.js';
import { useTheme } from 'emotion-theming';
import SendReportEmailForm from './SendReportEmailForm';
import redX from '../../../../../assets/red-x.png';
import greenTick from '../../../../../assets/green-tick.png';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import { Table, TableHead, TableBody, TableRow, TableCell, Divider } from '@material-ui/core';

import moment from "moment";
import cTable from 'console.table';
import { Permission } from '../../../../shared/Security/index';

import DialogContent from '@material-ui/core/DialogContent';
/** @jsx jsx */
const ResultsPage = ({ match }) => {
    const patient_assessment_id = match.params.patient_assessment_id;
    const [patientResults, setPatientResults] = useState({ recommendations: [], patient: { provider: {} } });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [printingReport, setPrintingReport] = useState(false);
    const [recommendations, setRecommendations] = useState([]);
    const [plaintextTable, setPlaintextTable] = useState("");
    const plaintextTableDiv = useRef();
    const theme = useTheme();

    const [patient] = useState({
        "patientInfo": {
            "firstName": "Amy",
            "lastName": "Appleseed",
            "phoneNumber": 1235559797,
            "emailAddress": "a.appleseed@gmail.com",
            "dateOfBirth": "1980-05-30",
            "MRN": 1234567,
            "provider": "Dr. John Smith",
            "providerID": 987654321,
            "clinicID": 12,
            "dateCompleted": "2020-04-10",
            "preferredTreatment": {
                "interested": [
                    "Medication"
                ],
                "notInterested": [
                    "Care Manager"
                ]
            },
            "barriers": [
                "I don't want any medication",
                "It's hard for me to get here",
                "I worry about how my family would react"
            ],
            "depressionHistory": [
                {
                    "indicator": "History of depression",
                    "answer": false
                },
                {
                    "indicator": "Seeing a counselor",
                    "answer": true
                },
                {
                    "indicator": "Perscribed antidepressants",
                    "answer": false
                },
                {
                    "indicator": "Adherence to Rx meds (>90%)",
                    "answer": false
                }
            ]
        },
        "suicidalitySection": {
            "isSuicidal": true,
            "suicidalityIndicators": [
                {
                    "indicator": "Wished dead/go to sleep and not wake up",
                    "answer": true
                },
                {
                    "indicator": "Has been thinking about how to kill thmesleves",
                    "answer": true
                },
                {
                    "indicator": "Had thoughts of killing themsleves",
                    "answer": true
                },
                {
                    "indicator": "Has some intention of acting on these thoughts",
                    "answer": true
                },
                {
                    "indicator": "Has prepared to end their life",
                    "answer": false
                },
                {
                    "indicator": "Has started to work out details and intends to carry out plan",
                    "answer": false
                }
            ]
        },
        "recommendations": [

        ],
        "PHQSection": {
            "PHQScore": 21,
            "PHQResponses": [
                {
                    "question": "Little interest/pleasure",
                    "response": 2
                },
                {
                    "question": "Down/hopeless",
                    "response": 2
                },
                {
                    "question": "Trouble sleeping",
                    "response": 0
                },
                {
                    "question": "Tired/little energy",
                    "response": 2
                },
                {
                    "question": "Poor appetite/overeating",
                    "response": 2
                },
                {
                    "question": "Self-esteem/let family down",
                    "response": 1
                },
                {
                    "question": "Trouble concentrating",
                    "response": 2
                },
                {
                    "question": "Moving slowly/fidgeting",
                    "response": 1
                },
                {
                    "question": "Suicidal ideation",
                    "response": 2
                }
            ]
        }
    },
        {
            "patientInfo": {
                "firstName": "Amy",
                "lastName": "Appleseed",
                "phoneNumber": 1235559797,
                "emailAddress": "a.appleseed@gmail.com",
                "dateOfBirth": "1980-05-30",
                "MRN": 1234567,
                "provider": "Dr. Mary Fisher",
                "providerID": 987654321,
                "clinicID": 12,
                "dateCompleted": "2020-04-10",
                "preferredTreatment": {
                    "interested": [
                        "Medication"
                    ],
                    "notInterested": [
                        "Care Manager"
                    ]
                },
                "barriers": [
                    "I don't want any medication",
                    "It's hard for me to get here",
                    "I worry about how my family would react"
                ],
                "depressionHistory": [
                    {
                        "indicator": "History of depression",
                        "answer": false
                    },
                    {
                        "indicator": "Seeing a counselor",
                        "answer": true
                    },
                    {
                        "indicator": "Perscribed antidepressants",
                        "answer": false
                    },
                    {
                        "indicator": "Adherence to Rx meds (>90%)",
                        "answer": false
                    }
                ]
            },
            "suicidalitySection": {
                "isSuicidal": true,
                "suicidalityIndicators": [
                    {
                        "indicator": "Wished dead/go to sleep and not wake up",
                        "answer": true
                    },
                    {
                        "indicator": "Has been thinking about how to kill thmesleves",
                        "answer": true
                    },
                    {
                        "indicator": "Had thoughts of killing themsleves",
                        "answer": true
                    },
                    {
                        "indicator": "Has some intention of acting on these thoughts",
                        "answer": true
                    },
                    {
                        "indicator": "Has prepared to end their life",
                        "answer": false
                    },
                    {
                        "indicator": "Has started to work out details and intends to carry out plan",
                        "answer": false
                    }
                ]
            },
            "recommendedAction": [
                "Initiate or titrate medication to address symptoms: mood/energy, migranes",
                "Emphasize Adherence"
            ],
            "PHQSection": {
                "PHQScore": 21,
                "PHQResponses": [
                    {
                        "question": "Little interest/pleasure",
                        "response": 2
                    },
                    {
                        "question": "Down/hopeless",
                        "response": 2
                    },
                    {
                        "question": "Trouble sleeping",
                        "response": 0
                    },
                    {
                        "question": "Tired/little energy",
                        "response": 2
                    },
                    {
                        "question": "Poor appetite/overeating",
                        "response": 2
                    },
                    {
                        "question": "Self-esteem/let family down",
                        "response": 1
                    },
                    {
                        "question": "Trouble concentrating",
                        "response": 2
                    },
                    {
                        "question": "Moving slowly/fidgeting",
                        "response": 1
                    },
                    {
                        "question": "Suicidal ideation",
                        "response": 2
                    }
                ]
            }
        });

    const resultBarData =
    {
        "type": "slider",
        "value": patient.PHQSection.PHQScore,
        "min": 1,
        "max": 25,
        "labels": [{
            "min": 1,
            "max": 4,
            "label": '<div className="label">Minimal</div>'
        }, {
            "min": 5,
            "max": 9,
            "label": '<div className="label">Mild</div>'
        }, {
            "min": 10,
            "max": 14,
            "label": '<div className="label">Moderate</div>'
        }, {
            "min": 15,
            "max": 20,
            "label": '<div className="label">Moderate-Severe</div>'
        }, {
            "min": 21,
            "max": 25,
            "label": '<div className="label">Severe</div>'
        }]
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleCopyDialogClose = () => {
        setCopyDialogOpen(false);
    };

    const handleCopyDialogOpen = () => {
        setCopyDialogOpen(true);
    };
    useEffect(() => {
        fetchResult(patient_assessment_id).then((data) => {

            if (data.success == 1) {
                // let p = patient;
                // p.patientInfo.MRN = data.data.patient.mrn;
                setPatientResults(data.data);
                setRecommendations(data.data.recommendations);

            }
        }).catch(() => {

        });
    }, []);

    const copyTable = () => {
  
        const listString = (condition, string) => {
            if (condition) {
                return `·       ${string}`;
            }
        };
        let tableString = cTable.getTable(
            `NAME: ${patientResults.patient.last_name || 'N/A'}, ${patientResults.patient.first_name || 'N/A'}`,
            `DepCare Report`,
            ``,
            `You are receiving this report as your patient indicated`,
            `elevated depressive symptoms (PHQ>5), which falls in the`,
            `range recommended for treatment.`,
            ``,
            `Suicidality: ${(patientResults.phq9_suicide_1 ==1 ||
                patientResults.phq9_suicide_2  == 1||
            
                patientResults.phq9_suicide_6 == 1)? 'YES' : 'NO'}`,
            `PHQ Score: ${patientResults.phq_score}, ${patientResults.phq_score_group}`,
            ``,
            `Preferred Treatment`,
            listString((patientResults.cm_interested_meds == 1), 'Interested in Medication'),
            listString((patientResults.cm_interested_meds == 0), 'Not Interested in Medication'),
            listString((patientResults.cm_interested_meds != undefined || patientResults.cm_interested_meds == null), 'Not Interested in Medication'),
            listString((patientResults.cm_interest == 1), 'Interested in Care Manager'),
            listString((patientResults.cm_interest == 0), 'Not Interested in Care Manager'),
            ``,
            `Self-reported Depression History`,
            listString((patientResults.dep_tx_4 !== undefined), `Adherence to Rx meds (>90%): ${patientResults.dep_tx_4 == 1 ? 'Y' : 'N'}`),
            ``,
            `Recommended Action`,
            recommendations.length !== 0 
            ? recommendations.map((action) => listString(action !== '', action))
            : '·       No Recommended Action',
            ``,
            `Scores:`,
            `(Cutoff for Depression Treatment, PHQ Score: 10) `,
            `PHQ Responses: `,
            listString(patientResults.phq9_q1 !== undefined, `Q1.Little interest/pleasure:${patientResults.phq9_q1}`),
            listString(patientResults.phq9_q2 !== undefined, `Q2.Down/hopeless:${patientResults.phq9_q2}`),
            listString(patientResults.phq9_q3 !== undefined, `Q3.Trouble sleeping:${patientResults.phq9_q3}`),
            listString(patientResults.phq9_q4 !== undefined, `Q4.Tired/little energy:${patientResults.phq9_q4}`),
            listString(patientResults.phq9_q5 !== undefined, `Q5.Poor appetite/overeating:${patientResults.phq9_q5}`),
            listString(patientResults.phq9_q6 !== undefined, `Q6.Self-esteem/let family down:${patientResults.phq9_q6}`),
            listString(patientResults.phq9_q7 !== undefined, `Q7.Trouble concentrating:${patientResults.phq9_q7}`),
            listString(patientResults.phq9_q8 !== undefined, `Q8.Moving slowly/fidgeting:${patientResults.phq9_q8}`),
            listString(patientResults.phq9_q9 !== undefined, `Q9.Suicidal ideation:${patientResults.phq9_q9}`),
            ``,
            `PHQ Score: ${patientResults.phq_score || 'N/A'}, ${patientResults.phq_score_group || 'N/A'}`,
            ``,
            `Barriers`,
            listString((patientResults.i_dont_think_i_have_a_problem && patientResults.i_dont_think_i_have_a_problem === 1), `I don't think i have a problem`),
            listString((patientResults.im_afraid_this_will_cost_too_much && patientResults.im_afraid_this_will_cost_too_much === 1), `I'm afraid this will cost too much`),
            listString((patientResults.its_hard_for_me_to_get_here && patientResults.its_hard_for_me_to_get_here === 1), `It's hard for me to get here`),
            listString((patientResults.i_dont_want_any_medication && patientResults.i_dont_want_any_medication === 1), `I don't want any medication`),
            listString((patientResults.i_worry_about_how_my_family_would_react && patientResults.i_worry_about_how_my_family_would_react === 1), 'I worry about how my family would react.'),
            listString((patientResults.not_ready_to_deal && patientResults.not_ready_to_deal === 1), 'Not ready to deal'),
            listString((patientResults.i_dont_have_time_to_meet && patientResults.i_dont_have_time_to_meet === 1), `I don't have time to meet`),            
        );


        console.log(tableString);
        setPlaintextTable(tableString);
        handleCopyDialogOpen();
    };

    return <div css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .gad-box{
            display:flex;
        }
        .grey{

        }
        .gad-box div{
            margin-right:20px;
        }
        .image-container{
            display:flex;
        }
        .copyright{
            margin-top:5px;
            padding:15px;

            width: 90vw;
            background: linear-gradient(90deg, rgb(18, 162, 218) 0px, rgb(19, 212, 199));
            color:#fff;
            text-align:center;
        }
        .depression-history{
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .footer{
            padding:15px;
            width: 90vw;
            background-color:#fff;
            display: flex;
            justify-content: space-between;
            font-weight:bold;
        }
        h4{
            color: #4D4D4D;
            margin-bottom: 1px;
        }
        .inline-block{
            color: #4D4D4D;
        }
        p{
            text-align: center; 
            width: 90%;   
        }
        hr{
            color: #CFD0CF;
            border-bottom: 1px;
        }
        .bigColoredText{
            color: ${patientResults.phq9_q9 ? 'red' : 'green'};
            margin-left: 5%;
        }
        .phq-score{
            margin-left: 5px;
        }
        .underline{
            text-decoration: underline;
            margin-right: 10px;
        }
        .small-list{
            list-style: none;
        } 
        .small-list > * {
            font-size: 15px;
        }
        .recommended-list > * {
            font-size: 16px;
        }
        .number{
            font-size: 2em !important;
        }
        .red-x{
            content: url(${redX});
            height: 1em;
            width: 1em;
            margin-right: 10px;
        }
        .green-tick{
            content: url(${greenTick});
            height: 1em;
            width: 1em;
            margin-right: 10px;
        }
        .inline-block{
            margin: 2px 0px;
        }
        .bold{
            font-weight: bold;
            margin-bottom: 0;
        }

        h3.table-heading {
            color: #263238;
            font-size: 16px;
        }

        // CONTAINERS
        .grid-container{
            display: grid;
            grid-template-areas:
                "recommended   patient-info"
                "  phq patient-info"
                "suicidality  patient-info";

            width: 90vw;
            gap: 5px;
            margin-bottom: 5px;
            grid-template-columns: 3fr 2fr;
            @media (min-width: 1366px) {
                grid-template-columns: 2fr 1fr;
            }
            @media (min-width: 1800px) {
                grid-template-columns: 3fr 1fr;
            }
        }
        .section-container {
            margin-bottom: 0;
            background: white;
            border-radius: 5px;
            .section-heading {
                font-size: 20px;
                &--group {
                    color: #78AFAB;
                }
            }
        }
        .suicidality{
            grid-area: suicidality;
        }
        .recommended{
            grid-area: recommended;
        }
        .phq{
            grid-area: phq;
        }
        .patient-info{
            .MuiTableCell-root {
                border-bottom: none;
                padding:0px;
            }
            &__table {
                margin: 20px 0;
            }
            grid-area: patient-info;
            width: 100%;
            .report-subheading {
                color: #263238;
                font-weight: 500;
            }
            .report-detail {
                color: #546e7a;
            }

            h4 {
                color: #757575;
                display: flex;
                align-items: center;

                span {
                    display: block;
                    font-size: 24px;
                    color: black;
                    margin-left: 15px;
                }
            }
        }
        .margin-box{
            margin: 10px 5%;
        }
        .nobullet-list-container{
            list-style: none;
            padding-left:0px;
            font-size:0.875rem;
        }  
        .PHQ-responses{
            width:50%;
        }      
        .arrow-right {
            width: 0; 
            height: 0; 
            border-top: 10vh solid transparent;
            border-bottom: 10vh solid transparent;
            border-left: 3vw solid #12a2da;
            border-radius: 5px;
            // border-image: linear-gradient(90deg,#12a2da 0,#13d4c7);
            float: left;
        }
        .phq-container{
            display: flex;
            
        }
        .result-bar-container{
            width: 65%;
            border-radius: 5px;
            .bar{
                padding: 10px;
                background-color: #F7F5F2;

            }
        }
        .image-item{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 0 10px;
            box-sizing: border-box;
        }

        @media (max-width: 996px) {
            .grid-container{
                grid-template-areas:
                    "patient-info patient-info patient-info"
                    "suicidality suicidality suicidality"
                    "recommended recommended recommended"
                    "phq phq phq";
            }
            .patient-info{
                width: 100%;
            }
            .result-bar-container{
                width: 100%;
            }
            .phq-container{
                flex-direction: column;
            }
        }
    `}>
        <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
            <DialogContent>
                <SendReportEmailForm reportId={patient_assessment_id} onSuccess={handleDialogClose} />
            </DialogContent>
        </Dialog>
        <Dialog open={copyDialogOpen} onClose={handleCopyDialogClose} aria-labelledby="form-dialog-title">
            <DialogContent>
                {/* <SendReportEmailForm reportId={patient_assessment_id} onSuccess={handleCopyDialogClose} /> */}
                <div 
                // onClick={() => {
                //     console.log("copying");
                //     // plaintextTableDiv.current.select();
                //     // const toCopy = document.getElementById('plaintext-table');
                //     // toCopy.select();

                //     let el = document.createElement("textarea");
                //     el.value = plaintextTable;
                //     document.body.appendChild(el);
                //     el.select();
                //     document.execCommand('copy');
                //     document.body.  removeChild(el);
                //     console.log("done");
                // }} 
                css={css`
                    position: relative;
                    background: #00000010;
                    border: 1px solid #00000040;
                    margin: 20px;
                    padding: 10px;
                    border-radius: 5px;
                    transition: 0.5s;
                    .plaintext-table {
                        white-space: pre-wrap;
                    }
                `}>
                    <pre id="plaintext-table" className="plaintext-table" ref={plaintextTableDiv}>
                        {plaintextTable}
                        
                    </pre>
                </div>
            </DialogContent>
        </Dialog>
        <div className={'hide-btn'} style={{ padding: '20px' }}>
        <Permission allowed="PatientAssessments.DownloadReport">

        
            <Button
                variant="contained"
                color="primary"
                disabled={(printingReport == true) ? true : false}
                onClick={() => { setPrintingReport(true); setTimeout(() => { setPrintingReport(false); }, 3000); }}
                style={{ marginRight: 20 }}
                className="print-btn" href={`/api/patient-assessments/downloadReport/${patient_assessment_id}`}
            >
                Print
            </Button>
            </Permission>
            <Permission allowed="PatientAssessments.DownloadReport">

            <Button
                variant="contained"
                color="primary"
                onClick={copyTable}
            >
                Copy
            </Button>
            </Permission>

        </div>

        <Title>DepCare Report</Title>
        <p>You are recieving this report as your patient indicated elevated depressive symptoms (PHQ &ge; 5), which falls in the range recommended for treatment.</p>
        <div className="grid-container">
        <section className="recommended section-container">
                <div className="arrow-right"></div>
                <div className="margin-box">
                    <h2 className="section-heading" style={{ marginLeft: '13px' }}>RECOMMENDED ACTION</h2>
                    {recommendations.length === 0 && <ul><li><i>No Recommended Action</i></li></ul>}
                    {recommendations.length === 1 && <ul><li><i>{recommendations[0]}</i></li></ul>}
                    {recommendations.length > 1 && (
                    <ol className="recommended-list">
                        {recommendations.map((action, i) => {
                            return (<li key={`recommended-${i}`} dangerouslySetInnerHTML={{ __html:action}}></li>);
                        })}    
                    </ol>
                    )}
                </div>
            </section>
            <section className="suicidality section-container">
                <div className="margin-box">
                    <div className="depression-history">
                        <div>
                        <h1 className="table-heading" style={{ marginBottom: 0 }}>Depression History</h1>
                            <p style={{ fontStyle: 'italic', fontSize: '14px', margin: 0, textAlign: 'left', color: '#757575', marginBottom: '10px' }}>Self-Reported</p>
                            </div>
                            <div>

                <Table className="patient-info__table">
                        <TableHead>
                           
                        </TableHead>
                        <TableBody>
                        {patientResults.dep_hex !== undefined?
                            <TableRow key={`indicator-box-1`} className="image-item">
                                <div key={`indicator-1`} className="image-item">
                                    <image className={patientResults.dep_hex == 1 ? 'green-tick' : 'red-x'}></image>
                                    <h5 className="inline-block">History of depression</h5>
                                </div>
                            </TableRow> : <Fragment/>
                        }
                        {patientResults.dep_tx_1 !== undefined?
                            <TableRow key={`indicator-box-2`} className="image-item">
                                <div key={`indicator-2`} className="image-item">
                                    <image className={patientResults.dep_tx_1 == 1 ? 'green-tick' : 'red-x'}></image>
                                    <h5 className="inline-block">Seeing a counselor</h5>
                                </div>                    
                            </TableRow> : <Fragment/>
                        }
                        {patientResults.dep_tx_3 !== undefined?
                            <TableRow key={`indicator-box-3`} className="image-item">
                                <div key={`indicator-3`} className="image-item">
                                    <image className={patientResults.dep_tx_3 == 1 ? 'green-tick' : 'red-x'}></image>
                                    <h5 className="inline-block">Prescribed antidepressants</h5>
                                </div>                    
                            </TableRow> : <Fragment/>
                        }
                        {patientResults.dep_tx_4 !== undefined?
                            <TableRow key={`indicator-box-4`} className="image-item">
                                <div key={`indicator-4`} className="image-item">
                                    <image className={patientResults.dep_tx_4 == 1 ? 'green-tick' : 'red-x'}></image>
                                    <h5 className="inline-block">Adherence to Rx meds (&gt;90%)</h5>
                                </div>                    
                            </TableRow> : <Fragment/>
                        }
                        </TableBody>
                    </Table>
                    </div>
                    </div>
                   
                </div>
            </section>
           
            <section className="phq section-container">
                <div className="margin-box">
                    <h2 className="section-heading">PHQ-9 Score: <span className="phq-score">{patientResults.phq_score}</span></h2>
                    <div className="phq-container">
                       
                        <div className="PHQ-responses">
                            <h3 style={{ paddingLeft: '0px' }}>PHQ Responses:</h3>
                            <ul className="small-list" style={{ paddingLeft: '0px' }}>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q1 != undefined) ? patientResults.phq9_q1 : 'N/A'}
                                    </span>
                                    Q1: Little interest/pleasure
                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q2 != undefined) ? patientResults.phq9_q2 : 'N/A'}
                                    </span>
                                    Q2: Down/hopeless
                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q3 != undefined) ? patientResults.phq9_q3 : 'N/A'}
                                    </span>
                                    Q3: Trouble sleeping

                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q4 != undefined) ? patientResults.phq9_q4 : 'N/A'}
                                    </span>
                                    Q4: Tired/little energy
                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q5 != undefined) ? patientResults.phq9_q5 : 'N/A'}
                                    </span>
                                    Q5: Poor appetite/overeating
                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q6 != undefined) ? patientResults.phq9_q6 : 'N/A'}
                                    </span>
                                    Q6: Self-esteem/let family down
                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q7 != undefined) ? patientResults.phq9_q7 : 'N/A'}
                                    </span>
                                    Q7: Trouble concentrating
                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q8 != undefined) ? patientResults.phq9_q8 : 'N/A'}
                                    </span>
                                    Q8: Moving slowly/fidgeting
                                </li>
                                <li >
                                    <span style={{ textDecoration: 'underline', marginRight: '10px' }}>
                                        {(patientResults.phq9_q9 != undefined) ? patientResults.phq9_q9 : 'N/A'}
                                    </span>
                                    Q9: Suicidal ideation
                                </li>

                            </ul>
                            <p>
                            These problems have made it {patientResults.phq_score_group} difficult for the patient to work, take care of things at home, or get along with other people.
                            </p>
                        </div>
                        <div className="result-bar-container">
                        <h2 className="section-heading section-heading--group">{patientResults.phq_score_group}</h2>
                            <div className='bar'>
                            <ResultBar theme={theme} score={patientResults.phq_score} labels={resultBarData.labels} min={resultBarData.min} max={resultBarData.max}></ResultBar>
                            </div>
                            <p style={{textAlign:'center',width:'100%'}}>(Scale: 0-27) Cutoff for Depression Treatment, PHQ Score: {patientResults.phq_score}</p>
                        </div>
                        
                    </div>
                    <div className="gad-box">
                                <div className="">
                                    <h2>GAD-2 Score: {patientResults.gad2}</h2>
                                </div>
                                <div className="">
                                    <h2>GAD-7: {(patientResults.patient.gad_7)?patientResults.patient.gad_7:'N/A'}</h2>
                                </div>
                            </div>
                </div>
            </section>
            <section className="patient-info section-container">
                <div className="margin-box">
                       
                        <h2>SUICIDALITY:
                        {patientResults.phq9_suicide_1 != undefined &&
                            <span className="bigColoredText">
                                {(patientResults.phq9_suicide_1 == 1 ||
                                    patientResults.phq9_suicide_1 == 2 ||
                                        patientResults.phq9_suicide_1 == 6) ? 'YES' : 'NO'}
                            </span>
                        }
                        {patientResults.phq9_q9 == undefined &&
                            <span className="bigColoredText grey">
                                NO
                                </span>
                        }
                    </h2>
                    <ul className="nobullet-list-container">
                        <li >
                            {patientResults.phq9_suicide_1 == undefined &&
                                <span className="underline">N/A</span>
                            }
                            {patientResults.phq9_suicide_1 != undefined &&

                                <span className="underline">{patientResults.phq9_suicide_1 == 1 ? 'Y' : 'N'}</span>
                            }
                            Wished dead/go to sleep and not wake up
                        </li>
                        <li>
                            {patientResults.phq9_suicide_2 == undefined &&
                                <span className="underline">N/A</span>
                            }
                            {patientResults.phq9_suicide_2 != undefined &&
                                <span className="underline">{patientResults.phq9_suicide_2 == 1 ? 'Y' : 'N'}</span>
                            }
                            Has been thinking about how to kill themsleves
                        </li>
                        <li>
                            {patientResults.phq9_suicide_3 == undefined &&
                                <span className="underline">N/A</span>
                            }
                            {patientResults.phq9_suicide_3 != undefined &&
                                <span className="underline">{patientResults.phq9_suicide_3 == 1 ? 'Y' : 'N'}</span>
                            }
                            Had thoughts of killing themsleves
                        </li>
                        <li>
                            {patientResults.phq9_suicide_4 == undefined &&
                                <span className="underline">N/A</span>
                            }
                            {patientResults.phq9_suicide_4 != undefined &&
                                <span className="underline">{patientResults.phq9_suicide_4 == 1 ? 'Y' : 'N'}</span>
                            }
                            Has some intention of acting on these thoughts
                        </li>
                        <li>
                            {patientResults.phq9_suicide_5 == undefined &&
                                <span className="underline">N/A</span>
                            }
                            {patientResults.phq9_suicide_5 != undefined &&
                                <span className="underline">{patientResults.phq9_suicide_5 == 1 ? 'Y' : 'N'}</span>
                            }
                            Has prepared to end their life
                        </li>
                        <li>
                            {patientResults.phq9_suicide_6 == undefined &&
                                <span className="underline">N/A</span>
                            }
                            {patientResults.phq9_suicide_6 != undefined &&
                                <span className="underline">{patientResults.phq9_suicide_6 == 1 ? 'Y' : 'N'}</span>
                            }
                            Has started to work out details and intends to carry out plan
                        </li>
                    </ul>
                    <div>
                       <h2>Assessed By MD: {(patientResults.assessed_by_md)?'Yes':'No'}</h2>
                           </div>
                    <Divider />
                    <Table className="patient-info__table">
                        <TableHead>
                            <h2 className="table-heading">Preferred Treatment</h2>
                        </TableHead>
                        <TableBody key={`interested`} >
                            {patientResults.cm_interested_meds == 1  &&
                                <TableRow className="image-item">
                                    <TableCell><image className="green-tick"></image></TableCell>
                                    <TableCell>Interested in <span className="bold">Medication</span></TableCell>
                                </TableRow>
                            }

                            {patientResults.cm_interested_meds == 0 &&
                                <TableRow className="image-item">
                                    <TableCell><image className="red-x"></image></TableCell>
                                    <TableCell>Not Interested in <span className="bold">Medication</span></TableCell>
                                </TableRow>
                            }

                            {(patientResults.cm_interested_meds == undefined || patientResults.cm_interested_meds == null) &&
                                <TableRow className="image-item">
                                    <TableCell><image className="red-x"></image></TableCell>
                                    <TableCell>Not Interested in <span className="bold">Medication</span></TableCell>
                                </TableRow>
                            }

                        {patientResults.cm_interest !== undefined &&
                            <div key={`interested`} >

                                {(patientResults.cm_interest == 1 || patientResults.solutions_cm ==1) &&
                                    <TableRow className="image-item">
                                        <TableCell><image className="green-tick"></image></TableCell>
                                        <TableCell>Interested in <span className="bold">Care Manager</span></TableCell>
                                    </TableRow>
                                }
                                {(patientResults.cm_interest == 0 &&patientResults.solutions_cm !=1) &&
                                    <TableRow className="image-item">
                                        <TableCell><image className="red-x"></image></TableCell>
                                        <TableCell>Not Interested in <span className="bold">Care Manager</span></TableCell>
                                    </TableRow>
                                }
                            </div>
                        }
                        </TableBody>
                    </Table>
                    <Divider />
                    <Table className="patient-info__table">
                        <TableHead>
                            <h3 className="table-heading">Barriers</h3>
                        </TableHead>
                        <TableBody>
                            {patientResults.i_dont_think_i_have_a_problem !== undefined &&
                                <TableRow key={`interested`} className="image-item">
                                    {patientResults.i_dont_think_i_have_a_problem == 1 &&
                                                                               <div className="image-container">

                                        <TableCell><img src="/api/webroot/uploads/images/stop.svg" width={20} style={{marginRight:10}}></img></TableCell>
                                        <TableCell>I don&apos;t think i have a problem</TableCell>    
                                        </div>
                                    }
                                </TableRow>
                            }

                            {patientResults.im_afraid_this_will_cost_too_much !== undefined &&
                                <TableRow key={`interested`} className="image-item">
                                    {patientResults.im_afraid_this_will_cost_too_much == 1 &&
                                                                               <div className="image-container">
                                        <TableCell><img src="/api/webroot/uploads/images/money.svg" width={20} style={{marginRight:10}}></img></TableCell>
                                            <TableCell>I&apos;m afraid this will cost too much</TableCell>
                                      </div>
                                    }
                                </TableRow>
                            }

                            {patientResults.its_hard_for_me_to_get_here !== undefined &&
                                <TableRow key={`interested`} className="image-item">
                                    {patientResults.its_hard_for_me_to_get_here == 1 &&
                                                                               <div className="image-container">
                                        <TableCell><img src="/api/webroot/uploads/images/train.svg" width={20} style={{marginRight:10}}></img></TableCell>
                                            <TableCell>It&apos;s hard for me to get here</TableCell>
                                        </div>
                                    }
                                </TableRow>
                            }
                            {patientResults.i_dont_want_any_medication !== undefined &&
                                <TableRow key={`interested`} className="image-item">
                                    {patientResults.i_dont_want_any_medication == 1 &&
                                                                               <div className="image-container">

<TableCell><img src="/api/webroot/uploads/images/pill.svg" width={20} style={{marginRight:10}}></img></TableCell>
                                            <TableCell>I don&apos;t want any medication</TableCell>
                                            </div>
                                    }
                                </TableRow>
                            }
                            {patientResults.i_worry_about_how_my_family_would_react !== undefined &&
                                <TableRow key={`interested`} className="image-item">
                                    {patientResults.i_worry_about_how_my_family_would_react == 1 &&
                                                                               <div className="image-container">

<TableCell><img src="/api/webroot/uploads/images/family.svg" width={20} style={{marginRight:10}}></img></TableCell>
                                            <TableCell>I worry about how my family would react.</TableCell>
                                       </div>
                                    }
                                </TableRow>
                            }
                            {patientResults.not_ready_to_deal !== undefined &&
                                <TableRow key={`interested`} className="image-item">
                                    {patientResults.not_ready_to_deal == 1 &&
                                                                               <div className="image-container">

<TableCell><img src="/api/webroot/uploads/images/sad.svg" width={20} style={{marginRight:10}}></img></TableCell>
                                            <TableCell>Not ready to deal</TableCell>
                                       </div>
                                    }
                                </TableRow>
                            }
                            {patientResults.i_dont_have_time_to_meet !== undefined &&
                                <TableRow key={`interested`} className="image-item">
                                    {patientResults.i_dont_have_time_to_meet == 1 &&
                                                                               <div className="image-container">

<TableCell><img src="/api/webroot/uploads/images/calendar.svg" width={20} style={{marginRight:10}}></img></TableCell>
                                            <TableCell>I don&apos;t have time to meet</TableCell>
                                       </div>
                                    }
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <Divider />
                    
                    
                </div>
            </section>
        </div>
        <div className="footer">
            <div>
            PT MRN #: {patientResults.patient.mrn}
            </div>
            <div>
            Provider: {patientResults.patient.provider.name}
            </div>
            <div>
                Date Completed: {moment(patientResults.assessment_completion_date).format('MM/DD/YYYY')}
            </div>
            
        </div>
        <div className="copyright">
        Use the .DepCare SmartPhrase to add depression and anxiety screening and plan to your note.
        </div>
       
    </div>;
};

ResultsPage.propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
};

export default ResultsPage;