import { css, jsx } from '@emotion/core';
import { Fragment, useState, useEffect } from 'react';
import {
    Button,
    Input,
    Select,
    ImageGroup,
    ColumnQuestion,
    ProgressDots,
    SingleQuestion,
    Header,
    ResultBar,
    Video,
    ButtonGroup,
    GroupedQuestion,
    MultiQuestion,
    ProConList,
} from 'ComponentLibrary';
import { useSelector } from 'react-redux';
// import { Provider } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    submitAssessment,
    fetchAssessment,
    previousQuestion,
    getProviders,
} from '../../../services/AssessmentService';
import { CardGroup } from 'ComponentLibrary/index';
// import { set } from "date-fns";

/** @jsx jsx */
export const TakeAssessment = () => {
    const { token, language, soundOn, method } = useSelector(
        (state) => state.auth.userData
    );
    const [audio, setAudio] = useState('');
    const [answers, setAnswer] = useState([]);

    const [followupLink, setFollowupLink] = useState('');

    const [isCompleted, setIsCompleted] = useState(false);

    const [isFirstPage, setIsFirstPage] = useState(false);
    const [allowSkip, setAllowSkip] = useState(false);

    // const [isThankYouPage, setIsThankYouPage] = useState(false);

    const [hasAssessment, setHasAssessment] = useState(true);

    const [progressBar, setProgressBar] = useState({});
    const [providers, setProviders] = useState([]);
    const [subAudios, setSubAudios] = useState([]);

    const [sections, setSections] = useState([]);

    // keep tracking if a section has completed or not
    const [sectionsIds, setSectionsIds] = useState([]);
    const [finishedSection, setFinishedSection] = useState(-1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formErrors, setFormErrors] = useState([]);
    function finishedPlaying() {
        setAnswer({ finished: true });
    }

    function answerQuestion() {
        if (allowSkip == false) {
            alert('Please watch the video before you continue');
            return false;
        }
        console.log('Initiate Answer');
        console.log(answers, 'finanswers');
        setIsSubmitting(true);
        submitAssessment(token, answers, method)
            .then((result) => {
                console.log(result);

                setIsSubmitting(false);
                if (!result.success) {
                    setFormErrors(result.errors.question);
                    // console.log(formErrors);
                } else {
                    if (result.sound_enabled) {
                        setAudio(result.data.audio);
                    } else {
                        setAudio('');
                    }
                    // setIsThankYouPage(result.data)
                    // console.log("----- DATA -----");
                    // console.log(result);
                    // console.log("----- ENDDATA -----");
                    if (result.completed == 1) {
                        setIsCompleted(true);

                        if (result.followup_link != '') {
                            setFollowupLink(result.followup_link + '/' + token);
                        }
                    }
                    setProgressBar(result.data.progressbar);

                    setSections(result.data.sections);

                    setIsFirstPage(
                        result.current_screen_id === result.first_screen_id
                            ? true
                            : false
                    );
                    setAnswer({});
                    console.log(
                        result.data.sections,
                        'result.data.sectionsresult.data.sectionsresult.data.sectionsresult.data.sections'
                    );

                    if (result.data.sections) {
                        result.data.sections.forEach((item) => {
                            console.log(
                                item.sectionItems,
                                'item.sectionItemsitem.sectionItems'
                            );
                            item.sectionItems.forEach((i) => {
                                console.log(
                                    i,
                                    'item.sectionItemsitem.sectionItems123123123'
                                );
                                if (i.type == 'video') {

                                    setAllowSkip(true);
                                    console.log('allow skip true');
                                }
                            });
                        });
                    }
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
                console.error('submit answer', error);
            });
    }
    function gotoPreviousQuestion() {

        previousQuestion(token).then((result) => {
            const previous_answers = Object.keys(result.answered_questions)[0];
            if (!result.success) {
                setFormErrors(result.errors.question);
            } else {
                if (result.sound_enabled) {
                    setAudio(result.data.audio);
                } else {
                    setAudio('');
                }
                setAnswer({});
                let answerArray = {};
                setProgressBar(result.data.progressbar);
                setSections(result.data.sections);
                setAllowSkip(true);
                setIsFirstPage(
                    result.current_screen_id === result.first_screen_id
                        ? true
                        : false
                );
                console.log(result, 'resultresultresultresultresult');

                result.data.sections.forEach((section) => {
                    section.sectionItems.forEach((question) => {
                        console.log(question.questionType, 'question.questionType');
                        console.log(result.answered_questions, 'question.questionType');
                        if (result.answered_questions[question.id] != undefined && question.questionType != undefined) {
                            console.log(question.questionType, 'vraagtype');

                            if (question.questionType == 'column') {
                                question.question.forEach((sub_question, i) => {
                                    console.log('questions-' + i + 'subquestion' + sub_question.id);
                                    console.log(question.answers, 'sub_questionsub_questionsub_question');
                                    if (result.answered_questions[question.id][sub_question.id] != undefined) {
                                        question.answers.forEach((answer) => {
                                            console.log('SET THE POINTS1-' + i, answer.points);
                                            console.log('SET THE POINTS2-' + i, result.answered_questions[question.id][sub_question.id]);

                                            if (result.answered_questions[question.id][sub_question.id] == answer.points) {
                                                console.log('SET THE POINTS - match-' + i);
                                                if (answerArray[sub_question.id] == undefined) {
                                                    answerArray[sub_question.id] = {};
                                                }
                                                answerArray[sub_question.id] = answer.id;
                                            }
                                        });
                                    }

                                });

                            }
                            else if (question.questionType == 'grouped') {
                                question.questions.forEach(sub_question => {
                                    console.log(result.answered_questions, 'result.answered_questions');
                                    console.log(question.id, 'result.answered_questions');

                                    console.log(sub_question.id, 'result.answered_questions');

                                    sub_question.answers.forEach(answer => {
                                        console.log(answer, 'answeranswer');

                                        if (result.answered_questions[question.id][sub_question.id] == answer.points) {
                                            console.log(result.answered_questions[question.id], 'previousquestions');

                                            if (answerArray[sub_question.id] == undefined) {
                                                answerArray[sub_question.id] = {};
                                            }
                                            console.log(question.id, answer.id, 'IDS');
                                            answerArray[sub_question.id] = answer.id;

                                        }
                                    });
                                });
                            }
                            else if (question.questionType != undefined) {
                                question.answers.forEach((answer) => {
                                    console.log(answer + '|' + answer.points, 'answeransweransweranswermees');
                                    console.log(result.answered_questions[question.id][0], 'answeransweransweranswermees');

                                    if (result.answered_questions[question.id][0] == answer.points) {
                                        console.log(answer, 'hasanswer');

                                        if (answerArray[question.id] == undefined) {
                                            answerArray[question.id] = {};
                                        }
                                        answerArray[question.id] = answer.id;
                                    }
                                });

                            }
                            // console.log(result.answered_questions[question.id], 'BOOOYAAH');
                            // if (result.answered_questions[question.id][answer.id] != undefined) {

                            //     //
                        }
                    });
                });
                console.log(answerArray, 'answerArrayanswerArrayanswerArrayanswerArray');
                //console.log('show the previously answered questions,', result.answered_questions);

                setAnswer(answerArray);
                // result.answered_questions.forEach((answered) => {
                //     console.log(answered);
                //     result.data.sections.forEach((item) => {
                //         item.itemSections.forEach((e) => {
                //             alert(e);
                //             if (
                //                 e.type == 'single-question' ||
                //                 'multi-question' ||
                //                 'column-question' ||
                //                 'grouped-question'
                //             ) {
                //                 e.answered_questions.map((i) => {
                //                     alert(i);
                //                 });
                //                 if (e.answered_questions == '1') {
                //                     //.setAnswer({});
                //                 }
                //             }
                //         });


                //     });
                // });
                if (result.data.sections) {
                    result.data.sections.forEach((item) => {
                        item.sectionItems.forEach((i) => {
                            if (i.type == 'video') {
                                //   setAllowSkip(false);
                            }
                        });
                    });
                }
            }
        });
    }
    function getProvidersWrapper() {
        getProviders().then((res) => {
            setProviders(res.data.providers);
        });
    }

    const loadSectionItems = (data) => {
        const sectionQuestionIds = data.sections.map((sec) =>
            sec.sectionItems.map(
                (item) =>
                    item.type === 'question' && item.question.map((q) => q.id)
            )
        );
        if (sectionQuestionIds[0]) {
            setSectionsIds(
                sectionQuestionIds[0].filter((secIds) => secIds !== false)
            );
        }
    };

    const loadSectionAudios = (data) => {
        const sectionAudios = data.sections.map((sec) =>
            sec.sectionItems
                .map((item) => (item.type === 'question' ? item.audio : ''))
                .filter((audiolink) => audiolink !== '')
        );
        // ignore muitiple section groups
        // TODO: fix button toggle bug
        // if (sectionAudios[0]) {
        //   setSubAudios(sectionAudios[0]);
        // }
    };

    const subQuestionFinished = (arr, target) =>
        target.every((v) => arr.includes(v));

    const getHiddenIds = () => {
        let hiddenIds = [];
        sections.forEach((section, section_i) => {
            section.sectionItems.forEach((section_item) => {
                // it returns true and 'hide' by default
                const checkSection = document.getElementById(
                    `section-${section_i}-item-${section_item.id}`
                );
                if (checkSection.classList.contains('hide')) {
                    hiddenIds = section_item.question.map((q) => q.id);
                }
            });
        });
        return hiddenIds;
    };

    const getHiddenIdsIndex = () => {
        let hidenIndex = -1;
        const hiddenIds = getHiddenIds();
        sectionsIds.forEach((ids, index) => {
            if (subQuestionFinished(hiddenIds, ids)) {
                hidenIndex = index;
            }
        });
        return hidenIndex;
    };

    const playSectionAudios = () => {
        if (answers) {
            const answeredIds = Object.keys(answers).map((a) => parseInt(a));

            sectionsIds.forEach((sectionIds, index) => {
                if (subQuestionFinished(answeredIds, sectionIds)) {
                    if (finishedSection < index) {
                        setFinishedSection(index);
                    }
                } else {
                    return;
                }
            });
        }
    };

    function getAssessment() {
        fetchAssessment(token).then((result) => {
            console.log(result, 'item.sectionItems----get-');
            console.log(result.data.sections);
            if (result.exists) {
                if (result.completed == 1) {
                    setIsCompleted(true);

                    if (result.followup_link != '') {
                        setFollowupLink(result.followup_link + '/' + token);
                    }
                }
                if (result.sound_enabled) {
                    setAudio(result.data.audio);
                    // loadSectionItems(result.data);
                    // loadSectionAudios(result.data);
                } else {
                    setAudio('');
                }
                getProvidersWrapper();
                setProgressBar(result.data.progressbar);
                setSections(result.data.sections);
                setAllowSkip(true);
                if (result.data.sections) {
                    result.data.sections.forEach((item) => {
                        console.log(item.sectionItems, 'item.sectionItems');

                        item.sectionItems.forEach((i) => {
                            console.log(i, 'item.sectionItems');

                            if (i.type == 'video') {
                                setAllowSkip(false);
                            }
                        });
                    });
                }
                setIsFirstPage(
                    result.current_screen_id === result.first_screen_id
                        ? true
                        : false
                );
            }
            if (result.mrn !== null && result.mrn != '') {
                window.crizit('properties', {
                    mrn: result.mrn,
                });
            }
        });
    }
    function handleRightClick() {
        if (followupLink.length !== 0) {
            window.location.replace(followupLink);
        } else {
            answerQuestion();
        }
    }

    useEffect(() => {
        // document.addEventListener('keydown', event => {
        //   if (event.key === 'Enter') {
        //     handleRightClick();
        //     }
        // });
        // return () => {
        //     document.removeEventListener('keydown', null);
        // };
        let keyEnabledArray = Array(222).fill(true);
        document.onkeydown = function (e) {
            // disable the key until key release
            if (keyEnabledArray[e.key]) {
                keyEnabledArray[e.key] = false;
                handleRightClick();
            }
        };

        document.onkeyup = function (e) {
            // enable the specific key on keyup
            keyEnabledArray[e.key] = true;
        };
    });

    useEffect(() => {
        if (providers) {
            if (providers.length === 0) {
                getProvidersWrapper();
            }
        }
        if (token == null) {
            setHasAssessment(false);
        } else {
            getAssessment();
        }
    }, [language, soundOn]);

    useEffect(() => {
        playSectionAudios();
    }, [answers]);

    useEffect(() => {
        const audioElement = document.getElementById('audio-element');
        audioElement.src = audio;
        if (subAudios) {
            audioElement.src = subAudios[0];

            if (finishedSection > -1) {
                const hiddenIndex = getHiddenIdsIndex();
                if (hiddenIndex !== -1) {
                    audioElement.src =
                        subAudios[finishedSection + hiddenIndex + 1];
                } else {
                    audioElement.src = subAudios[finishedSection + 1];
                }
                console.clear();
            }
        }
        audioElement.oncanplaythrough = (event) => {
            var playedPromise = audioElement.play();

            if (playedPromise) {
                playedPromise
                    .catch((e) => {
                        if (
                            e.name === 'NotAllowedError' ||
                            e.name === 'NotSupportedError'
                        ) {
                            document.getElementById('soundIcon').click();

                            setAudio(false);
                        }
                    })
                    .then(() => { });
            }
        };

        // if(sections){
        //   const text = sections[0].sectionItems[0].text;
        //   const isThankYou = text.split(' ').slice(0,2).join(" ");
        //   console.log("isThankYou: " + isThankYou);
        //   setIsThankYouPage(isThankYou === "Thank you"? true : false);
        // }

        // console.log("isthanks: " + isThankYouPage);
    }, [audio, subAudios, finishedSection, sections]);

    if (hasAssessment) {
        console.log(sections, 'rendersections');
        console.log(answers, 'answersanswersanswers');
        return (
            <Fragment>
                <audio
                    id="audio-element"
                    src=""
                    type="audio/mpeg"
                    allow="autoplay"
                />
                {progressBar.has_progressbar == 1 && (
                    <div className="progressbar-box">
                        <ProgressDots
                            max={progressBar.progressbar_max}
                            value={progressBar.progressbar_value}
                        ></ProgressDots>
                    </div>
                )}
                <div className="assessment-box">
                    {isSubmitting && (
                        <div
                            css={css`
                                left: 50%;
                                top: 180px;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                opacity: 0;
                                position: absolute;
                                align-items: center;
                                justify-content: center;
                                transform: translate(-50%, -50%);
                                animation-name: fadeIn;
                                animation-delay: 1s;
                                animation-duration: 700ms;
                                animation-fill-mode: forwards;
                                @keyframes fadeIn {
                                    from {
                                        opacity: 0;
                                    }
                                    to {
                                        opacity: 1;
                                    }
                                }
                            `}
                        >
                            <CircularProgress style={{ color: '#13D3C6' }} />
                        </div>
                    )}

                    {sections.map((section, section_i) => (
                        <div key={`section-${section_i}`}>
                            {section.sectionItems.map((section_item) => (
                                <div
                                    key={`section-${section_i}-item-${section_item.id}`}
                                    id={`section-${section_i}-item-${section_item.id}`}
                                    className={`round-btn sound-btn ${section_item.has_variable_contingency ===
                                        1 &&
                                        ((section_item.variable_contingency
                                            .type == 'answerDependency' &&
                                            (answers[
                                                section_item
                                                    .variable_contingency.value
                                            ] == undefined ||
                                                answers[
                                                section_item
                                                    .variable_contingency
                                                    .value
                                                ] !=
                                                section_item
                                                    .variable_contingency
                                                    .answer_id)) ||
                                            (section_item.variable_contingency
                                                .type ==
                                                'frontendYesNoQuestion' &&
                                                (answers[
                                                    section_item
                                                        .variable_contingency
                                                        .value
                                                ] == undefined ||
                                                    answers[
                                                    section_item
                                                        .variable_contingency
                                                        .value
                                                    ] == 0)))
                                        ? 'hide'
                                        : ''
                                        }`}
                                >
                                    {section_item.type === 'paragraph' && (
                                        <section>
                                            <div
                                                className="section-paragraph"
                                                dangerouslySetInnerHTML={{
                                                    __html: section_item.text
                                                        ? section_item.text.replace(
                                                            /(<? *script)/gi,
                                                            'illegalscript'
                                                        )
                                                        : section_item.text,
                                                }}
                                            ></div>
                                        </section>
                                    )}
                                    {section_item.type === 'input' && (
                                        <section
                                            className="input-only"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <label
                                                css={css`
                                                    ${formErrors[
                                                        section_item.id
                                                    ] !== undefined
                                                        ? 'color: #D75938;'
                                                        : ''}
                                                `}
                                                dangerouslySetInnerHTML={{
                                                    __html: section_item.text
                                                        ? section_item.text.replace(
                                                            /(<? *script)/gi,
                                                            'illegalscript'
                                                        )
                                                        : section_item.text,
                                                }}
                                            ></label>
                                            <Input
                                                type={'text'}
                                                name={section_item.id}
                                                placeholder={
                                                    language
                                                        ? section_item.placeholder
                                                        : section_item.placeholder_spanish
                                                }
                                                value={answers[section_item.id]}
                                                errors={
                                                    formErrors[
                                                        section_item.id
                                                    ] !== undefined
                                                        ? formErrors[
                                                        section_item.id
                                                        ]
                                                        : []
                                                }
                                                onChange={(event) =>
                                                    setAnswer({
                                                        ...answers,
                                                        [section_item.id]:
                                                            event.target.value,
                                                    })
                                                }
                                            ></Input>
                                        </section>
                                    )}
                                    {section_item.type === 'provider-list' && (
                                        <section
                                            className="input-only"
                                            style={{ marginBottom: 10 }}
                                        >
                                            {language == 1 && (
                                                <label
                                                    css={css`
                                                        ${formErrors[
                                                            section_item.id
                                                        ] !== undefined
                                                            ? 'color: #D75938;'
                                                            : ''}
                                                    `}
                                                >
                                                    Select Provider:
                                                </label>
                                            )}
                                            {language == 0 && (
                                                <label
                                                    css={css`
                                                        ${formErrors[
                                                            section_item.id
                                                        ] !== undefined
                                                            ? 'color: #D75938;'
                                                            : ''}
                                                    `}
                                                >
                                                    Entre Proveedor:
                                                </label>
                                            )}
                                            <Select
                                                name={section_item.id}
                                                value={answers[section_item.id]}
                                                errors={
                                                    formErrors[
                                                        section_item.id
                                                    ] !== undefined
                                                        ? formErrors[
                                                        section_item.id
                                                        ]
                                                        : []
                                                }
                                                onChange={(event) =>
                                                    setAnswer({
                                                        ...answers,
                                                        [section_item.id]:
                                                            event.target.value,
                                                    })
                                                }
                                            >
                                                <option value="">
                                                    Select a Provider
                                                </option>
                                                {providers ? (
                                                    providers.map(
                                                        (provider, i) => {
                                                            return (
                                                                <option
                                                                    key={
                                                                        'provider-' +
                                                                        i
                                                                    }
                                                                    value={
                                                                        provider.id
                                                                    }
                                                                >
                                                                    {
                                                                        provider.name
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <div></div>
                                                )}
                                            </Select>
                                        </section>
                                    )}
                                    {section_item.type === 'image' && (
                                        <section className="section-image">
                                            <img src={section_item.url} />
                                        </section>
                                    )}
                                    {section_item.type === 'question' &&
                                        section_item.questionType ===
                                        'column' && (
                                            <section>
                                                <ColumnQuestion
                                                    answers={
                                                        section_item.answers
                                                    }
                                                >
                                                    {section_item.question.map(
                                                        (question) => (
                                                            <ColumnQuestion.Question
                                                                key={
                                                                    question.id
                                                                }
                                                                question={
                                                                    question.question
                                                                }
                                                                answers={
                                                                    section_item.answers
                                                                }
                                                                selected={
                                                                    answers[
                                                                    question
                                                                        .id
                                                                    ]
                                                                }
                                                                errors={
                                                                    formErrors[
                                                                        section_item
                                                                            .id
                                                                    ] !==
                                                                        undefined
                                                                        ? formErrors[
                                                                        section_item
                                                                            .id
                                                                        ][
                                                                        question
                                                                            .id
                                                                        ]
                                                                        : []
                                                                }
                                                                onChange={(
                                                                    answer
                                                                ) =>
                                                                    setAnswer({
                                                                        ...answers,
                                                                        [question.id]:
                                                                            answer.id,
                                                                    })
                                                                }
                                                            ></ColumnQuestion.Question>
                                                        )
                                                    )}
                                                </ColumnQuestion>
                                            </section>
                                        )}
                                    {section_item.type ===
                                        'progress_bullets' && (
                                            <section>
                                                <ProgressDots
                                                    max={section_item.max}
                                                    value={section_item.value}
                                                ></ProgressDots>
                                            </section>
                                        )}
                                    {section_item.type === 'question' &&
                                        section_item.questionType ===
                                        'single' && (
                                            <section>
                                                <SingleQuestion
                                                    question={
                                                        section_item.question
                                                    }
                                                    answers={
                                                        section_item.answers
                                                    }
                                                    selected={
                                                        answers[section_item.id]
                                                    }
                                                    errors={
                                                        formErrors[
                                                            section_item.id
                                                        ] !== undefined
                                                            ? formErrors[
                                                            section_item
                                                                .id
                                                            ]
                                                            : []
                                                    }
                                                    onChange={(answer) =>
                                                        setAnswer({
                                                            ...answers,
                                                            [section_item.id]:
                                                                answer.id,
                                                        })
                                                    }
                                                ></SingleQuestion>
                                            </section>
                                        )}
                                    {section_item.type === 'header' && (
                                        <section>
                                            <Header
                                                text={section_item.text}
                                                align={'center'}
                                            ></Header>
                                        </section>
                                    )}
                                    {section_item.type === 'large-header' && (
                                        <section>
                                            <Header
                                                text={section_item.text}
                                                size="large"
                                                align={'center'}
                                            ></Header>
                                        </section>
                                    )}
                                    {section_item.type === 'mega-header' && (
                                        <section>
                                            <Header
                                                text={section_item.text}
                                                size="mega"
                                                align={'center'}
                                            ></Header>
                                        </section>
                                    )}
                                    {section_item.type === 'boxed-modal' && (
                                        <section></section>
                                    )}
                                    {section_item.type === 'slider' && (
                                        <section>
                                            <ResultBar
                                                score={
                                                    section_item.slider.score
                                                }
                                                labels={
                                                    section_item.slider.groups
                                                }
                                                min={section_item.slider.min}
                                                max={section_item.slider.max}
                                            ></ResultBar>
                                        </section>
                                    )}
                                    {section_item.type ===
                                        'grouped-question' && (
                                            <section>
                                                <GroupedQuestion
                                                    questions={
                                                        section_item.questions
                                                    }
                                                    selected={answers}
                                                    onChange={(
                                                        question,
                                                        answer
                                                    ) => {
                                                        if (
                                                            question.groupType ===
                                                            'multi'
                                                        ) {
                                                            let items = [];
                                                            if (
                                                                answers[question.id]
                                                            ) {
                                                                items =
                                                                    answers[
                                                                        question.id
                                                                    ].slice();
                                                            }
                                                            let index =
                                                                items.indexOf(
                                                                    answer.id
                                                                );
                                                            if (index !== -1) {
                                                                items.splice(
                                                                    index,
                                                                    1
                                                                );
                                                            } else {
                                                                items.push(
                                                                    answer.id
                                                                );
                                                            }
                                                            setAnswer({
                                                                ...answers,
                                                                [question.id]:
                                                                    items,
                                                            });
                                                        } else {
                                                            setAnswer({
                                                                ...answers,
                                                                [question.id]:
                                                                    answer.id,
                                                            });
                                                        }
                                                    }}
                                                    errors={
                                                        formErrors[
                                                            section_item.id
                                                        ] !== undefined
                                                            ? formErrors[
                                                            section_item.id
                                                            ]
                                                            : []
                                                    }
                                                ></GroupedQuestion>
                                            </section>
                                        )}
                                    {section_item.type == 'multi-question' && (
                                        <section>
                                            <MultiQuestion
                                                question={
                                                    section_item.questions
                                                }
                                                answers={section_item.answers}
                                                selected={
                                                    answers[section_item.id]
                                                }
                                                maxSelections={
                                                    section_item.max_selections
                                                        ? section_item.max_selections
                                                        : answers.length
                                                }
                                                onChange={(answer) => {
                                                    let items = [];
                                                    if (
                                                        answers[section_item.id]
                                                    ) {
                                                        items =
                                                            answers[
                                                                section_item.id
                                                            ].slice();
                                                    }
                                                    let index = items.indexOf(
                                                        answer.id
                                                    );
                                                    if (index !== -1) {
                                                        items.splice(index, 1);
                                                    } else {
                                                        items.push(answer.id);
                                                    }
                                                    setAnswer({
                                                        ...answers,
                                                        [section_item.id]:
                                                            items,
                                                    });
                                                }}
                                                errors={
                                                    formErrors[
                                                        section_item.id
                                                    ] !== undefined
                                                        ? formErrors[
                                                        section_item.id
                                                        ]
                                                        : []
                                                }
                                            ></MultiQuestion>
                                        </section>
                                    )}

                                    {section_item.type === 'proconlist' && (
                                        <section>
                                            <ProConList
                                                data={section_item.questions}
                                            ></ProConList>
                                        </section>
                                    )}
                                    {section_item.type === 'card' && (
                                        <section>
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    borderRadius: 20,
                                                    padding: 20,
                                                }}
                                            >
                                                <p
                                                    className="header"
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: section_item
                                                            .question.header
                                                            ? section_item.question.header.replace(
                                                                /(<? *script)/gi,
                                                                'illegalscript'
                                                            )
                                                            : section_item
                                                                .question
                                                                .header,
                                                    }}
                                                ></p>
                                                <p
                                                    className="body"
                                                    dangerouslySetInnerHTML={{
                                                        __html: section_item
                                                            .question.paragraph
                                                            ? section_item.question.paragraph.replace(
                                                                /(<? *script)/gi,
                                                                'illegalscript'
                                                            )
                                                            : section_item
                                                                .question
                                                                .paragraph,
                                                    }}
                                                ></p>
                                            </div>
                                        </section>
                                    )}
                                    {section_item.type === 'image_group' && (
                                        <section>
                                            <ImageGroup
                                                imagesArray={
                                                    section_item.imagesArray
                                                }
                                                size={section_item.size}
                                            />
                                        </section>
                                    )}
                                    {section_item.type == 'video' && (
                                        <section>
                                            <Video
                                                source={section_item.video}
                                                hasButton={true}
                                                format="mp4"
                                                size="large"
                                                onStart={() =>
                                                    setAnswer({
                                                        finished: false,
                                                    })
                                                }
                                                onEnd={() => finishedPlaying()}
                                                allowSkip={() => {
                                                    setAllowSkip(true);
                                                }}
                                            />
                                        </section>
                                    )}
                                    {section_item.type == 'boxed-modal' && (
                                        <section>
                                            <CardGroup
                                                cardArray={section_item.questions.map(
                                                    (question) => {
                                                        return {
                                                            imageUrl:
                                                                question.icon,
                                                            alt: question.label,
                                                            overlayText:
                                                                question.label,
                                                            detailText:
                                                                question.text,
                                                            coding: question.coding,
                                                        };
                                                    }
                                                )}
                                                onRecordClickedItem={(
                                                    coding
                                                ) => {
                                                    if (
                                                        !answers[
                                                        section_item.id
                                                        ] &&
                                                        !answers[
                                                        section_item.id
                                                        ]
                                                    ) {
                                                        setAnswer({
                                                            ...answers,
                                                            [section_item.id]: [
                                                                coding,
                                                            ],
                                                        });
                                                    } else {
                                                        const existingAnswer =
                                                            answers[
                                                            section_item.id
                                                            ];
                                                        if (
                                                            !existingAnswer.includes(
                                                                coding
                                                            )
                                                        ) {
                                                            existingAnswer.push(
                                                                coding
                                                            );

                                                            setAnswer({
                                                                ...answers,
                                                                [section_item.id]:
                                                                    existingAnswer,
                                                            });
                                                        }
                                                    }
                                                }}
                                            />
                                        </section>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

                {isCompleted == false && (
                    <div
                        className="next-prev-btn-group"
                        style={{
                            width: language === 1 ? '400px' : '550px',
                            marginLeft: language === 1 ? '-200px' : '-225px',
                        }}
                    >
                        <ButtonGroup
                            onLeftClick={() => gotoPreviousQuestion()}
                            language={language}
                            onRightClick={handleRightClick}
                            canGoBack={!isFirstPage}
                        ></ButtonGroup>
                    </div>
                )}
                {isCompleted == true && followupLink != '' && (
                    <div>
                        <div
                            className="next-prev-btn-group"
                            style={{
                                width: language === 1 ? '400px' : '550px',
                                marginLeft:
                                    language === 1 ? '-200px' : '-225px',
                            }}
                        >
                            <ButtonGroup
                                onLeftClick={() => { }}
                                language={language}
                                onRightClick={() => {
                                    window.location.href = followupLink;
                                }}
                                canGoBack={false}
                            ></ButtonGroup>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    } else if (!hasAssessment) {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: '-200px',
                    width: 400,
                    textAlign: 'center',
                }}
            >
                <h3>Welcome!</h3>
                <p>
                    To start a new assessment, login to the admin panel or use
                    your provider url.
                </p>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: '-100px',
                    width: 200,
                    textAlign: 'center',
                }}
            >
                <h1> Complete.</h1>
                {followupLink != '' && (
                    <ButtonGroup
                        onLeftClick={() => { }}
                        language={language}
                        onRightClick={(window.location.href = followupLink)}
                        canGoBack={false}
                    ></ButtonGroup>
                )}
            </div>
        );
    }
};

TakeAssessment.propTypes = {};
