import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";
import { useEffect, useState} from 'react';

/** @jsx jsx */
export const MultiQuestion = ({
  question,
  answers,
  selected,
  maxSelections,
  onChange,
  errors,
  ...others
}) => {
  const theme = useTheme();
  const [errorArray, setErrorArray] = useState(errors ? errors : []);
  useEffect(() => {
    if (errors) {
      console.log(errors);
      setErrorArray(errors);
    };
  });
  return (
    <div
      css={css`
        font-family: ${theme.texts.font.family.default};
        text-align: left;
        ${errorArray.length > 0? 'border: 1px solid red;': ''}
        .choices {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .choice-box {
          position: relative;
          background-color: #fff;
          color: #464d56;
          margin-bottom: 4px;
          font-weight: 400;
          cursor: pointer;
          user-select: none;
          flex-basis: calc(50% - 2px);
          font-size: 12px;
          padding: 10px 0;
          display: inline-flex;
          align-items: center;
          @media(min-width: 768px) {
            font-size: 14px;
            padding: 13px 0;
          }

          &.disabled {
            cursor: not-allowed;
          }

          .text {
            padding-left: 40px;
            padding-right: 10px;
          }
        }
        .question {
          font-size: 1.5rem;
          margin-bottom: 30px;
        }

        .checkbox {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          height: 15px;
          width: 15px;
          background-color: #fff;
          border: 2px solid #999;
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkbox:after {
          content: "";
          position: absolute;
          display: none;
          top: 47%;
          left: 47%;
          transform: translate(-47%, -47%);
          width: 11px;
          height: 11px;
          background-color: ${theme.colors.primary};
        }

        /* When the radio button is checked, add background */
        .choice-box.selected .checkbox {
          background-color: #fff;
          border: 2px solid ${theme.colors.primary};
        }

        /* Show the indicator (dot/circle) when checked */
        .choice-box.selected .checkbox:after {
          display: block;
        }
      `}
      {...others}
    >
      <div className="question">{question}</div>
      <div className="choices">
        {answers.map((option) => {
          let select = false;
          let disable = false;
          if (selected && selected.indexOf(option.id) !== -1) {
            select = true;
          } else if (selected && maxSelections && selected.length >= maxSelections) {
            disable = true;
          }
          return (
            <div
              key={option.id}
              className={`choice-box${select ? ' selected' : ''}${disable ? ' disabled' : ''}`}
              onClick={(event) => !disable && onChange(option, event)}
            >
              <span className="checkbox"></span>
              <div className="text" dangerouslySetInnerHTML={{ __html: option.answer.replace(/(<? *script)/gi, 'illegalscript') }}></div>
            </div>
          );
        })}
      </div>
      <div className="errors" css={css`
        div {
          color: red;
          margin-bottom: 5px;
          text-align: center;
        }
      `}>
        {errorArray.length > 0 ?
          errorArray.map((err, i) => {
            return <div key={`err-${i}`}>{err}</div>;
          })
          :
          <div></div>
        }
      </div>

    </div>
  );
};

MultiQuestion.propTypes = {
  /** event called when answer is clicked, arguments: answer object, event */
  onChange: PropTypes.func.isRequired,
  /** Currently selected id's */
  selected: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /** The question */
  question: PropTypes.string.isRequired,
  /** Answers for the question */
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      /** Unique id of the answer, not shown to user */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Answer value */
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Maximum amount of selections, 0 means infinite */
  maxSelections: PropTypes.number,
  /** array of errors to be passed to this question */
  errors: PropTypes.array,
};

MultiQuestion.defaultProps = {
  maxSelections: 0,
  selected: []
};
